import styled from "styled-components";

export const TeamsMainStyle = styled.div`
  width: 90%;
  position: relative;
  margin: auto;
  margin-top: 20px;
  .addBtn-container {
    width: 77%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  .card-size {
    width: 77%;
  }
  .addteam-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #ffffffde !important;
    background: #3594ff !important;
    height: auto;
    margin-bottom: 20px;
  }
  .teams-heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
    margin-bottom: 20px;
  }
  
  .floatAddTeamButton{
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .card-size {
      width: 100%;
    }
    .addBtn-container{
      display: none;
    }
    .floatAddTeamButton{
      display: block;
    }
  }
`;
