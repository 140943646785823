import styled from "styled-components";
import * as palette from "../../styles/variables";

export const SignupStyle = styled.div`
  .main-row {
    justify-content: space-around;
  }
  .leftside-pic {
    display: inline;
    text-align: center;

    img {
      width: 68%;
    }
  }
  .todo-main {
    display: inline;
    text-align: center;
    .rightside-pic {
      display: inline;
      text-align: center;
      width: 190px;
    }
    .todo-form {
      .todo-ant-button {
        .ant-form-item-control {
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
        }
        .ant-btn {
          width: 100%;
          margin-top: 10px;
        }
      }
      .todo-have-acc {
        text-align: center;
      }
      .todo-haveacc-span {
        padding-left: 5px;
        color: #3594ff;
        cursor: pointer;
      }
      .todo-signup-free {
        text-align: center;
        color: #3594ff;
        cursor: pointer;
      }
      .keep-me-signin {
        color: #6a707c;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .main-row {
      justify-content: center;
      .leftside-pic {
        display: none;
      }
    }

    .bottom-pic {
      display: none;
    }
  }
`;

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .wrapper {
    width: 370px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 12px;
    .bannerImg img {
      width: 285px;
      height: 285px;
    }
    .btnGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      width: 100%;
      .ant-btn {
        width: 100%;
      }
    }
  }
`;

export const PageHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${palette.MAIN_COLOR};
  text-align: center;
  margin-bottom: 40px;
`;
export const ForgotPasswordLink = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  a {
    color: #1e232c;
    text-decoration: none;
  }
`;

export const OtherLogins = styled.div`
  .seperator {
    margin-bottom: 24px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: ${palette.DARK_GRAY};
    &:before,
    &:after {
      background-color: ${palette.CYAN_BLUE_LIGHT};
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
`;

export const LoginButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  gap: 6px;
  button {
    height: 56px !important;
    border: 1px solid ${palette.MEDIUM_GRAY} !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 30px !important;
    img {
      width: 26px;
      height: 26px;
    }
  }
`;

export const NoAccount = styled.div`
  p {
    font-size: 16px;
    font-weight: 500;
    color: ${palette.MAIN_COLOR};
    text-align: center;
    span {
      cursor: pointer;
      font-weight: 700;
      color: ${palette.PRIMARY_COLOR};
    }
  }
`;
