import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { TeamsMainStyle } from "./styles";
import { Button, Layout, Card, Col, Row, Spin, FloatButton  } from "antd";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import TeamCardView from "../../components/Teams/cardView";
import { fetchTeamsList } from "../../redux/actions/teams.actions";
import TeamsDrawer from "../../components/Teams/teamsDrawer";
import NoDataFound from "../../assets/teams/no-data-found.svg";
import { PlusOutlined } from "@ant-design/icons";
const { Content } = Layout;

function TeamsMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const currentUser = useSelector((state) => state.auth.user);
  const teamsListData = useSelector((state) => state.teams);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getTeams();
    // setIsLoading(false);  
    // if (!teamsListData.teamsList.length) {
    //   getTeams();
    // } else {
    //   setIsLoading(false);  
    // }
  }, []);

  const getTeams = async () => {
    await dispatch(fetchTeamsList(currentUser?.user_id));
    setIsLoading(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content>
          <Navbar />
          <TeamsMainStyle>
            <div className="addBtn-container">
              <Button
                type="primary"
                onClick={showDrawer}
                className="addteam-btn"
              >
                + Add Team
              </Button>
            </div>
            <div className="card-size">
            <h3 className="teams-heading">Teams</h3>
              {isLoading ? (
                <Spin tip="Loading" size="small" style={{ marginTop: "180px" }}>
                  <div className="content" />
                </Spin>
              ) : teamsListData?.teamsList?.length ? (
                teamsListData?.teamsList?.map((team, index) => (
                  <TeamCardView data={team} index={index} key={team.id} />
                ))
              ) : (
                <div className="data-not-found">
                  <img
                    src={NoDataFound}
                    alt=""
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      margin: 0,
                      marginRight: "auto",
                    }}
                  />
                </div>
              )}
            </div>

            {isMobile ? <FloatButton  icon={<PlusOutlined />}  type="primary" onClick={showDrawer} className="floatAddTeamButton" /> :"" }
          </TeamsMainStyle>
        </Content>
      </Layout>
      <TeamsDrawer drawerVisible={drawerVisible} onClose={onCloseDrawer} />
    </Layout>
  );
}

export default TeamsMain;
