import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { addTeamMember } from "../../redux/actions/teams.actions";
import { AddTeamMemberStyle } from "./styles";

function AddTeamMembers({teamDatas, onClose}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth?.user);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { email } = values;
    setIsLoading(true);
    const memberData = {
      email: email || "",
      status: false,
      user_id: "",
      actions: "User",
    };
    const add = await dispatch(
      addTeamMember({ teamId: teamDatas?.id, memberData })
    );

    if (add?.type === "teams/addTeamMemberAsync/rejected") {
      message.error({ content: "Email already exists", key: "loading" });
      setIsLoading(false);
    } else {
      message.success({ content: "Members added successfuly", key: "loading" });
      form.resetFields();
      setIsFormDirty(false);
      setIsLoading(false);
      onClose();
    }
  };
  const layout = {
    layout: "vertical",
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleCancel = () => {
    onClose();
  };

  const onValuesChange = () => {
    setIsFormDirty(true);
  };

  const rules = {
    required: [{ required: false, message: "This field is required" }],
    email: [
      { type: "email", message: "Please enter a valid email address" },
      { max: 320, message: "Email must be less than 320 characters" },
    ],
  };

  return (
    <>
    <AddTeamMemberStyle>
      <div className="profileUpdate-card-main">
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          className="addTeam-member"
        >
          <Form.Item name="email" label="Member" rules={rules.email}>
            <Input placeholder="Enter the Email" />
          </Form.Item>
        <div className="teams-btn-container">
          <Form.Item className="submit-button cancel-button">
            <Button
              onClick={() => handleCancel()}
              default
              style={{ width: "100%" }}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!isFormDirty}
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
        </Form>
      </div>
    </AddTeamMemberStyle>
    </>
  );
}

export default AddTeamMembers;
