import React from "react";
import { Drawer } from "antd";
import AddTeams from "./addTeam/addTeams";
import styled from "styled-components";

function TeamsDrawer({ drawerVisible, onClose }) {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const AddTeamDrawerStyle = styled.div`
    overflow-y: auto;
    height: auto;

    @media only screen and (max-width: 768px) {
      overflow-y: auto;
      height: calc(100vh - 170px);
    }
  `;

  return (
    <Drawer
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: "24px",
            textAlign: "center",
            color: "#000",
          }}
        >
          Add a Team
        </div>
      }
      placement={isMobile ? "bottom" : "right"}
      width={isMobile ? "100%" : 378}
      // height={'100vh'}
      closable={true}
      onClose={onClose}
      visible={drawerVisible}
      className="drawer"
    >
      <AddTeamDrawerStyle>
        <AddTeams onClose={onClose} />
      </AddTeamDrawerStyle>
    </Drawer>
  );
}

export default TeamsDrawer;
