// FlagImage.js

import React from 'react';
import redFlag from "../../assets/home/red-flag.svg";
import yellowFlag from "../../assets/home/yellow-flag.svg";
import greenFlag from "../../assets/home/green-flag.svg";
import blackFlag from "../../assets/home/black-flag.svg";

const FlagImage = ({ index, select }) => { 
  var flagImages = [
    redFlag,
    yellowFlag,
    greenFlag,
    blackFlag
  ]
  if(select){
     flagImages = [
      redFlag,
      redFlag,
      yellowFlag,
      greenFlag,
      blackFlag
    ]
  }
  return <img style={{width: '12px'}} src={flagImages[index]} alt='' />
};

export default FlagImage;
