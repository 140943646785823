import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { Form, Input, Button, Select, Upload, message, Space} from 'antd';
import { useNavigate } from "react-router-dom";
import { ProfileUpdateStyle } from "./styles";
import { profileUpdateApi } from "../../redux/actions/auth.thunks";
import ProfileImage from "./image";
const { Option } = Select;

function ProfileUpdate({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (currentUser?.user_id) {
      form.setFieldsValue({
        name: currentUser?.name,
        lastName: currentUser?.lastName,
        email: currentUser?.email,
        phone: currentUser?.phone,
        countryCode: currentUser?.countryCode || "91",
      });
    } 
  }, [currentUser, form]);
  const onFinish = async (values) => {
    const { name, lastName, email, phone, countryCode } = values;
    const isValidPhone = /^\d+$/.test(phone);

    if (!isValidPhone && phone && phone!='' && phone != undefined) {
        message.error("Phone number must contain only numbers.");
        return;
    }


    const trimmedName = name.trim();
    const isValidName = trimmedName.length >= 3;

    if (!isValidName) {
        message.error("Name must be at least 3 characters long.");
        return;
    }


    message.loading({ content: "Loading", key: "loading" }); 

    const data = {
      name: trimmedName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
      countryCode: countryCode || '91'
  };
    const a =  await dispatch(profileUpdateApi({ userId: currentUser?.user_id, data }));
    
    message.success({ content: "Updated successfuly", key: "loading" }); 

    return false;
  };
  const layout = {
    layout: 'vertical', 
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const rules = {
    required: [{ required: false, message: 'This field is required' }],
    email: [
      { type: 'email', message: 'Please enter a valid email address' },
      { max: 320, message: 'Email must be less than 320 characters' },
    ],
    phoneNumber: [
      { required: false, message: 'Please enter your phone number' },
      {
        pattern: /^[0-9]*$/,
        message: 'Phone number must be between 10 digits',
      },
    ],
  };

 
  const prefixSelector = (
    <Form.Item name="countryCode" noStyle>
      <Select
        style={{ width: 100 }}
        defaultValue="91"
        dropdownStyle={{ paddingTop: 8, paddingBottom: 8 }}
        optionLabelProp="label"
      >
        <Option value="91" label={<><span role="img" aria-label="India">🇮🇳</span> +91</>}>+91</Option>
        <Option value="87" label={<><span role="img" aria-label="UK">🇬🇧</span> +87</>}>+87</Option>
      </Select>
    </Form.Item>
  );

  const handleCancel = () => {
      // navigate("/");
      onClose();
  }

  const onValuesChange = () => {
    setIsFormDirty(true);
  };


 
  return (
    <ProfileUpdateStyle>
      <div className="profileUpdate-card-main">

          <ProfileImage />
      <Form {...layout} form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
      {/* First Name */}
      <Form.Item name="name" label="First Name"  
      rules={[
        {required: true, message: 'Please input the name!',}, 
        { whitespace: true, message: 'Enter your name' },
        ]}>
        <Input required placeholder="Enter the name"/>
      </Form.Item>

      {/* Last Name */}
      <Form.Item name="lastName" label="Last Name" rules={[ { whitespace: true, message: 'Enter your last name' }]}>
        <Input  placeholder="Enter lastname" />
      </Form.Item>

      {/* Email */}
      <Form.Item name="email" label="Email *" rules={rules.email} >
        <Input readOnly />
      </Form.Item>

      {/* Country Code */}
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: false,
            message: 'Please input your phone number!',
          },
          { whitespace: true, message: 'Enter your number' }
        ]}
      >
        <Input
          addonBefore={prefixSelector}
          minLength={10}
          maxLength={10}
          style={{
            width: '100%',
          }}
        />
      </Form.Item>

    <Form.Item className="submit-button">
   
        <Button onClick={() => handleCancel()} default style={{width: '100%'}}>
            Cancel
        </Button>
  
      </Form.Item>


      <Form.Item className="submit-button">
    
        <Button type="primary" htmlType="submit" disabled={!isFormDirty} style={{width: '100%'}}>
          Save
        </Button>
        
      </Form.Item>

      
    </Form>
      </div>
    </ProfileUpdateStyle>
  );
}

export default ProfileUpdate;
