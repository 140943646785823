import styled from "styled-components";

export const TaskCardStyle = styled.div`
  .todo-body {
    .webView {
      display: block;
    }

    .mobileView {
      display: none;
    }

    .dropdownbtn{
      width: 15px;
      position: relative;
      top: 2px;
    }
    
    @media only screen and (max-width: 768px) {
      .webView {
        display: none;
      }

      .mobileView {
        display: block;
      }
    }
  }
`;
