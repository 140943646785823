import { Button, Popover, Badge, Modal, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../backend/firebase";
import logo from "../../assets/navbar/logo.svg";
import menu from "../../assets/navbar/menu.svg";
import notificationIcon from "../../assets/notification-icon.svg";
import NotificationList from "../Notification/notificationList";
import { NavStyle } from "./styles";
import { logout } from "../../redux/actions/signOut.actions";
import ProfileDrawer from "../ProfileUpdate/profileDrawer";

import { ReactComponent as ReacthomeIcon } from "../../assets/sidebar/newHome.svg";
import { ReactComponent as ReacttaskIcon } from "../../assets/sidebar/newTask.svg";
import { ReactComponent as ReactlogoutIcon } from "../../assets/sidebar/newLogout.svg";
import { ReactComponent as ReactcalendarIcon } from "../../assets/sidebar/newCalender.svg";
import { ReactComponent as ReactsettingsIcon } from "../../assets/sidebar/newSetting.svg";
import { ReactComponent as ReactTeamsIcon } from "../../assets/sidebar/newTeams.svg";

import {
  DashboardOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";

function Navbar({ handleTaskModalOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const notification = useSelector((state) => state.notifications);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [profile, setProfile] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleNotificationRoute = () => {
    navigate("/notification");
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleProfile = () => {
    navigate("/profile");
  };
  const handleTaskPage = () => {
    navigate("/task");
  };
  const handleTeamsPage = () => { 
    navigate("/teams");
  };

  useEffect(() => {
    if (currentUser?.user_id) {
      const profile_name = currentUser?.name;
      const firstLetter = profile_name[0];
      setProfile(firstLetter);
    }
  }, []);

  const handleSignOut1 = async () => {
    await dispatch(logout());
    await signOut(auth)
      .then(() => {
        navigate("/signin");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleSignOut = async () => {
    Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to logout?",
      onOk: async () => {
        try {
          await dispatch(logout());
          await signOut(auth);
          navigate("/signin");
        } catch (err) {
          console.log(err);
        }
      },
    });
  };
  const handleTaskModal = () => {
    handleTaskModalOpen(true);
  };

  const handleNotificationVisibleChange = (visible) => {
    setNotificationVisible(visible);
  };

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuItemClick = (key) => {
    setShowMenu(!showMenu);
    setSelectedKey(key);
    if(key=='/settings'){
      navigate("/profile");
    }
    if(key=='/logout'){
      handleSignOut()
    }
  };

  // const handleMenuItemClick = () => {
  //   // Handle menu item click event here if needed
  //   setShowMenu(false); // Close the menu after a menu item is clicked
  // };

  return (
    <>
      <div style={{ position: "sticky", top: 0, left: 0, right: 0, zIndex: 1 }}>
        <NavStyle className="NavBar">
          <div className="home-nav">
            <div style={{ cursor: "pointer" }}>
              {isMobile ? (
                <div>
                  <img
                    style={{ width: "30px" }}
                    src={menu}
                    alt=""
                    className="mobileLogo"
                    onClick={handleMenuClick} // Toggle the menu visibility when the menu icon is clicked
                  />
                  {showMenu && (
                    <Menu mode="vertical" className="navbarHead">
                      <Menu.Item
                        key="/"
                        icon={
                          <ReacttaskIcon
                            className={selectedKey == "/" ? "selected" : ""}
                          />
                        }
                        className={
                          selectedKey == "/" ? "menu-item-selected" : ""
                        }
                        onClick={() => handleMenuItemClick("/")}
                      >
                        <Link to="/">Dashboard</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="/task"
                        icon={
                          <ReacthomeIcon
                            className={selectedKey == "/task" ? "selected" : ""}
                          />
                        }
                        className={
                          selectedKey == "/task" ? "menu-item-selected" : ""
                        }
                        onClick={() => handleMenuItemClick("/task")}
                      >
                        <Link to="/task">Tasks</Link>
                      </Menu.Item>
                      <Menu.Item
                        key="/teams"
                        icon={
                          <ReactTeamsIcon
                            className={
                              selectedKey == "/teams" ? "selected" : ""
                            }
                          />
                        }
                        className={
                          selectedKey == "/teams" ? "menu-item-selected" : ""
                        }
                        onClick={() => handleMenuItemClick("/teams")}
                      >
                        <Link to="/teams">Teams</Link>
                      </Menu.Item>
                      
 
                      <Menu.Item
                        key="/settings"
                        icon={
                          <ReactsettingsIcon
                            className={
                              selectedKey == "/settings" ? "selected" : ""
                            }
                          />
                        }
                        onClick={() => handleMenuItemClick("/settings")}
                        className={
                          selectedKey == "/settings" ? "menu-item-selected" : ""
                        }
                      >
                        Settings
                      </Menu.Item>
                      <Menu.Item
                        key="/logout"
                        icon={
                          <ReactlogoutIcon
                            className={
                              selectedKey == "/logout" ? "selected" : ""
                            }
                          />
                        }
                        onClick={() => handleMenuItemClick("/logout")}
                        className={
                          selectedKey == "/logout" ? "menu-item-selected" : ""
                        }
                      >
                        Logout
                      </Menu.Item>
                    </Menu>
                  )}
                </div>
              ) : (
                <img
                  style={{ width: "84px" }}
                  src={logo}
                  alt=""
                  className="mobileLogo"
                />
              )}
            </div>
            <div style={{ display: "inline-flex", cursor: "pointer" }}>
              {isMobile ? (
                <>
                  <span
                    className="notificationIcons"
                    style={{
                      marginRight: "30px",
                      marginTop: "12px",
                    }}
                    onClick={handleNotificationRoute}
                  >
                    <Badge
                      size="small"
                      count={notification?.notificationCounts?.unread}
                    >
                      <img src={notificationIcon} alt="" />
                    </Badge>
                  </span>
                </>
              ) : (
                <Popover
                  placement="bottomRight"
                  title={
                    <div
                      style={{
                        minWidth: "290px",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      Notifications
                    </div>
                  }
                  content={<NotificationList />}
                  trigger="click"
                  visible={notificationVisible}
                  onVisibleChange={handleNotificationVisibleChange}
                  style={{
                    width: "400px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    height: "300px",
                  }}
                >
                  <span
                    className="notificationIcons"
                    style={{
                      marginRight: "30px",
                      marginTop: "12px",
                    }}
                  >
                    <Badge
                      size="small"
                      count={notification?.notificationCounts?.unread}
                    >
                      <img src={notificationIcon} alt="" />
                    </Badge>
                  </span>
                </Popover>
              )}

              <div
                className={
                  currentUser?.imageUrl && currentUser?.imageUrl !== ""
                    ? "profilePicture-main"
                    : ""
                }
              >
                <Popover
                  className="todo-profile-popover"
                  placement="bottomLeft"
                  content={
                    <div
                      className="profile-content"
                      style={{ cursor: "pointer" }}
                    >
                      {/* <p className="menu-dropdown" onClick={handleTaskModal}> Create Task </p> */}
                      <li className="menu-dropdown" style={{marginBottom: '5px'}} onClick={handleTaskPage}><p> Tasks </p></li>
                      <li className="menu-dropdown" style={{marginBottom: '5px'}} onClick={handleTeamsPage}><p> Teams </p></li>
                      <li className="menu-dropdown" style={{marginBottom: '5px'}} onClick={isMobile ? handleProfile : showDrawer} ><p> Settings </p></li>
                      <li className="menu-dropdown" style={{marginBottom: '5px'}} onClick={handleSignOut}><p> Signout</p></li>
                    </div>
                  }
                  // trigger="click"
                >
                  {currentUser?.imageUrl && currentUser?.imageUrl !== "" ? (
                    <img
                      src={currentUser?.imageUrl}
                      alt="Profile"
                      className="profilePicture"
                    />
                  ) : (
                    <Button className="profile-btn">{profile}</Button>
                  )}
                </Popover>
              </div>

              <ProfileDrawer drawerVisible={drawerVisible} onClose={onClose} />
            </div>
          </div>
        </NavStyle>
      </div>
    </>
  );
}

export default Navbar;
