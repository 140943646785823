import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, where, query, doc, updateDoc, limit, orderBy,getDoc } from "@firebase/firestore";
import { db } from "../../backend/firebase";

export const fetchNotificationsAsync = createAsyncThunk(
    "notification/fetchNotificationsAsync",
    async (userId, { rejectWithValue}) => {
        try {
            const getNotifications = collection(db, "notifications");
            const notificationWuery = query(getNotifications, where('userId', '==', userId), orderBy('timestamp', 'desc'), limit(10));
            const notificationSnapshot = await getDocs(notificationWuery);

            const notifications = notificationSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            // const notificationsWithTasksPromises = notifications.map(async (notification) => {
            //     const tasksCollection = collection(db, "tasks");
            //     const tasksQuery = query(tasksCollection, where('__name__', '==', notification.taskId));
            //     const tasksSnapshot = await getDoc(tasksQuery);
            //     const tasks = tasksSnapshot.doc.map((taskDoc) => ({
            //         id: taskDoc.id,
            //         ...taskDoc.data(),
            //     }));
 
            //     return { ...notification, tasks };
            // });

            const notificationsWithTasksPromises = notifications.map(async (notification) => {
                const tasksCollection = collection(db, "tasks");
                const taskDocRef = doc(tasksCollection, notification.taskId);
                const taskDocSnapshot = await getDoc(taskDocRef);
            
                if (taskDocSnapshot.exists()) {
                    const taskData = taskDocSnapshot.data();
                    const task = {
                        id: taskDocSnapshot.id,
                        ...taskData
                    };
                    return { ...notification, task };
                } else {
                    return { ...notification, task: null }; // Task not found
                }
            });

            const notificationsWithTasks = await Promise.all(notificationsWithTasksPromises);

            return notificationsWithTasks;
        } catch (error) {
            console.error("Error fetching notification:", error);
            return rejectWithValue(error.response.data.message);
        }
    }
)


export const updateNotificationStatusAsync = createAsyncThunk(
    "notification/updateNotificationStatusAsync",
    async (notificationId , {rejectWithValue}) => {
        try {
            const notificationRef = doc(db, "notifications", notificationId);
            await updateDoc(notificationRef, {
                status: 'read'
            });
            return { notificationId };
        } catch (error) {
            console.error("Error updating notification status:", error);
            return rejectWithValue(error.response.data.message);
        }
    }
)

export const markAllNotificationsAsReadAsync = createAsyncThunk(
    "notification/markAllNotificationsAsReadAsync",
    async (userId, { rejectWithValue }) => {
        try {
            const notificationsRef = collection(db, "notifications");
            const querySnapshot = await getDocs(
                query(notificationsRef, where("userId", "==", userId))
            );

            const updateActions = querySnapshot.docs.map(async (docs) => {
                const notificationId = docs.id;
                const notificationRef = doc(db, "notifications", notificationId);
                await updateDoc(notificationRef, { status: 'read' });
            });

            await Promise.all(updateActions);

            return true;  
        } catch (error) {
            console.error("Error marking all notifications as read:", error);
            return rejectWithValue(error.response.data.message);
        }
    }
);
