import React from "react";
import { Card, Col, Row } from "antd";
import starIcon from "../../assets/dashboard/star.svg";
import pic1 from "../../assets/dashboard/pic1.svg";
import pic2 from "../../assets/dashboard/pic2.svg";
import pic3 from "../../assets/dashboard/pic3.svg";
import { DashboardStyles } from "./styles";
const TaskCards = ({ counts }) => {
  return (
    <DashboardStyles>
      <Row gutter={16}>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: "15px" }}
        >
          <Card
            title={
              <div className="cardTitle">
                <img src={starIcon} alt="" />
                <span className="title">Total</span>

                <span className="count">{counts.TotalCount}</span>
              </div>
            }
            bordered={false}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={pic1} alt="" style={{ width: "50%" }} />
              <p>Total task</p>
            </div>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: "15px" }}
        >
          <Card
            title={
              <div className="cardTitle">
                <img src={starIcon} alt="" />
                <span className="title">Completed </span>

                <span className="count">{counts.Completed}</span>
              </div>
            }
            bordered={false}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={pic2} alt="" style={{ width: "50%" }} />
              <p>Completed</p>
            </div>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: "15px" }}
        >
          <Card
            title={
              <div className="cardTitle">
                <img src={starIcon} alt="" />
                <span className="title">New</span>

                <span className="count">{counts.Pending}</span>
              </div>
            }
            bordered={false}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={pic3} alt="" style={{ width: "50%" }} />
              <p>New Task</p>
            </div>
          </Card>
        </Col>
      </Row>
    </DashboardStyles>
  );
};
export default TaskCards;
