import styled from "styled-components";
 
export const ProfileStyle = styled.div`
  .main-profiles {
    padding: 20px;
    
    .breadcrumb-title{
        display: flex;
        text-align: center;
        .back-arrow{
            margin-right: 10px;
            margin-top: 5px;
            color: rgb(0, 0, 0);
            font-weight: 700;
        }
        .title-bar{
            margin-bottom: 10px;
            color: rgb(0, 0, 0);
            text-align: center;
            font-weight: 700;
            flex: 1 1 0%;
            font-size: 20px;
        }
    }
    .
  }
`;
