import { getToken, onMessage } from "firebase/messaging";
import { messaging, db } from "../../backend/firebase";
import "firebase/compat/firestore"; // Import Firestore
import { collection, updateDoc, getDoc, doc } from "firebase/firestore";
import { message, } from "antd";
import notificationSound from '../../assets/notification-tone.mp3';
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsAsync} from '../../redux/actions/notification.actions';

const useNotifications = () => {
  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const requestNotificationPermission = async () => {
    try {
      if (!("Notification" in window)) {
        console.error("This browser does not support desktop notification");
        return false;
      }

      let permission = Notification.permission;
      if (permission === "granted") {
        return true;
      }

      if (permission !== "denied") {
        permission = await Notification.requestPermission();
      }

      if (permission === "granted") {
        return true;
      } else {
        // Permission denied or blocked
        return false;
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return false;
    }
  };

  const getNotificationToken = async () => {
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const token = await getToken(messaging);
      const userId = currentUser?.user_id;
      // console.log(token, "tokemmmm")
      // Initialize Firebase if not already done
      const userDocRef = doc(collection(db, "users"), userId);

      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        let tokens = userData.tokens || [];
        tokens = Array.isArray(tokens) ? tokens : [tokens];
        if (!tokens.includes(token)) {
          tokens.push(token);
          await updateDoc(userDocRef, { tokens });
        } else {
          // console.log("Token already exists in user document.");
        }
      }

      return token;
    } catch (error) {
      console.error("Error getting notification token:", error);
      return null;
    }
  };

  const subscribeToMessages = () => {
    onMessage(messaging, (payload) => {
      if (Notification.permission === "granted") {
        const { title, body } = payload.data;
        message.success(<>
        <p>title: {title}</p>
        <p>desc: {body}</p>
        </>);    
        new Notification(title, { body });
        playAudio();  
      }
    });
  };

  const playAudio = async () => {
    const audio = new Audio(notificationSound);
    audio.play();
    dispatch(fetchNotificationsAsync(currentUser?.user_id));
  };

  return {
    requestNotificationPermission,
    getNotificationToken,
    subscribeToMessages,
  };
};

export default useNotifications;
