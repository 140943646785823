// auth.thunk.js
import {GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword} from 'firebase/auth';
import { setDoc, doc, getDocs, query, collection, where, updateDoc, getDoc} from 'firebase/firestore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db, storage } from '../../backend/firebase';
import {
    ref,
    uploadBytes,
    getDownloadURL, 
  } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
export const googleLogin = createAsyncThunk(
    'auth/googleLogin',
    async(_, { rejectWithValue }) => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const dbRef = doc(db, 'users', `${user.uid}`);
            const docSnapshot = await getDoc(dbRef);
            if (docSnapshot.exists()) {
                await updateDoc(dbRef, {
                    name: user.displayName,
                    email: user.email,
                    user_id: user.uid,
                });
            } else {
                await setDoc(dbRef, {
                    name: user.displayName,
                    email: user.email,
                    user_id: user.uid,
                });
            }
            // Get user info
            const q = query(collection(db, 'users'), where('user_id', '==', user.uid));
            const querySnapshot = await getDocs(q);

            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const info = {
                email: data[0].email,
                user_id: data[0].user_id,
                name: data[0].name,
            }

            if (data[0].lastName) {
                info.lastName = data[0].lastName;
            }

            if (data[0].phone) {
                info.phone = data[0].phone;
            }

            if (data[0].countryCode) {
                info.countryCode = data[0].countryCode;
            }

            if (data[0].imageUrl) {
                info.imageUrl = data[0].imageUrl;
            }

            return info;
        } catch (error) {
            throw rejectWithValue(error);
        }

});

export const signInWithEmailPassword = createAsyncThunk(
    'auth/signInWithEmailAndPassword',
    async({email, password}, {rejectWithValue}) => {
        try {
             
            const result = await signInWithEmailAndPassword(auth, email, password);
            const user = result.user;
             // Get user info
             const q = query(collection(db, 'users'), where('user_id', '==', user.uid));
             const querySnapshot = await getDocs(q);
 
             const data = querySnapshot.docs.map((doc) => ({
                 id: doc.id,
                 ...doc.data(),
             }));

             const info = {
                 email: data[0].email,
                 user_id: data[0].user_id,
                 name: data[0].name,
             }
             
            if (data[0].lastName) {
                info.lastName = data[0].lastName;
            }

            if (data[0].phone) {
                info.phone = data[0].phone;
            }

            if (data[0].countryCode) {
                info.countryCode = data[0].countryCode;
            }
            
            if (data[0].imageUrl) {
                info.imageUrl = data[0].imageUrl;
            }
            
            return { info }
        } catch (error) {
            console.error('Email login error:', error);
            throw rejectWithValue(error);
        }
    }
);
  
export const signUp = createAsyncThunk(
    'auth/signUp',
    async (user_id, { rejectWithValue }) => {
      try {
        const uid = user_id.user_id;
      
        const q = query(collection(db, 'users'), where('user_id', '==', uid));
        const querySnapshot = await getDocs(q);

        const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        const info = {
            email: data[0].email,
            user_id: data[0].user_id,
            name: data[0].name,
        }

         if (data[0].lastName) {
            info.lastName = data[0].lastName;
        }

        if (data[0].phone) {
            info.phone = data[0].phone;
        }

        if (data[0].countryCode) {
            info.countryCode = data[0].countryCode;
        }

        if (data[0].imageUrl) {
            info.imageUrl = data[0].imageUrl;
        }
        return { info }
        
      } catch (error) {
        // Handle registration errors
        console.log(error, "...ERROR...")
        throw rejectWithValue(error);
      }
    }
);

export const profileUpdateApi = createAsyncThunk(
    'profile/Update',
    async ({userId, data}, { rejectWithValue }) => {
        try {
            const profileRef = doc(db, "users", userId);
            const a= await updateDoc(profileRef, data);
            const getDocSnapshot = await getDoc(profileRef);
            return {
            id: getDocSnapshot.id,
            ...getDocSnapshot.data(),
            };
        } catch (error) {
        console.error("Error fetching tasks:", error);
        return rejectWithValue(error.response.data.message);
        }
    }
)

export const ImpageFileUpload1 = createAsyncThunk(
  "profile/Image",
  async (imageFile, { rejectWithValue, getState }) => {
    try {
      const imageName = `${uuidv4()}_${imageFile.name}`;
      const storageRef = ref(storage, `images/${imageName}`);
      await uploadBytes(storageRef, imageFile);
      const imageUrl = await getDownloadURL(storageRef);

      const currentUser = getState().auth.user;
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.user_id);
        await updateDoc(userDocRef, {
            imageUrl: imageUrl
        });
    }
      return imageUrl;
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const ImpageFileUpload = createAsyncThunk(
    "profile/Image",
    async (imageFile, { rejectWithValue, getState }) => {
      try {
        const imageName = `${uuidv4()}_${imageFile.originFileObj.name}`;
        const storageRef = ref(storage, `images/${imageName}`);
        await uploadBytes(storageRef, imageFile.originFileObj);
        const imageUrl = await getDownloadURL(storageRef);
        const currentUser = getState().auth.user;
        if (currentUser) {
          const userDocRef = doc(db, 'users', currentUser.user_id);
          await updateDoc(userDocRef, {
              imageUrl: imageUrl
          });
      }
        return imageUrl;
      } catch (error) {
        console.error("Error fetching tasks:", error);
        return rejectWithValue(error.message);
      }
    }
  );
  