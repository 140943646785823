import React, { useState, useEffect } from "react";
import CreateTask from "../../components/Task/CreateTask";
import Navbar from "../../components/Navbar";
import { DashboardStyle } from "./styles";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Layout, Card, Col, Row, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import TaskCards from "../../components/Dashboard/taskCards";
import SearchFor from "../../components/Search/index";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaskCountsAsync } from "../../redux/actions/dashboard.actions";
 
const { Content } = Layout;

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const taskCounts = useSelector((state) => state.taskCounts.taskCount);
  
  const handleGoBack = () => {
    navigate("/");
  };

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  useEffect(() => {
    dispatch(fetchTaskCountsAsync(currentUser?.user_id));
  }, [dispatch, currentUser]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content>
          <Navbar handleTaskModalOpen={handleTaskModalOpen} />
          <DashboardStyle>
            {/* <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <SearchFor />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Button type="primary" style={{float:'right'}}> + Add Task</Button>
                </Col>
            </Row> */}
            <br />
            <TaskCards counts={taskCounts} />
          </DashboardStyle>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
