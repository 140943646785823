import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../backend/firebase';
import { resetState } from '../reducers/auth.reducer';
import { resetPriorityState } from '../reducers/priority.reducer';
import { resetNotificationsState } from '../reducers/notification.reducer';
import { resetTasksState } from '../reducers/tasks.reducer';

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { dispatch, rejectWithValue }) => {
      try {
        await auth.signOut();
        dispatch(resetState());
        dispatch(resetPriorityState());
        dispatch(resetNotificationsState());
        dispatch(resetTasksState());
      } catch (error) {
        console.error("Error logging out:", error);
        return rejectWithValue(error.message);
      }
    }
)