import { createSlice } from "@reduxjs/toolkit";
import { fetchTaskCountsAsync } from "../actions/dashboard.actions";

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        taskCount: {
            Pending: 0,
            Completed: 0,
            TotalCount: 0
        },
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTaskCountsAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchTaskCountsAsync.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            const { completedCount, pendingCount, totalCount } = action.payload;
            state.taskCount = {
                Completed: completedCount,
                Pending: pendingCount,
                TotalCount: totalCount
            };
        });
        builder.addCase(fetchTaskCountsAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const selectTaskCount = (state) => state.dashboard.taskCount;
export const selectLoading = (state) => state.dashboard.loading;
export const selectError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
