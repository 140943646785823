import React from "react";
import { Drawer } from "antd";
import ProfileUpdate from "./index";

function ProfileDrawer({ drawerVisible, onClose }) {
  return (
    <Drawer
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: "24px",
            textAlign: "center",
            color: "#000",
          }}
        >
          Profile Settings
        </div>
      }
      placement="right"
      closable={true}
      onClose={onClose}
      visible={drawerVisible}
      className="drawer"
    >
      <ProfileUpdate onClose={onClose} />
    </Drawer>
  );
}

export default ProfileDrawer;
