import React, { useState, useEffect } from "react";
import { Button, Dropdown, Row, Col, Card, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TeamCardstyle } from "./styles";

import { CaretDownOutlined, DownOutlined } from "@ant-design/icons";
import TeamMembersList from "./teamMemberList";
import AddTeamMembersDrawer from "./teamsMemberDrawer";
import { useNavigate } from "react-router-dom";
import editMenu from "../../assets/teams/editMenu.svg";
import taskListMenu from "../../assets/teams/taskListMenu.svg";
import addMemberMenu from "../../assets/teams/addMemberMenu.svg";
import memberListMenu from "../../assets/teams/memberListMenu.svg";
import deleteMenu from "../../assets/teams/deleteMenu.svg";
import { deleteTeam, getTeamsTask } from "../../redux/actions/teams.actions";
import EditTeamsDrawer from "./editTeam/editTeamsDrawer";

function TeamCardView({ data, index }) {
  const dispatch = useDispatch();

  const [teamMemberDrawerListVisible, setTeamMemberDrawerListVisible] = useState(false);
  const [teamMemberDrawerAddVisible, setTeamMemberDrawerAddVisible] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const handleMembersList = (data) => {
    setTeamMemberDrawerListVisible(true);
    setSelectedTeam(data);
  };

  const handleMembersAddList = (data) => {
    setTeamMemberDrawerAddVisible(true);
    setSelectedTeam(data);
  };

  const onClose = () => {
    setTeamMemberDrawerListVisible(false);
    setTeamMemberDrawerAddVisible(false);
    setEditDrawerVisible(false)
  };

  const handleEditTeam = (data) => {
    setEditDrawerVisible(true)
    setSelectedTeam(data);
  };

  const handleTaskList = async (data) => {
    const teamId = data?.id;
    // await getTaskData(teamId);
    navigate(`/teams/${teamId}/tasks`);
  };

  // const getTaskData = async (teamId) => {
  //   try {
  //     const a = await dispatch(getTeamsTask(teamId));
  //   } catch (error) {
  //     console.error("Error fetching tasks:", error);
  //   }
  // };
  const handleDelete = async (teamId) => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure you want to delete this team?",
      onOk: async () => {
        try {
          message.loading({ content: "Loading", key: "loading" });
          await dispatch(deleteTeam(teamId));
          message.success({
            content: "Team delete successfuly",
            key: "loading",
          });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const items = [];

  if (data.user_id == currentUser?.user_id) {
    items.push({
      key: "1",
      label: (
        <span onClick={() => handleEditTeam(data)}>
          <img
            src={editMenu}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Edit Team
        </span>
      ),
    });
  }

  // Task List
  items.push({
    key: "2",
    label: (
      <span onClick={() => handleTaskList(data)}>
        <img
          src={taskListMenu}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Task List
      </span>
    ),
  });

  // Add Members
  if (data.user_id == currentUser?.user_id) {
    items.push({
      key: "3",
      label: (
        <span onClick={() => handleMembersAddList(data)}>
          <img
            src={addMemberMenu}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Add Members
        </span>
      ),
    });
  }

  // Members List
  items.push({
    key: "4",
    label: (
      <span onClick={() => handleMembersList(data)}>
        <img
          src={memberListMenu}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Members List
      </span>
    ),
  });

  // Delete
  if (data.user_id == currentUser?.user_id) {
    items.push({
      key: "5",
      label: (
        <span onClick={() => handleDelete(data)}>
          <img
            src={deleteMenu}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Delete
        </span>
      ),
    });
  }

  function getColorFromString(str) {
    // Generate a color based on a string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.abs(hash).toString(16).substring(0, 6);
    return "#" + "0".repeat(6 - color.length) + color; // Pad the color if necessary
  }

  const webVIewRender = () => {
    return (
      <Card className="teams-card" key={index} bordered={false}>
            <div className="card-main">
              <div
                style={{
                  flex: "0.5 1 0%",
                  padding: "2px 10px",
                }}
              >
                <h5>S. No</h5>
                <p>{index + 1}</p>
              </div>
              <div
                style={{
                  flex: "1 1 0%",
                  borderRight: "3px solid #e8edf3",
                  padding: "2px 10px",
                }}
              >
                <h5>Team Name</h5>
                <p>{data?.name}</p>
              </div>
              <div
                style={{
                  flex: "1 1 0%",
                  borderRight: "3px solid #e8edf3",
                  padding: "2px 50px",
                }}
              >
                <h5>No.of Members</h5>
                <div className="team-container">
                {data?.members.slice(0, 5).map((member, idx) =>
                    member?.userInfo?.imageUrl ? (
                      <img
                        key={idx}
                        src={member?.userInfo?.imageUrl}
                        alt=""
                        className="profile-picture"
                      />
                    ) : (
                      <span
                        className="teamMember-profile"
                        style={{
                          backgroundColor: getColorFromString(member?.email),
                        }}
                        key={idx}
                      >
                        {member?.email?.charAt(0).toUpperCase()}
                      </span>
                    )
                  )}
                  {data?.members?.length > 5 && (
                    <div className="more-members-indicator">
                      +{data.members.length - 5}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  flex: "1 1 0%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                  <Button className="teams-menu-btn">
                    Menu <CaretDownOutlined style={{ color: "#000000" }} />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Card>
    );
  }

  const mobileVIewRender = () => {
    return (
      <Card className="teams-card" key={index} bordered={false}>
            <div className="card-main">
              <div
                style={{
                  flex: "0 1 0%",
                  padding: "2px 10px",
                }}
              >
                <h5>#</h5>
                <p>{index + 1}</p>
              </div>
              <div
                style={{
                  flex: "1 1 0%",
                  borderRight: "3px solid #e8edf3",
                  padding: "2px 10px",
                }}
              >
                <h5>{data?.name}</h5>
                <div className="team-container">
                {data?.members.slice(0, 5).map((member, idx) =>
                    member?.userInfo?.imageUrl ? (
                      <img
                        key={idx}
                        src={member?.userInfo?.imageUrl}
                        alt=""
                        className="profile-picture"
                      />
                    ) : (
                      <span
                        className="teamMember-profile"
                        style={{
                          backgroundColor: getColorFromString(member?.email),
                        }}
                        key={idx}
                      >
                        {member?.email?.charAt(0).toUpperCase()}
                      </span>
                    )
                  )}
                  {data?.members?.length > 5 && (
                    <div className="more-members-indicator">
                      +{data.members.length - 5}
                    </div>
                  )}
                </div>
              </div>
             
              <div
                style={{
                  flex: "0.2 1 0%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                  <DownOutlined  style={{ color: "#000000", padding: '10px' }}/> 
                </Dropdown>
              </div>
            </div>
          </Card>
    );
  }
  return (
    <TeamCardstyle>
      <Row gutter={16}>
        <Col span={24}>
          {isMobile ?  mobileVIewRender() : webVIewRender()  }
        </Col>
      </Row>

      <TeamMembersList
        drawerVisible={teamMemberDrawerListVisible}
        onClose={onClose}
        teamMembersData={data}
      />

      <AddTeamMembersDrawer
        drawerVisible={teamMemberDrawerAddVisible}
        onClose={onClose}
        teamsData={selectedTeam}
      />

      <EditTeamsDrawer drawerVisible={editDrawerVisible} onClose={onClose} Data={selectedTeam} />
    </TeamCardstyle>
  );
}

export default TeamCardView;
