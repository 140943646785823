import styled from "styled-components";
import * as palette from "../../styles/variables";

export const NavStyle = styled.div`
  .todo-task-btn {
    position: fixed;
    bottom: 20px;
    right: 23px;
    width: 60px;
    cursor: pointer;
  }
  .home-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 10px;
    background: ${palette.WHITE_COLOR};
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    height: 75px;
    h2 {
      color: ${palette.WHITE_COLOR};
    }
    .mobileLogo {
      display: none;
    }

    .notificationIcons {
      margin-right: 30px;
      margin-top: 10px;
      background: #f8f9fb;
      border-radius: 50%;
      padding: 8px;
      height: 30px;
    }
    .profilePicture-main {
      width: 36px;
      height: 35px;
      background: #fff;
      border-radius: 50%;
      margin-top: 8px;

      .profilePicture {
        width: 30px;
        height: 30px;
        display: flex;
        margin-top: 3px;
        margin-left: 3px;
        border-radius: 50%;
      }
    }

    .profile-btn {
      background: pink;
      color: ${palette.WHITE_COLOR};
      font-weight: 500;
      font-size: 23px;
      height: auto;
      border-radius: 50%;
    }
  }

  .todo-ant-modal {
    .ant-input {
      margin-top: 10px;
    }
  }

  @media (max-width: 767px) {
    .home-nav .mobileLogo {
      display: block;
    }
    .ant-menu {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 75px;
      left: 0;
    }
    .ant-menu-item {
      display: flex;
      align-items: center;
      color: #1e232c;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      background: #eeeff1;
      margin-bottom: 15px;
      border-radius: 4px;
      height: 48px !important;
    }
    .ant-menu-item-selected{
      color: #ffffff;
      background: #3594FF;
    }
    .menu-item-selected{
      color: #ffffff;
      background: #3594FF;
    }
    .ant-menu-title-content {
      a {
        text-decoration: none;
      }
    }
    .navbarHead {
      .selected g {
        fill: #ffffff;
      }
      .selected path {
        stroke: #ffffff;
        // stroke-width: 1px;
      }
      //  .not-selected g {
      //   fill: #1e232c;
      //  }
      //  .not-selected path {
      //   stroke: #1e232c;
      //   // stroke-width: 1px;
      //  }
    }
  }
`;
