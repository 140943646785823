import styled from "styled-components";

export const EditTeamsContainer = styled.div`
  .addteam-form {
    margin-top: 10px;
    .ant-input {
      padding: 15px;
      margin-top: 8px;
      font-size: 18px !important;
    }
    .ant-row {
      display: flex;
      flex-direction: column;
      .ant-form-item-label {
        text-align: left;
      }
    }
    label {
      font-family: Inter;
      font-size: 20px !important;
      font-weight: 500;
      line-height: 24px;
    }
    .teamMember-heading {
      font-family: Inter;
      font-size: 20px !important;
      font-weight: 500;
      line-height: 24px;
    }
    .btn-container {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        margin-top: 58px;
        gap: 20px;
        .ant-btn {
          padding: 12px;
          height: auto;
        }
      }
    }
  }
`;

export const EditTeamsDrawerStyle = styled.div`
  overflow-y: auto;
  height: auto;

  @media only screen and (max-width: 768px) {
    overflow-y: auto;
    height: calc(100vh - 170px);
  }
`;
