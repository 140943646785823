import styled from "styled-components";
import * as palette from "../../../styles/variables";

export const TeamsTaskCardsStyle = styled.div`
  .todo-body {
    .webView {
      display: block;
    }

    .mobileView {
      display: none;
    }

    .dropdownbtn {
      width: 15px;
      position: relative;
      top: 2px;
    }

    @media only screen and (max-width: 768px) {
      .webView {
        display: none;
      }

      .mobileView {
        display: block;
      }
    }
  }
`;

export const HomeStyle = styled.div`
  .todo-home {
    position: relative;
    // height: calc(100vh - 25px);
    .todo-task-btn {
      position: fixed;
      bottom: 20px;
      right: 23px;
      width: 60px;
      cursor: pointer;
    }
  }
  .todo-ant-modal {
    .ant-input {
      margin-top: 10px;
    }
  }
  .todo-tasks {
    .todo-no-tasks-content {
      position: absolute;
      top: 35%;
      right: 22%;
    }
    .todo-task-list {
      // height: 100vh;
      // background: ${palette.WHITE_COLOR};
      width: 80%;
      // margin-left: auto;
      // margin-right: auto;
      // border-top: 31px solid #ebf5ff;
      // overflow-y: scroll;
      padding: 30px 80px 17px;
      h3 {
        margin: 10px 0px 8px 5.5%;
      }
      .home-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 20px;
      }
    }
    .todo-body {
      box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
      background: rgba(255, 255, 255, 1);
      padding: 17px 20px 18px 15px;
      margin-top: 20px;
      // cursor: pointer;
      .todo {
        display: flex;
      }
      .tasklist-container {
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 2;
          letter-spacing: 0em;
          text-align: left;
          color: #000;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        p {
          line-height: 2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        // Timer

        .timers {
          img {
            width: 18px;
            height: 18px;
          }
          span {
            position: relative;
            top: -3px;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
          }
        }

        // Title

        .tasklist-title {
          font-size: 20px;
          font-weight: 400;
          color: #1e232cde;
          gap: 5px .tasklist-time {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
          }

          .edited-text {
            font-size: 12px;
            color: gray;
          }

          h5 {
            color: #23235f;
          }
        }

        // Prioritys

        .prioritys {
          p {
            span {
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: left;
              color: #778399;
              position: relative;
              left: 10px;
            }
          }
        }

        // Pin

        .pins {
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
            span {
              // position: relative;
              // left: 15px;
              margin-left: 10px;
            }
          }
        }

        // Edit
        .taskEdit {
          .text {
            top: -5px;
            position: relative;
          }
        }
      }
      .tasklist-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .todo-subtask {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: 24px;
        margin-top: 12px;
        p {
          font-size: 20px;
          font-weight: 400;
          color: #1e232cde;
        }
      }
    }
    .todo-body:hover {
      background: #ebf5ff;
      color: ${palette.PRIMARY_COLOR};
    }
    .task-icon-container {
      display: flex;
      gap: 10px;
    }
  }

  .statusCompleted,
  .TaskHeadLine {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #232360;
    line-height: 2;
    display: block;
  }
  .statusCompleted-btn {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .todo-tasks .todo-task-list {
      width: 100%;
      padding: 20px 30px 0px;

      .wrapper {
        margin-top: 100px;
      }
    }
    .todo-home .home-nav {
      padding: 0 10px 0 10px;
    }

    .statusCompleted {
      // display: none;
    }
    .statusCompleted-btn {
      display: block;
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 150px);
  margin: auto;
  text-align: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 153px;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: ${palette.MAIN_COLOR};
    }
    .newTask {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #0077d7;
      cursor: pointer;
    }
  }
`;

export const TeamCardstyle = styled.div`
  .teamsTask-webview {
    display: block;
  }

  .teamsTask-mobview {
    display: none;
  }
  margin-bottom: 25px;
  margin-top: 35px;
  .ant-row {
    border-radius: 8px;
  }
  .teams-heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
    margin-bottom: 20px;
  }
  .card-main {
    display: flex;
    justify-content: space-between;
    font-family: inter;
    align-items: center;

    .teamTask-contents {
      h5 {
        line-height: 2;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-transform: capitalize;
      }
      p {
        line-height: 2;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .team-profileName {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      margin-right: 4px;
    }

    .profile-picture {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
    .timers {
      img {
        width: 18px;
        height: 18px;
      }
    }
    .prioritys {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #23235f;
      margin-bottom: 15px;
    }

    p {
      color: #778399;
      display: flex;
      align-items: center;
      gap: 7px;
      font-weight: 500;
      font-size: 16px;
    }
    .time-container {
      font-size: 13px;
      span {
        font-weight: 500;
      }
    }

    .team-container {
      display: flex;
      align-items: center;
      gap: 7px;
      color: #778399;
      font-weight: 500;
      font-size: 16px;
    }

    .profile-picture {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  .teams-card {
    .ant-card-body {
      padding: 10px 24px !important;
    }
    .teams-menu-btn {
      background: #eff6ff;
      color: #3594ff;
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      border: none;
      display: flex;
      gap: 6px;
      align-items: center;
      height: auto;
      padding: 9px 24px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;
    margin-top: 15px;

    .teamsTask-webview {
      display: none !important;
    }

    .teamsTask-mobview {
      display: block;
      .todo-subtask {
        margin-left: 25px;
        margin-top: 15px;
        ul {
          list-style-type: auto;
        }
      }
      .taskcard-main2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
      .icon-display {
        display: none;
      }
      .flag-img {
        border: 1.8px solid rgb(134, 135, 231);
        border-radius: 4px;
        height: 28px;
        padding: 5px 11px 1px;
      }
      .teamsTask-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .teamsTask-content-title {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        color: #1e232cde;
      }
      .teamsTask-content-date {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        color: #afafaf;
      }
      .team-container {
        display: flex;
        align-items: center;
        gap: 6px;
        .team-profileName {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
        }
        .team-userName {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          color: #1e232cde;
        }
      }
      .profile-picture {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        object-fit: cover;
      }
    }
  }
`;

export const TeamTaskListMainStyle = styled.div`
  width: 90%;
  position: relative;
  margin: auto;
  margin-top: 20px;
  .date-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #ffffffde !important;
    background: #3594ff !important;
    height: auto;
    margin-bottom: 30px;
  }
  .TaskHeadLine {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
  }
  .statusCompleted {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
  }
  .TeamHeadLine {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .teamTaskList-top {
    width: 77%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    margin-bottom: 35px;
  }
  .addteam-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #ffffffde !important;
    background: #3594ff !important;
    height: auto;
    padding: 8px 35px;
  }
  .card-size {
    width: 77%;
  }
  .teams-searchfor {
    width: 55%;
  }

  @media only screen and (max-width: 767px) {
    .TeamHeadLine {
      font-size: 20px;
      font-weight: 600;
    }
    .TaskHeadLine {
      font-size: 20px;
      font-weight: 600;
    }
    .statusCompleted {
      font-size: 20px;
      font-weight: 600;
    }
    .addteam-btn {
      display: none;
    }
    .teamTaskList-top {
      width: 100%;
    }
    .teams-searchfor {
      width: 100%;
      .ant-input-affix-wrapper {
        font-size: 16px !important;
      }
    }
    .date-btn {
      font-size: 12px;
    }
    .card-size {
      width: 100%;
    }
    .taskcard-main {
      display: flex;
      justify-content: space-between;
    }
    .taskcard-main1 {
      display: flex;
      align-items: center;
    }
  }
`;

export const MainTaskView = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  width: 300px; /* Adjust the width as needed */
  margin: 10px;
  p { font-size: 14px;}
  .title {
    color: #1e232cde;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 2;
  }

  .description {
    color: #666666;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7;
    span {
      color: #23235f;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .subHeading {
    color: #666666;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7;
    span {
      color: #23235f;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .subtask {
    margin-top: 15px;
    .subTaskTitle {
      color: #666666;
      text-transform: capitalize;
      span {
        color: #23235f;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .sub-description {
      margin-top: 10px;
      color: #666666;
      span {
        color: #666666;
        font-weight: 500;
      }
    }
  }

  .flag {
    margin-top: 15px;
    .periority {
      color: #778399;
      margin-left: 5px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .calendarIcons {
    position: relative;
    top: 3px;
    width: 15px;
  }

  .assignTo {
    display: flex;
    margin-left: 7px;
    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      top: 3px;
      display: center;
    }

    .userinfo {
      margin-left: 10px;
      .userName {
        color: #1e232c;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.5;
        text-transform: capitalize;
      }

      .userEmail {
        color: #1e232c;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .status {
    margin-top: 15px;
    color: #fff;
    .pending {
      background: #f0bd70;
      color: #fff;
      padding: 5px;
      border-radius: 7px;
      font-size: 12px;
    }
    .complete {
      background: #087f03;
      color: #fff;
      padding: 5px;
      border-radius: 7px;
      font-size: 12px;
    }
  }
`;

export const AddTask = styled.div`
  .custom-label {
    font-weight: 600;
    color: #1e232cde;
    font-size: 20px;
    line-height: 2;
  }
  .teams-task-form {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  @media only screen and (max-width: 768px) {
    .taskset-container {
      margin-top: 0px !important;
    }
  }
`;

export const CreateTeamsTaskStyle = styled.div`
  @media only screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
      .drawer {
        height: 400px !important;
        create-task-icons {
          img {
            cursor: pointer;
            width: 20px;
          }
        }
        .ant-drawer-title {
          display: flex;
          div {
            font-size: 20px !important;
          }
        }
      }
    }
  }
`;
