import styled from "styled-components";

export const TeamCardstyle = styled.div`
  margin-bottom: 25px;
  .ant-row {
    border-radius: 8px;
  }
  .teams-heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: #232360;
    margin-bottom: 20px;
  }
  .card-main {
    display: flex;
    justify-content: space-between;
    font-family: inter;
    .teamMember-profile {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #23235f;
      margin-bottom: 15px;
    }

    p {
      text-transform: capitalize;
    }

    .team-container {
      display: flex;
      gap: 1px;
    }

    .profile-picture {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  .teams-card {
    .ant-card-body {
      padding: 10px 24px !important;
    }
    .teams-menu-btn {
      background: #eff6ff;
      color: #3594ff;
      font-family: Inter;
      font-size: 13px;
      font-weight: 500;
      border: none;
      display: flex;
      gap: 6px;
      align-items: center;
      height: auto;
      padding: 9px 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    .teams-card .ant-card-body {
      padding: 5px 2px!important;
    } 
    .team-container {
      display: flex;
      gap: 1px !important;
    }

    .teams-card {
      .ant-card-body {
        padding: 10px 24px !important;
      }
      .teams-menu-btn {
        background: #eff6ff;
        color: #3594ff;
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        border: none;
        display: flex;
        gap: 6px;
        align-items: center;
        height: auto;
        padding: 6px 10px;
      }
    }
    
  }
`;

export const TeamMemberStyle = styled.div`
  text-align: center;

  .profile-picture {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
`;

export const MemberCardStyle = styled.div`
  background: #f6fafb;
  box-shadow: 3px 3px 16px 0px #00000014;
  padding: 20px 10px 10px;
  h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #1e232cde;
  }
  .members-count {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    color: #23235f;
    padding: 20px 0;
  }
  .members-details {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-bottom: 25px;
    button {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      width: auto;
      border: none;
      border-radius: 4px;
      padding: 4px 10px;
    }
    .accept-btn {
      background: #51b971cc;
    }
    .invite-btn {
      background: #ffac1c;
    }
    .ant-btn:hover {
      border: none;
      color: #ffffff;
    }
    .about-member {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #1e232c;
      flex: 1;
      h4 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
      }
      p {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .member-profile {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      color: blue;
      background: #adbce6;
    }
    .profile-picture {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .btns-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AddTeamMemberStyle = styled.div`
  .addTeam-member {
    label {
      font-family: Inter;
      font-size: 20px !important;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    .ant-input{
      padding: 15px;
      margin-top: 8px;
      font-size: 18px !important;
    }
    }
  }
  .teams-btn-container{
    position: absolute;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 88%;
    .ant-btn{
      padding: 14px;
      height: auto;
    }
  }

  .cancel-button{
    display: block;
  }

  @media only screen and (max-width: 768px) {
    .teams-btn-container {
      position: relative;
      bottom: 0px;
      width: 100%;
    }
    .cancel-button{
      display: none;
    }
  }
 
`;

export const AddTeamMemberDrawerStyle = styled.div`
  .drawer {
    position: relative;
  }
`;

export const AddTeamDrawerStyle = styled.div`
.addDrawerHeight {
  overflow-y: auto;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .addDrawerHeight {
    overflow-y: auto;
    height: calc(100vh - 170px);
  }
}
`;
