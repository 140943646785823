import { createSlice } from "@reduxjs/toolkit";
import { fetchPriorityOptionsAsync } from "../actions/priority.actions";

export const prioritySlice = createSlice({
  name: "priority",
  initialState: {
    priorityOptions: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetPriorityState: (state) => {
      return {
        priorityOptions: [],
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriorityOptionsAsync.pending, (state, action) => {
        state.loading = true;
        state.priorityOptions = action.payload;
      })
      .addCase(fetchPriorityOptionsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.priorityOptions = action.payload;
        state.error = "";
      })
      .addCase(fetchPriorityOptionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetPriorityState } = prioritySlice.actions;
export default prioritySlice.reducer;
