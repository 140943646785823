import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card, Spin, Drawer, Divider } from "antd";
import CalendarIcon from "../../../assets/teams/Calendar.png";
// import { fetchTaskId } from "../../../redux/actions/task.actions";
import FlagImage from "../../../components/FlagImages";
import { TeamTaskViewStyle, MainTaskView } from "./styles";

function TeamsTaskView({ drawerVisible, onClose, Data }) {
  const dispatch = useDispatch();
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [isLoading, setIsLoading] = useState(true);
  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );

  const [taskData, setTaskData] = useState(null);

  const formatDueDatex = (timestamp) => {
    const momentTimestamp = moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    if (momentTimestamp.isSame(moment(), "day")) {
      return `Today at ${momentTimestamp.format("h:mm A")}`;
    } else if (momentTimestamp.isSame(moment().subtract(1, "days"), "day")) {
      return `Yesterday at ${momentTimestamp.format("h:mm A")}`;
    } else {
      return momentTimestamp.format("MMM D, YYYY [at] h:mm A");
    }
  };

  function formatDueDate(timestamp) {
    if (!timestamp) return ""; // Check if timestamp is undefined or null
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month and pad with zero if needed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  console.log(Data, "Data");
  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        setIsLoading(true);
        // const getData = await dispatch(fetchTaskId(Data.taskId));
        // setTaskData(getData.payload);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching task data:", error);
        setIsLoading(false);
      }
    };

    fetchTaskData();
  }, [Data]);

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              fontWeight: 500,
              fontSize: "22px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Task Details
          </div>
        }
        placement="right"
        width={isMobile ? "100%" : 378}
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        className="drawer"
      >
        <MainTaskView>
          {<div className="spinner">{isLoading && <Spin size="small" />}</div>}
          <div className="task-card">
            <h2 className="title">{Data.title}</h2>
            <p className="description">{Data.description}</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {priorityOptions?.map((priority) => (
                <>
                  {Data.priority === priority.id ? (
                    <p className="flag">
                      <FlagImage index={priority.id} select="true" />{" "}
                      <span className="periority"> {priority.name} </span>
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ))}

              <p className="status">
                {Data.status === "Pending" ? (
                  <span className="pending">Pending </span>
                ) : (
                  <span className="complete">Completed</span>
                )}
              </p>
            </div>
            <Divider />

            {Data.subtasks?.map((item, index) => (
              <div className="subtask">
                <p className="subTaskTitle">
                  <span>Sub Task {index + 1} :</span> {item.title}
                </p>
                <p className="sub-description">{item.title}</p>
                <Divider />
              </div>
            ))}

            {/* {Data.priority === "" ? (
              <p>
                <FlagImage index={4} select="true" />{" "}
                <span className="periority"> No Priority</span>
              </p>
            ) : (
              ""
            )} */}

            {Data?.userInfo ? (
              <>
                <p className="description" style={{ display: "flex" }}>
                  <span>Assigned: </span>{" "}
                  <div className="assignTo">
                    <img src={Data?.userInfo?.imageUrl} alt="" />

                    <div className="userinfo">
                      <p className="userName">{Data?.userInfo?.name}</p>
                    </div>
                  </div>
                </p>
              </>
            ) : (
              " "
            )}

            <p className="subHeading">
              <span>Task date: </span>
              <img className="calendarIcons" src={CalendarIcon} alt="" />{" "}
              {formatDueDate(Data.dueDate)}
            </p>
            {Data.assignBy ? (
              <p className="subHeading">
                <span>Task created by :</span> {Data.assignBy}
              </p>
            ) : (
              ""
            )}
          </div>
        </MainTaskView>
      </Drawer>
    </>
  );
}

export default TeamsTaskView;
