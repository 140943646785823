import React from "react";
import { Drawer } from "antd";
import AddTeamMembers from "./addTeamMembers";
import { AddTeamMemberDrawerStyle } from "./styles";
function AddTeamMembersDrawer({ drawerVisible, onClose, teamsData }) {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  return (
    <AddTeamMemberDrawerStyle>
      <Drawer
        title={
          <div
            style={{
              fontWeight: 600,
              fontSize: "24px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Add a Member
          </div>
        }
        placement={isMobile ? "bottom":  "right"}
        width={ isMobile ? '100%' : 378 }
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        className="drawer"
      >
        <AddTeamMembers teamDatas={teamsData} onClose={onClose} />
      </Drawer>
    </AddTeamMemberDrawerStyle>
  );
}

export default AddTeamMembersDrawer;
