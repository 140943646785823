import styled from "styled-components";
import * as palette from "../../../styles/variables";

export const MainTaskView = styled.div`
  // background: #f6fafb;
  // padding: 5px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  width: 300px; /* Adjust the width as needed */
  margin: 10px;
  p { font-size: 14px;}
  .title {
    color: #1e232cde;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 2;
  }
  .task-priority {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: 7px;
    color: #778399;
    font-size: 12px;
    font-weight: 500;
  }
  .description {
    color: #666666;
    margin-top: 10px;
    span {
      color: #23235f;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .subtask {
    margin-top: 15px;
    .subTaskTitle {
      color: #666666;
      text-transform: capitalize;
      span {
        color: #23235f;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .sub-description {
      margin-top: 10px;
      color: #666666;
      span {
        color: #666666;
        font-weight: 500;
      }
    }
  }

  .flag {
    margin-top: 15px;
    .periority {
      color: #778399;
      margin-left: 5px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .calendarIcons {
    position: relative;
    top: 3px;
    width: 15px;
  }

  .assignTo {
    display: flex;
    margin-top: 10px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      position: relative;
      top: 3px;
      display: center;
    }

    .userinfo {
      margin-left: 10px;
      .userName {
        color: #1e232c;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        text-transform: capitalize;
      }

      .userEmail {
        color: #1e232c;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .status {
    margin-top: 15px;
    color: #fff;
    .pending {
      background: #f0bd70;
      color: #fff;
      padding: 5px;
      border-radius: 7px;
      font-size: 12px;
    }
    .complete {
      background: #087f03;
      color: #fff;
      padding: 5px;
      border-radius: 7px;
      font-size: 12px;
    }
  }

  .subHeading {
    color: #666666;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7;
    span {
      color: #23235f;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
  }
`;
