import styled from "styled-components";
import * as palette from "../../styles/variables";

export const SidebarStyle = styled.div`
.logo{
    img{

    }
}
.todo-sidebar{
    .ant-layout-sider-children{
        position: fixed;
        top: 0;
    }
}
.sidebarHead  {
    width: 80px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
    border-inline-end: none !important;
    // margin-left: -2px;
    .selected g {
        fill: #ffffff;
       }

       .selected path {
        stroke: #ffffff;
        // stroke-width: 10px;
       }
    .ant-menu-item { 
        margin-inline: 0px !important;
        padding: 8px 0 !important;
        width: 51%;
        svg{
            width: 100%;
            max-width: 24px;
            display: flex;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ant-menu-item-selected {
        // background-color: red; 
        // width: 100%;
        // padding: 12px 0;
        // width: 60% !important;
        // position: relative;
        //  padding-inline: calc(33% - 4px - 5px);
        // left: 12px;
        background: #3594FF !important;
        padding: 8px 0 !important;
        width: 51%;
        border-radius: 12px;
       
    }
}

.ant-layout-sider-trigger{
    display: none;
}

@media (max-width: 767px) {
     
      display: none;
  
  }
`