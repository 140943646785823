import styled from "styled-components";

export const InvitationsStyle = styled.div`
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }

  .card-content {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }

  .team-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  .team-name {
    margin-bottom: 15px;
    font-size: 18px;
    color: #23235f;
    text-transform: capitalize;
  }

  .invited-by span,
  .description span {
    margin-bottom: 10px;
    font-size: 14px;
    color: #23235f;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
`;

export const MessageContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 30px;
`;
