import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTeamsList,
  addTeamMember,
  deleteTeamMember,
  addTeam,
  addTask,
  getTeamsTask,
  updateTask,
  deleteTeamTask,
  deleteTeam,
  teamsDuplicateTask,
  statusUpdate,
  updateGlobalDate,
  fetchTask,
  updateTeam,
} from "../actions/teams.actions";

export const teamsSlice = createSlice({
  name: "teams",
  initialState: {
    teamsList: [],
    teamsTaskList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetTeamsState: (state) => {
      return {
        teamsList: [],
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTeam.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTeam.fulfilled, (state, action) => {
        state.loading = false;
        // state.teamsList.push(action.payload);
        state.teamsList.unshift(action.payload);
        state.error = "";
      })
      .addCase(addTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateTeam.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        const { teamId, updatedData } = action.payload;
        const teamIndex = state.teamsList.findIndex(
          (team) => team.id === teamId
        );
        if (teamIndex !== -1) {
          state.teamsList[teamIndex] = {
            ...state.teamsList[teamIndex],
            name: action.payload?.data.name,
            description: action.payload?.data.description,
          };
        }
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchTeamsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTeamsList.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "existing datas teams");
        state.teamsList = action.payload;
        state.error = "";
      })
      .addCase(fetchTeamsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(addTeamMember.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTeamMember.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, memberData } = action.payload;
        const teamIndex = state.teamsList.findIndex(
          (team) => team.id === teamId
        );
        if (teamIndex !== -1) {
          state.teamsList[teamIndex].members.push(memberData);
        }
        state.error = "";
      })
      .addCase(addTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteTeamMember.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteTeamMember.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, memberId } = action.payload;
        const teamIndex = state.teamsList.findIndex(
          (team) => team.id === teamId
        );
        if (teamIndex !== -1) {
          const memberIndex = state.teamsList[teamIndex].members.findIndex(
            (member) => member.id === memberId
          );
          if (memberIndex !== -1) {
            state.teamsList[teamIndex].members.splice(memberIndex, 1);
          }
        }
        state.error = "";
      })
      .addCase(deleteTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // ------ Task ----------
    builder
      .addCase(addTask.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, taskData } = action.payload;
        if (teamId !== null && taskData !== null) {
          const newTask = { teamId: teamId, ...taskData };
          state.teamsTaskList = Array.isArray(state.teamsTaskList)
            ? [...state.teamsTaskList, newTask]
            : [newTask];
        }
        state.error = "";
      })
      .addCase(addTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getTeamsTask.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTeamsTask.fulfilled, (state, action) => {
        state.loading = false;
        state.teamsTaskList = action.payload;
        state.error = "";
      })
      .addCase(getTeamsTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.loading = false;
        const { taskId, taskData } = action.payload;
        const updatedTeamsTaskList = state.teamsTaskList.map((task) => {
          if (task.id === taskId) {
            return { ...task, ...taskData };
          }
          return task;
        });
        state.teamsTaskList = updatedTeamsTaskList;
        state.error = null;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteTeamTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeamTask.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, taskId } = action.payload;
        const updatedTeamsTaskList = state.teamsTaskList.filter(
          (task) => !(task.id === taskId)
        );
        state.teamsTaskList = updatedTeamsTaskList;
        state.error = null;
      })
      .addCase(deleteTeamTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.loading = false;
        const updatedTeamsList = state.teamsList.filter(
          (task) => !(task.id === action.payload.id)
        );
        state.teamsList = updatedTeamsList;
        state.error = null;
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(teamsDuplicateTask.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(teamsDuplicateTask.fulfilled, (state, action) => {
        state.loading = false;
        const { teamId, taskData } = action.payload;
        if (teamId !== null && taskData !== null) {
          const newTask = { teamId: teamId, ...taskData };
          state.teamsTaskList = Array.isArray(state.teamsTaskList)
            ? [...state.teamsTaskList, newTask]
            : [newTask];
        }
        state.error = "";
      })
      .addCase(teamsDuplicateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(statusUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(statusUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        console.log(action.payload, "action payload status update")
        const updatedTaskIndex = state.teamsTaskList.findIndex(
          (task) => task.id === action.payload.id
        );
        if (updatedTaskIndex !== -1) {
          state.teamsTaskList[updatedTaskIndex].status = action.payload.status;
        }
      })
      .addCase(statusUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateGlobalDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGlobalDate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.globalDate = action.payload;
      })
      .addCase(updateGlobalDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTask.fulfilled, (state, action) => {
        state.loading = false;
        state.teamsTaskList = action.payload;
        state.error = "";
      })
      .addCase(fetchTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetTeamsState } = teamsSlice.actions;
export default teamsSlice.reducer;
