import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  updateDoc,
  limit,
  orderBy,
  getDoc,
} from "@firebase/firestore";
import { db } from "../../backend/firebase";

export const fetchTaskCountsAsync = createAsyncThunk(
  "dashboard/fetchTaskCountsAsync",
  async (userId, { rejectWithValue }) => {
    try {
      const getTasks = collection(db, "tasks");
      const tasksQuery = query(getTasks, where("user_id", "==", userId));
      const tasksSnapshot = await getDocs(tasksQuery);
      let completedCount = 0;
      let pendingCount = 0;
      const tatalTasks = tasksSnapshot.docs.map((doc) => {
        const task = {
          id: doc.id,
          ...doc.data(),
        };
        if (task.status === "Completed") {
          completedCount++;
        } else {
          pendingCount++;
        }
        return task;
      });
      const totalCount = tatalTasks.length;

      // Teams count
      // let teamsCount = 0;
      // const q = query(
      //   collection(db, "teams"),
      //   where("userGroup", "array-contains", userId)
      // );
      // const teamsQuerySnapshot = await getDocs(q);
      // teamsQuerySnapshot.forEach((x) => {
      //   teamsCount++;
      // });
      return { completedCount, pendingCount, totalCount };
    } catch (error) {
      console.error("Error fetching counts:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
