import { GoogleGenerativeAI } from "@google/generative-ai";

// Access your API key from environment variables
const API_KEY = "AIzaSyCPgk2NtCIZJbhMsuFTOTXXghBtNevGKoY";
const genAI = new GoogleGenerativeAI(API_KEY);

export const generateContent = async (prompt) => {
    try {
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const result = await model.generateContent(prompt);
        const response = await result.response;
        const text = response.text();
        return text;
    } catch (error) {
        console.error('Error generating content:', error);
        throw error;
    }
};
