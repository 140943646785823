import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, message, Space, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { updateTeam } from "../../../redux/actions/teams.actions";
import { EditTeamsContainer } from "./styles";

function EditTeams({ onClose, Data }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth?.user);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setIsLoading(true);
    const { name } = values;
    const { description } = values;
    const data = {
      name: name.trim() || "",
      description: description.trim() || "",
    };

    await dispatch(updateTeam({ teamId: values.id, data }));
    message.success({ content: "Team updated successfully", key: "loading" });
    // form.setFieldsValue({
    //   name: "",
    //   description: "",
    // });
    setIsFormDirty(false);
    setIsLoading(false);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const onValuesChange = () => {
    setIsFormDirty(true);
  };

  return (
    <>
      <EditTeamsContainer>
        <div className="profileUpdate-card-main">
          <Form
            form={form}
            layout="vertical"
            initialValues={Data}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            className="addteam-form"
          >
            <Form.Item
              name="name"
              label="Team Name"
              className="teamname-input"
              rules={[
                { required: true, message: "Please input the Team Name!" },
                { whitespace: true, message: "Enter Team Name" },
              ]}
            >
              <Input placeholder="Enter team name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="teamname-input team-description"
              rules={[{ whitespace: true, message: "Enter description" }]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>

            <Form.Item name="id" hidden>
              <Input type="hidden" />
            </Form.Item>

            <Form.Item className="btn-container">
              <Button onClick={handleCancel} style={{ marginRight: "10px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading} disabled={!isFormDirty}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </EditTeamsContainer>
    </>
  );
}

export default EditTeams;
