import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import {
  Button,
  Layout,
  Card,
  Col,
  Row,
  Modal,
  Calendar,
  Spin,
  FloatButton,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../Sidebar/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamsTask,
  deleteTeamTask,
  fetchTask,
  updateGlobalDate,
} from "../../../redux/actions/teams.actions";
import TeamsTaskCards from "./taskCards";
import TeamsCreateTaskDrawer from "./createTask";
import { EmptyState, TeamTaskListMainStyle } from "./styles";
import EmptyStateImg from "../../../assets/taskEmpty.png";
import createBtn from "../../../assets/home/create-task.svg";
import SearchFor from "../../Search";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
const { Content } = Layout;

function TeamTaskList() {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [taskDate, setTaskDate] = useState("Today");
  const [isEditData, setIsEditData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [globalDate, setGlobalDate] = useState();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { teamId } = useParams();
  const TeamsList = useSelector((state) => state.teams);
  const todoTask = useSelector((state) => state.teams.teamsTaskList);
  const currentUser = useSelector((state) => state.auth.user);
  const foundTeam = TeamsList?.teamsList?.find(
    (TeamsList) => TeamsList.id === teamId
  );
  const teamName = foundTeam.name;
  const [membersList, setMembersList] = useState([]);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
  const [createDrawerVisible, setCreateDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    if (teamId) {
      const currentDate = moment().format("YYYY/MM/DD");
      // setGlobalDate(currentDate);
      getTasks(currentDate);
      getGlobalDate(currentDate);
    }
  }, []);
  useEffect(() => {
    if (teamId) {
      const a = TeamsList?.teamsList?.filter((x) => x.id === teamId);
      setMembersList(a[0]?.members);
      const currentDate = moment().format("YYYY/MM/DD");
      // setGlobalDate(currentDate);
      getTasks(currentDate);
      getGlobalDate(currentDate);
    }
  }, [teamId]);

  const editTask = (taskId, teamId, data) => {
    setIsEditData(data);
    setDrawerVisible(true);
  };

  const handleCalendarViewCancel = () => {
    setCalendarView(false);
  };

  const getGlobalDate = async (date) => {
    await dispatch(updateGlobalDate(date));
  };

  const getTasks = async (date) => {
    setIsLoading(true);
    await dispatch(fetchTask({ teamId: teamId, date: date }));
    setIsLoading(false);
  };

  const onPanelChange = (value) => {
    setSelectedDate(value);
    const selectedDate = new Date(value);
    const currentDate = new Date();

    const day = selectedDate.getDate();
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      selectedDate
    );
    const year = selectedDate.getFullYear();

    let formattedDate;

    if (
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      formattedDate = "Today";
    } else {
      formattedDate = `${day} ${month} ${year}`;
    }
  };

  const handleConfirmButtonClick = () => {
    if (selectedDate) {
      const selectedDates = new Date(selectedDate);
      const currentDate = new Date();

      const day = selectedDates.getDate();
      const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
        selectedDates
      );
      const year = selectedDates.getFullYear();

      let formattedDate;

      if (
        selectedDates.getDate() === currentDate.getDate() &&
        selectedDates.getMonth() === currentDate.getMonth() &&
        selectedDates.getFullYear() === currentDate.getFullYear()
      ) {
        formattedDate = "Today";
      } else {
        formattedDate = `${day} ${month} ${year}`;
      }

      setTaskDate(formattedDate);
      setGlobalDate(selectedDate.format("YYYY/MM/DD"));
      getGlobalDate(selectedDate.format("YYYY/MM/DD"));
      getTasks(selectedDate.format("YYYY/MM/DD"));
      setCalendarView(false);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filterTasks = (todoslist) => {
    const filteredAndSortedTasks = todoslist.filter((task) => {
      const titleMatch = task?.title
        ? task.title.toLowerCase().includes(searchValue.toLowerCase())
        : false;
      return titleMatch;
    });

    const sortedTasks = filteredAndSortedTasks
      ? filteredAndSortedTasks.slice().sort((a, b) => {
          let dateA, dateB;
          if (a.createdOn instanceof Date) {
            dateA = a.createdOn;
          } else if (a.createdOn.seconds && a.createdOn.nanoseconds) {
            dateA = new Date(
              a.createdOn.seconds * 1000 + a.createdOn.nanoseconds / 1000000
            );
          }

          if (b.createdOn instanceof Date) {
            dateB = b.createdOn;
          } else if (b.createdOn.seconds && b.createdOn.nanoseconds) {
            dateB = new Date(
              b.createdOn.seconds * 1000 + b.createdOn.nanoseconds / 1000000
            );
          }

          if (dateA && dateB) {
            return dateB - dateA;
          }

          return 0;
        })
      : [];
    const pendingTasks = sortedTasks.filter(
      (task) => task.status === "Pending"
    );
    const completedTasks = sortedTasks.filter(
      (task) => task.status === "Completed"
    );

    return { pendingTasks, completedTasks };
  };

  const handleTaskModalOpen = () => {
    setIsCreateTaskOpen(true);
    setCreateDrawerVisible(true);
  };

  const onCreateCloseDrawer = () => {
    setCreateDrawerVisible(false);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content>
          <Navbar />
          <>
            <TeamTaskListMainStyle>
              <h3 className="TeamHeadLine">{teamName}</h3>
              <div className="teamTaskList-top">
                <div className="teams-searchfor">
                  <SearchFor
                    placeholder="Search by task name"
                    onChange={handleSearch}
                  />
                </div>
                <Button
                  type="primary"
                  onClick={showDrawer}
                  className="addteam-btn"
                >
                  + Add Task
                </Button>
              </div>
              <Button
                type="primary"
                className="date-btn"
                onClick={() => setCalendarView(true)}
              >
                {taskDate} <DownOutlined />
              </Button>
              <Modal
                open={calendarView}
                onOk={handleCalendarViewCancel}
                onCancel={handleCalendarViewCancel}
                className="todo-ant-modal"
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                // okText="Confirm"
              >
                <Calendar fullscreen={false} onChange={onPanelChange} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button onClick={handleCalendarViewCancel}>Cancel</Button>
                  <Button onClick={handleConfirmButtonClick} type="primary">
                    Confirm
                  </Button>
                </div>
              </Modal>
              <div className="card-size">
                {isLoading ? (
                  <Spin
                    tip="Loading"
                    size="small"
                    style={{ marginTop: "180px" }}
                  >
                    <div className="content" />
                  </Spin>
                ) : (
                  <>
                    <span className="TaskHeadLine">Tasks</span>
                    {todoTask?.length > 0 &&
                    filterTasks(todoTask)?.pendingTasks.length > 0
                      ? filterTasks(todoTask)?.pendingTasks.map(
                          (todo, index) => (
                            <TeamsTaskCards
                              task={todo}
                              index={index}
                              taskStatus="Completed"
                              taskDate={taskDate}
                              teamIds={teamId}
                            />
                          )
                        )
                      : null}

                    {todoTask?.length > 0 &&
                    filterTasks(todoTask)?.pendingTasks.length === 0 &&
                    todoTask?.length > 0 &&
                    filterTasks(todoTask)?.completedTasks.length > 0 ? (
                      <span
                        style={{
                          color: "#778399",
                          fontSize: "16px",
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        {" "}
                        Sorry No Pending Tasks{" "}
                      </span>
                    ) : null}

                    {todoTask?.length > 0 &&
                    filterTasks(todoTask)?.completedTasks.length > 0 ? (
                      <>
                        <span className="statusCompleted">Completed</span>
                      </>
                    ) : null}

                    {todoTask?.length > 0 &&
                    filterTasks(todoTask)?.completedTasks.length > 0
                      ? filterTasks(todoTask)?.completedTasks.map(
                          (todo, index) => (
                            <TeamsTaskCards
                              key={index}
                              task={todo}
                              index={index}
                              taskStatus="Pending"
                              taskDate={taskDate}
                              teamIds={teamId}
                            />
                          )
                        )
                      : null}

                    {todoTask?.length === 0 ||
                    (filterTasks(todoTask)?.pendingTasks.length === 0 &&
                      filterTasks(todoTask)?.completedTasks.length === 0) ? (
                      <EmptyState>
                        <div className="wrapper">
                          <img alt="empty state" src={EmptyStateImg} />
                          <h3>Sorry! No Task Found...</h3>
                          <span className="newTask" onClick={showDrawer}>
                            Please Add New Task
                          </span>
                        </div>
                      </EmptyState>
                    ) : null}
                  </>
                )}
              </div>
              {isMobile ? (
                <FloatButton
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => handleTaskModalOpen(true)}
                  className="floatAddTeamButton"
                />
              ) : (
                ""
              )}
            </TeamTaskListMainStyle>
          </>
        </Content>
      </Layout>

      <TeamsCreateTaskDrawer
        drawerVisible={createDrawerVisible}
        onClose={onCreateCloseDrawer}
        membersList={membersList}
        teamId={teamId}
        editValues=""
      />

      <TeamsCreateTaskDrawer
        drawerVisible={drawerVisible}
        onClose={onCloseDrawer}
        membersList={membersList}
        teamId={teamId}
      />
    </Layout>
  );
}

export default TeamTaskList;
