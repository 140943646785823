import React from "react";
import { MemberCardStyle, TeamMemberStyle } from "./styles";
import { Drawer, Card, Spin, message, Button, Modal } from "antd";
import { deleteTeamMember } from "../../redux/actions/teams.actions";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../assets/teams/deleteMenu.svg";

const TeamMembersList = ({ drawerVisible, onClose, teamMembersData }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const handleTeamMemberDelete = async (teamId, memberId, rowId) => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure you want to delete this member?",
      onOk: async () => {
        try {
          // message.loading({ content: "Loading", key: "loading" });
          await dispatch(
            deleteTeamMember({ teamId: teamId, memberId: memberId })
          );
          message.success({
            content: "Team member delete successfuly",
            key: "loading",
          });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  return (
    <TeamMemberStyle>
      <Drawer
        title={
          <div
            style={{
              fontWeight: 600,
              fontSize: "24px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Team Members
          </div>
        }
        width={isMobile ? "100%" : 378}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        className="drawer"
        style={{ width: "100% !important" }}
      >
        <MemberCardStyle>
          <h3 style={{ textTransform: "capitalize" }}>
            {teamMembersData.name}
          </h3>
          <p className="members-count">
            Members: {teamMembersData.members?.length}
          </p>
          {teamMembersData?.members?.map((list, index) => (
            <>
              <div className={`members-details`} id={"row_" + index}>
                {list?.userInfo?.imageUrl ? (
                  <img
                    key={index}
                    src={list?.userInfo?.imageUrl}
                    alt=""
                    className="profile-picture"
                  />
                ) : (
                  <div className="member-profile">
                    {list?.email.charAt(0).toUpperCase()}
                  </div>
                )}
                <div className="about-member">
                  {/* <h4>{list?.name}</h4> */}
                  <p>{list?.email}</p>
                  <div className="btns-container">
                    <button
                      className={list?.status ? "accept-btn" : "invite-btn"}
                    >
                      {list?.status ? "Accepted" : "Invited"}{" "}
                    </button>

                    {teamMembersData?.user_id == currentUser?.user_id ? (
                      <img
                        src={deleteIcon}
                        alt=""
                        style={{ width: "20px", height: "22px" }}
                        onClick={() =>
                          handleTeamMemberDelete(
                            teamMembersData?.id,
                            list?.id,
                            index
                          )
                        }
                      />
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </MemberCardStyle>
      </Drawer>
    </TeamMemberStyle>
  );
};

export default TeamMembersList;
