import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../backend/firebase";
import moment from "moment";

export const fetchTask = createAsyncThunk(
  "fetchTask",
  async ({ user_id, date }, { rejectWithValue }) => {
    try {
      const [year, month, day] = date.split("/").map(Number);
      const startDate = new Date(year, month - 1, day, 0, 0, 0, 0);
      const endDate = new Date(year, month - 1, day, 23, 59, 59, 999);
      const tasksCollection = collection(db, "tasks");
      const q = query(
        tasksCollection,
        where("user_id", "==", user_id),
        where("dueDate", ">=", startDate),
        where("dueDate", "<=", endDate)
      );

      const querySnapshot = await getDocs(q);
      const tasks = [];
      for (const taskDoc of querySnapshot.docs) {
        const taskData = {
          id: taskDoc.id,
          ...taskDoc.data(),
          subtasks: [],
        };

        const subtasksRef = collection(taskDoc.ref, "subtasks");
        const subtasksSnapshot = await getDocs(subtasksRef);
        subtasksSnapshot.forEach((subtaskDoc) => {
          taskData.subtasks.push({
            subtaskId: subtaskDoc.id,
            ...subtaskDoc.data(),
          });
        });

        tasks.push(taskData);
      }
      return tasks;
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue("Failed to fetch tasks. Please try again.");
    }
  }
);

export const addDuplicateTask = createAsyncThunk(
  "AddDuplicateTask",
  async (
    { formData, user_id, subtaskTitle, FilterDate },
    { rejectWithValue }
  ) => {
    try {
      const dbRef = collection(db, "tasks");
      const timestamp = serverTimestamp();
      let originalTitle = formData.title.trim();
      const copyIndex = originalTitle.lastIndexOf(" copy");
      if (copyIndex !== -1) {
        originalTitle = originalTitle.substring(0, copyIndex).trim();
      }
      const existingTasksQuery = query(
        dbRef,
        where("title", "==", originalTitle),
        where("user_id", "==", user_id)
      );
      const existingTasksSnapshot = await getDocs(existingTasksQuery);

      if (!existingTasksSnapshot.empty) {
        let counter = 1;
        while (true) {
          let newTitle = `${originalTitle} copy${counter}`;
          const modifiedTitleQuery = query(
            dbRef,
            where("title", "==", newTitle)
          );
          const modifiedTitleSnapshot = await getDocs(modifiedTitleQuery);
          if (modifiedTitleSnapshot.empty) {
            originalTitle = newTitle;
            break;
          }
          counter++;
        }
      }
      const newDocRef = await addDoc(dbRef, {
        title: originalTitle,
        description: formData.description,
        dueDate: formData.dueDate,
        time: formData.time,
        pin: formData.pin,
        priorityId: formData.priorityId,
        user_id: user_id,
        status: formData.status,
        createdOn: timestamp,
      });

      let subtasksData = [];
      if (formData.subtasks.length > 0) {
        const subtasksRef = collection(newDocRef, "subtasks");
        try {
          for (const item of formData.subtasks) {
            const newSubtaskRef = await addDoc(subtasksRef, {
              title: item.title,
              description: item.description,
            });
            const newSubtaskDoc = await getDoc(newSubtaskRef);
            subtasksData.push({
              subtaskId: newSubtaskRef.id,
              ...newSubtaskDoc.data(),
            });
          }
        } catch (error) {
          console.error("Error adding subtask:", error);
        }
      }

      try {
        const addedDocSnapshot = await getDoc(newDocRef);
        const seconds = formData.dueDate.seconds;
        const milliseconds = seconds * 1000;
        const time1 = moment(milliseconds).format("YYYY/MM/DD");
        const time2 = moment(FilterDate).format("YYYY/MM/DD");
        if (time1 === time2) {
          return {
            taskId: newDocRef.id,
            taskData: {
              ...addedDocSnapshot.data(),
              ...subtasksData,
            },
          };
        }
      } catch (error) {
        console.error("Error fetching task document:", error);
        // Handle error appropriately
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addTask = createAsyncThunk(
  "AddTask",
  async (
    {
      formData,
      selectedDates,
      selectedTime,
      user_id,
      subtaskTitle,
      FilterDate,
    },
    { rejectWithValue }
  ) => {
    try {
      console.log(FilterDate, "FilterDate");
      const formattedDate = selectedDates
        ? moment(selectedDates).format("YYYY-MM-DD")
        : null;
      let currentDate = new Date();
      if (formattedDate !== "" && formattedDate !== null) {
        currentDate = formattedDate;
      } else {
        currentDate = currentDate.toISOString().split("T")[0];
      }
      const combinedDateAndTime = `${currentDate} ${selectedTime}`;
      const DateAndTime = new Date(combinedDateAndTime);

      const dbRef = collection(db, "tasks");
      const timestamp = serverTimestamp();
      const newDocRef = await addDoc(dbRef, {
        title: formData.title,
        description: formData.description,
        dueDate: new Date(DateAndTime),
        time: selectedTime,
        pin: formData.pin,
        priorityId: formData.priorityId,
        user_id: user_id,
        status: "Pending",
        createdOn: timestamp,
      });

      let subtasksData = [];
      if (formData.subtasks.length > 0) {
        const subtasksRef = collection(newDocRef, "subtasks");
        try {
          for (const item of formData.subtasks) {
            const newSubtaskRef = await addDoc(subtasksRef, {
              title: item.title,
              description: item.description,
            });
            const newSubtaskDoc = await getDoc(newSubtaskRef);
            subtasksData.push({
              subtaskId: newSubtaskRef.id,
              ...newSubtaskDoc.data(),
            });
          }
        } catch (error) {
          console.error("Error adding subtask:", error);
        }
      }

      try {
        const addedDocSnapshot = await getDoc(newDocRef);
        const time1 = moment(currentDate).format("YYYY/MM/DD");
        const time2 = moment(FilterDate).format("YYYY/MM/DD");
        if (time1 === time2) {
          return {
            taskId: newDocRef.id,
            taskData: {
              ...addedDocSnapshot.data(),
              subtasks: subtasksData,
            },
          };
        } else {
          return {
            taskId: null,
            taskData: null,
          };
        }
      } catch (error) {
        console.error("Error fetching task document:", error);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateTask = createAsyncThunk(
  "UpdateTask",
  async (
    {
      formData,
      selectedDates,
      selectedTime,
      user_id,
      taskId,
      dateCheck,
      timeCheck,
      subtaskTitle,
      subtaskId,
    },
    { rejectWithValue }
  ) => {
    try {
      let currentDate = new Date();
      let DateAndTime;

      if (dateCheck !== "" && timeCheck !== "") {
        DateAndTime = dateCheck;
      } else {
        const formattedDate = selectedDates
          ? moment(selectedDates).format("YYYY-MM-DD")
          : null;

        if (formattedDate !== "" && formattedDate !== null) {
          currentDate = formattedDate;
        } else {
          currentDate = currentDate.toISOString().split("T")[0];
        }
        const combinedDateAndTime = `${currentDate} ${selectedTime}`;
        DateAndTime = new Date(combinedDateAndTime);
      }
      const dbRef = collection(db, `tasks`);
      const updatedDocRef = doc(dbRef, taskId);
      await updateDoc(updatedDocRef, {
        id: taskId,
        title: formData.title,
        description: formData.description,
        dueDate: DateAndTime,
        time: selectedTime,
        pin: formData.pin,
        priorityId: formData.priorityId,
        user_id: formData.user_id,
      });

      //  New subtasks
      const subtasksCollectionRef = collection(db, "tasks", taskId, "subtasks");
      const subtasksQuerySnapshot = await getDocs(subtasksCollectionRef);
      const deleteBatch = writeBatch(db);
      subtasksQuerySnapshot.forEach((subtaskDoc) => {
        deleteBatch.delete(subtaskDoc.ref);
      });
      await deleteBatch.commit();

      let subtasksData = [];
      if (formData.subtasks && formData.subtasks.length > 0) {
        const subtasks = formData.subtasks;
        for (let i = 0; i < subtasks.length; i++) {
          try {
            const t = subtasks[i];
            const title = typeof t === "string" ? t : t.title;

            const newSubtaskRef = await addDoc(subtasksCollectionRef, {
              title: subtasks[i].title,
              description: subtasks[i].description,
            });
            const newSubtaskDoc = await getDoc(newSubtaskRef);
            subtasksData.push({
              subtaskId: newSubtaskRef.id,
              ...newSubtaskDoc.data(),
            });
          } catch (error) {
            console.error("Error adding subtask:", error);
          }
        }
      }

      const addedDocSnapshot = await getDoc(updatedDocRef);
      return {
        taskId: addedDocSnapshot.id,
        taskData: {
          ...addedDocSnapshot.data(),
          subtasks: subtasksData,
        },
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "DeleteTask",
  async ({ taskId }, { rejectWithValue }) => {
    try {
      let dbRef = collection(db, `tasks`);
      const deleteRef = doc(dbRef, taskId);
      const newDocRef = await deleteDoc(deleteRef);
      const addedDocSnapshot = await getDoc(deleteRef);
      return {
        id: addedDocSnapshot.id,
        ...addedDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const statusUpdate = createAsyncThunk(
  "StatusUpdate",
  async ({ taskId, taskStatus }, { rejectWithValue }) => {
    try {
      let dbRef = collection(db, `tasks`);
      const statusUpdates = doc(dbRef, taskId);
      const statusCheck = await updateDoc(statusUpdates, {
        status: taskStatus,
      });
      const getDocSnapshot = await getDoc(statusUpdates);
      return {
        id: getDocSnapshot.id,
        ...getDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchTaskId = createAsyncThunk(
  "task/fetchTaskId",
  async (taskId, { rejectWithValue }) => {
    try {
      let dbRef = collection(db, `tasks`);
      const getRef = doc(dbRef, taskId);
      const addedDocSnapshot = await getDoc(getRef);
      return {
        id: addedDocSnapshot.id,
        ...addedDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const duplicateTask = createAsyncThunk(
  "",
  async (data, { rejectWithValue }) => {
    try {
      const timestamp = serverTimestamp();
      const taskDocRef = doc(db, "tasks", data.id);
      const dbRef = collection(db, "tasks");
      const taskSnapshot = await getDoc(taskDocRef);
      const taskData = taskSnapshot.data();
      const newTitle = await handleTaskTitleDuplication(
        taskData.title,
        data.user_id
      );
      // const newTaskRef = await addDoc(collection(db, 'tasks'), { ...taskData, title: newTitle });
      const newTaskRef = await addDoc(dbRef, {
        title: newTitle,
        description: data.description,
        dueDate: data.dueDate,
        time: data.time,
        pin: data.pin,
        priorityId: data.priorityId,
        user_id: data.user_id,
        status: data.status,
        createdOn: timestamp,
      });
      let subtaskData = {};
      if (data.subtasks) {
        const subtasksSnapshot = await getDocs(
          collection(taskDocRef, "subtasks")
        );
        subtasksSnapshot.forEach(async (subtaskDoc) => {
          const subtaskData = subtaskDoc.data();
          await addDoc(collection(newTaskRef, "subtasks"), subtaskData);
        });
      }
      const addedDocSnapshot = await getDoc(newTaskRef);
      return {
        taskId: newTaskRef.id,
        taskData: {
          ...addedDocSnapshot.data(),
          ...subtaskData,
        },
      };
    } catch (error) {
      console.error("Error duplicating task:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

async function handleTaskTitleDuplication(originalTitle, user_id) {
  try {
    let updatedTitle = originalTitle.trim();
    const existingTasksQuery = query(
      collection(db, "tasks"),
      where("title", "==", updatedTitle),
      where("user_id", "==", user_id)
    );
    const existingTasksSnapshot = await getDocs(existingTasksQuery);
    if (!existingTasksSnapshot.empty) {
      updatedTitle += " copy";
    }

    // if (!existingTasksSnapshot.empty) {
    //   let count = 1;
    //   let newTitle = updatedTitle;
    //   while (true) {
    //     const newTitleExists = existingTasksSnapshot.docs.some(doc => doc.data().title === newTitle);
    //     if (!newTitleExists) {
    //       updatedTitle = newTitle;
    //       break;
    //     }
    //     count++;
    //     newTitle = `${updatedTitle} copy ${count}`;
    //   }
    // }
    return updatedTitle;
  } catch (error) {
    console.error("Error handling task title duplication:", error);
    throw error;
  }
}

export const updateGlobalDate = createAsyncThunk(
  "task/globalDate",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      console.error("Error global date:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addAiTask = createAsyncThunk(
  "AddTask",
  async ({ formData, user_id }, { rejectWithValue }) => {
    try {
      let currentDate = new Date();
      const dbRef = collection(db, "tasks");
      const timestamp = serverTimestamp();
      const newDocRef = await addDoc(dbRef, {
        title: formData.title,
        description: formData.description,
        dueDate: currentDate,
        time: "",
        pin: formData.pin,
        priorityId: formData.priorityId,
        user_id: user_id,
        status: "Pending",
        createdOn: timestamp,
      });

      let subtasksData = [];
      if (formData?.subtasks?.length > 0) {
        const subtasksRef = collection(newDocRef, "subtasks");
        try {
          for (const item of formData.subtasks) {
            const newSubtaskRef = await addDoc(subtasksRef, {
              title: item.title,
              description: item.description,
            });
            const newSubtaskDoc = await getDoc(newSubtaskRef);
            subtasksData.push({
              subtaskId: newSubtaskRef.id,
              ...newSubtaskDoc.data(),
            });
          }
        } catch (error) {
          console.error("Error adding subtask:", error);
        }
      }

      try {
        const addedDocSnapshot = await getDoc(newDocRef);

        return {
          taskId: newDocRef.id,
          taskData: {
            ...addedDocSnapshot.data(),
            subtasks: subtasksData,
          },
        };
      } catch (error) {
        console.error("Error fetching task document:", error);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Update the PIN task

export const updatePin = createAsyncThunk(
  "UpdatePin",
  async (todo, { rejectWithValue }) => {
    try {
      const dbRef = collection(db, `tasks`);
      const updatedDocRef = doc(dbRef, todo.id);
      let pin;
      if (todo.pin == true) {
        pin = false;
      } else {
        pin = true;
      }
      await updateDoc(updatedDocRef, {
        id: todo.id,
        pin: pin,
      });
      const addedDocSnapshot = await getDoc(updatedDocRef);
      return {
        taskId: addedDocSnapshot.id,
        ...addedDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
