import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotificationsAsync,
  updateNotificationStatusAsync,
  markAllNotificationsAsReadAsync,
} from "../actions/notification.actions";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    notificationCounts:{
      unread: 0
    },
    loading: false,
    error: null,
  },
  reducers: {
    resetNotificationsState: (state) =>{
      return {
        notificationList:[],
        notificationCounts:{
          unread: 0
        },
        loading: false,
        error: null,
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsync.pending, (state, action) => {
        state.loading = true;
        state.notificationList = action.payload;
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationList = action.payload;
        const unreadCount = action.payload.filter(
          (notification) => notification.status === "unread"
        ).length;
        // Update notificationCounts in the state
        state.notificationCounts = {
          ...state.notificationCounts,
          unread: unreadCount,
        };
        state.error = "";
      })
      .addCase(fetchNotificationsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateNotificationStatusAsync.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateNotificationStatusAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.notificationList = state.notificationList.filter(notification =>  notification.id !== action.payload.notificationId);
        const updatedList = state.notificationList.map(notification => {
            if (notification.id === action.payload.notificationId) {
                return { ...notification, status: 'read' };
            }
            return notification;
        });
        state.notificationList = updatedList;
        const unreadCount = updatedList.filter(
            (notification) => notification.status === 'unread'
        ).length;
        // Update notificationCounts in the state
        state.notificationCounts = {
            ...state.notificationCounts,
            unread: unreadCount,
        };
        state.error = "";
      })
      .addCase(updateNotificationStatusAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(markAllNotificationsAsReadAsync.pending, (state, action) => {
        state.loading = true;
        state.notificationList = action.payload;
      })
      .addCase(markAllNotificationsAsReadAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationList = [];
        state.error = "";
      })
      .addCase(markAllNotificationsAsReadAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetNotificationsState } = notificationSlice.actions;
export default notificationSlice.reducer;
