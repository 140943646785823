import styled from "styled-components";
import * as palette from "../../styles/variables";

export const HomeStyle = styled.div`
  .todo-home {
    position: relative;
    // height: calc(100vh - 25px);
    .todo-task-btn {
      position: fixed;
      bottom: 20px;
      right: 23px;
      width: 60px;
      cursor: pointer;
    }
  }
  .todo-ant-modal {
    .ant-input {
      margin-top: 10px;
    }
  }
  .todo-tasks {
    .todo-no-tasks-content {
      position: absolute;
      top: 35%;
      right: 22%;
    }
    .todo-task-list {
      // height: 100vh;
      // background: ${palette.WHITE_COLOR};
      width: 80%;
      // margin-left: auto;
      // margin-right: auto;
      // border-top: 31px solid #ebf5ff;
      // overflow-y: scroll;
      padding: 30px 80px 17px;
      h3 {
        margin: 10px 0px 8px 0px;
      }
      .home-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 20px;
      }
    }
    .todo-body {
      box-shadow: 3px 3px 16px 0px rgba(0, 0, 0, 0.08);
      background: rgba(255, 255, 255, 1);
      padding: 17px 20px 18px 15px;
      margin-top: 20px;
      // cursor: pointer;
      .todo {
        display: flex;
      }
      .tasklist-container {
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 2;
          letter-spacing: 0em;
          text-align: left;
          color: #000;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        p {
          line-height: 2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        // Timer

        .timers {
          img {
            width: 18px;
            height: 18px;
          }
          span {
            position: relative;
            top: -3px;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
          }
        }

        // Title

        .tasklist-title {
          font-size: 20px;
          font-weight: 400;
          color: #1e232cde;
          gap: 5px .tasklist-time {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
          }

          .edited-text {
            font-size: 12px;
            color: gray;
          }

          h5 {
            color: #23235f;
          }
        }

        // Prioritys

        .prioritys {
          p {
            span {
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: left;
              color: #778399;
              position: relative;
              left: 10px;
            }
          }
        }

        // Pin

        .pins {
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #778399;
            span {
              // position: relative;
              // left: 15px;
              margin-left: 10px;
            }
          }
        }

        // Edit
        .taskEdit {
          .text {
            top: -5px;
            position: relative;
          }
        }
      }
      .tasklist-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .todo-subtask {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: 24px;
        margin-top: 12px;
        p {
          font-size: 20px;
          font-weight: 400;
          color: #1e232cde;
        }
      }
    }
    .todo-body:hover {
      background: #ebf5ff;
      color: ${palette.PRIMARY_COLOR};
    }
    .task-icon-container {
      display: flex;
      gap: 10px;
    }
  }

  .statusCompleted,
  .TaskHeadLine {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #232360;
    line-height: 2;
    display: block;
  }
  .statusCompleted-btn {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .todo-tasks .todo-task-list {
      width: 100%;
      padding: 20px 30px 0px;

      .wrapper {
        margin-top: 100px;
      }
    }
    .todo-home .home-nav {
      padding: 0 10px 0 10px;
    }

    .statusCompleted {
      // display: none;
    }
    .statusCompleted-btn {
      display: block;
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 150px);
  margin: auto;
  text-align: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 153px;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: ${palette.MAIN_COLOR};
    }
    .newTask {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #0077d7;
      cursor: pointer;
    }
  }
`;
