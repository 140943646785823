import React, { useState, useEffect } from "react";
import { Radio, message, Button, Dropdown, Modal } from "antd";
import TaskModal from "../../Task/TaskModal";
import flag from "../../../assets/home/flag.svg";
import FlagImage from "../../FlagImages";
import moment from "moment";
import CreateTask from "../../Task/CreateTask";
import { useDispatch, useSelector } from "react-redux";
import {
  statusUpdate,
  deleteTask,
  duplicateTask,
  updatePin,
} from "../../../redux/actions/task.actions";
import {
  DownOutlined,
  UpOutlined,
  PushpinOutlined,
  PushpinFilled,
  EditOutlined,
} from "@ant-design/icons";
import editIcon from "../../../assets/task/edit.svg";
import clockIcon from "../../../assets/task/clock.svg";
import { TaskCardStyle } from "./styles";
import subtaskIcon from "../../../assets/home/create-task/tabler_subtask.svg";
import duplicateIcon from "../../../assets/home/create-task/duplicate_icon.svg";
import deleteIcon from "../../../assets/home/create-task/delete.svg";
import menuIcon from "../../../assets/task/menu.svg";
import taskView from "../../../assets/teams/carbon_task-view.svg";
import ViewTask from "../ViewTask/ViewTask";

function TaskCard({ todo, index, taskStatus, taskDate }) {
  const dispatch = useDispatch();
  const [isTaskModalClick, setIsTaskModalClick] = useState(false);
  const [isTaskModalSet, setIsTaskModalSet] = useState("");
  const [downOutlineClass, setDownOutlineClass] = useState("");
  const [upOutlineClass, setUpOutlineClass] = useState("icon-display");
  const [isDownOutlinedClick, setIsDownOutlinedClick] = useState(false);
  const [viewTaskData, setViewTaskData] = useState("");
  const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);
  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );

  var formattedDate = "";
  var dateValue = "";
  var formattedTime = "";

  if (todo.dueDate !== null && todo?.dueDate !== "") {
    const { seconds, nanoseconds } = todo?.dueDate;
    formattedDate = new Date(seconds * 1000 + nanoseconds / 1000000);
    formattedDate = moment(formattedDate);
    if (moment().isSame(formattedDate, "day")) {
      dateValue = "Today";
    } else if (moment().subtract(1, "days").isSame(formattedDate, "day")) {
      dateValue = "Yesterday";
    } else {
      dateValue = formattedDate.format("DD MMM YYYY");
    }
    // Format time as "-h:mm a"
    var timeValue = formattedDate.format("h:mm a");
    formattedDate = `${dateValue} `;
    formattedTime = timeValue;
  }

  var priorityName = "";

  if (todo?.priorityId) {
    const checkPrioritys = priorityOptions?.find(
      (x) => x.id === todo.priorityId
    );
    if (checkPrioritys) {
      priorityName = checkPrioritys.name;
    }
  } else {
    priorityName = "No Priority";
  }

  const handleTaskModalSet = (todo) => {
    setIsTaskModalSet(todo);
    setIsTaskModalClick(true);
  };

  const handleTaskView = async () => {
    setViewTaskData(todo);
    setTaskDrawerVisible(true);
  };

  const handlePin = async() => {
    message.loading({ content: "Loading", key: "loading" });
    await dispatch(updatePin(todo))
    message.success({ content: "Task updated successfuly", key: "loading" });
  }

  const onCloseTaskDrawer = () => {
    setTaskDrawerVisible(false);
  };

  const handleDuplicate = async (data) => {
    message.loading({ content: "Loading", key: "loading" });
    await dispatch(duplicateTask(data));
    message.success({ content: "Task added successfuly", key: "loading" });
  };

  const handleTaskModalOpen = (val) => {
    setIsTaskModalClick(val);
  };

  const handleSubtask = (val) => {
    setIsDownOutlinedClick(val);
    if (val === true) {
      setDownOutlineClass("icon-display");
      setUpOutlineClass("");
    } else if (val === false) {
      setDownOutlineClass("");
      setUpOutlineClass("icon-display");
    }
  };

  const handleRadioClick = async () => {
    try {
      message.loading({ content: "Loading", key: "loading" });
      await dispatch(statusUpdate({ taskId: todo.id, taskStatus: taskStatus }));
      message.success({ content: "Task update successfuly", key: "loading" });
    } catch (error) {
      message.error("Failed to update task");
    }
  };

  const handleDelete = (data) => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure you want to delete this task?",
      onOk: async () => {
        try {
          // message.loading({ content: "Loading", key: "loading" });
          await dispatch(deleteTask({ taskId: data.id }));
          message.success({
            content: "Task delete successfuly",
            key: "loading",
          });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  // const handleDelete = async (data) => {
  //   message.loading({ content: "Loading", key: "loading" });
  //   await dispatch(deleteTask({ taskId: data.id }));
  //   message.success({ content: "Task delete successfuly", key: "loading" });
  // };

  const items = [
    // {
    //   key: '1',
    //   label: (
    //     <span><img src={subtaskIcon} className="dropdownbtn" style={{ width: '15px', position: 'relative', top: '2px'}} alt="" /> Add Subtask</span>
    //   ),

    // },
    {
      key: "2",
      label: (
        <span onClick={() => handleTaskModalSet(todo)}>
          <img
            src={editIcon}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Edit
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span onClick={() => handleDuplicate(todo)}>
          <img
            src={duplicateIcon}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Duplicate
        </span>
      ),
    },

    {
      key: "4",
      label: (
        <span onClick={handleTaskView}>
          <img
           src={taskView}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Task View
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span onClick={() => handleDelete(todo)}>
          <img
            src={deleteIcon}
            className="dropdownbtn"
            style={{ width: "15px", position: "relative", top: "2px" }}
            alt=""
          />{" "}
          Delete
        </span>
      ),
    },
  ];

  return (
    <TaskCardStyle>
      <div key={index} className="todo-body" >
        <div className="webView">
          <div className="todo">
            <div className="tasklist-container">
              <div style={{ flex: 0 }}>
                <Radio
                  className="todo-radio"
                  onClick={handleRadioClick}
                  checked={taskStatus === "Pending"}
                ></Radio>
              </div>
              <div className="timers" style={{ flex: 1,cursor:'pointer' }} onClick={handleTaskView} >
                <h5>{formattedDate}</h5>
                <p>
                  <img src={clockIcon} alt="" /> <span>{formattedTime}</span>
                </p>
              </div>
              <div
                className="tasklist-title"
                style={{ flex: 4, borderRight: "3px solid #E8EDF3", cursor: 'pointer' }}
                onClick={handleTaskView} 
              >
                <h5>
                  {todo.title}
                  <span className="edited-text">
                    {todo.isUpdate === true && "(edited)"}
                  </span>
                </h5>
                <p className="tasklist-time">{todo.description}</p>
              </div>

              <div
                style={{ flex: 2, borderRight: "3px solid #E8EDF3", cursor:'pointer'}}
                className="prioritys"
                onClick={handleTaskView} 
              >
                <p
                  style={{
                    padding: "2px 10px 2px",
                    height: "28px",
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FlagImage
                    index={
                      todo.priorityId ? parseInt(todo.priorityId, 10) - 1 : 3
                    }
                  />
                  <span>{priorityName}</span>
                </p>
              </div>

              <div  
                className="pins"
                style={{ flex: 1, borderRight: "3px solid #E8EDF3" }}
              >
                <p onClick={handlePin} style={{cursor:'pointer'}}>
                  {todo.pin ? <PushpinFilled /> : <PushpinOutlined />}

                  <span>Pin</span>
                </p>
              </div>
              <div className="taskEdit" style={{ flex: 1 }}>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    icon={
                      <img
                        src={menuIcon}
                        alt=""
                        style={{
                          width: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      />
                    }
                  >
                    Menu
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileView">
          <div className="todo">
            <div className="tasklist-container">
              <div>
                <Radio
                  className="todo-radio"
                  onClick={handleRadioClick}
                  checked={taskStatus === "Pending"}
                ></Radio>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => handleTaskModalSet(todo)}
              >
                <p className="tasklist-title">
                  {todo.title}
                  <span className="edited-text">
                    {todo.isUpdate === true && "(edited)"}
                  </span>
                </p>
                <p className="tasklist-time">{formattedDate}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  border: "1.8px solid rgb(134, 135, 231)",
                  padding: "2px 10px 2px",
                  borderRadius: "4px",
                  height: "28px",
                }}
              >
                <FlagImage
                  index={
                    todo.priorityId ? parseInt(todo.priorityId, 10) - 1 : 3
                  }
                />
              </span>

              {todo.subtasks &&
                todo.subtasks.length !== 0 &&
                todo.subtasks[0]?.title !== "" && (
                  <>
                    {" "}
                    <DownOutlined
                      className={downOutlineClass}
                      onClick={() => handleSubtask(true)}
                    />
                    <UpOutlined
                      className={upOutlineClass}
                      onClick={() => handleSubtask(false)}
                    />
                  </>
                )}
            </div>
          </div>
          {isDownOutlinedClick === true && (
            <div className="todo-subtask">
              <div>
                <Radio className="todo-radio"></Radio>
              </div>
              <div
                style={{ width: "100%" }}
                onClick={() => handleTaskModalSet(todo)}
              >
                <p>
                  {todo.subtasks.length !== 0 && todo.subtasks[0].title !== ""
                    ? todo.subtasks[0].title
                    : ""}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {isTaskModalClick === true && (
        <CreateTask
          isTaskModalSet={isTaskModalSet}
          handleTaskModalOpen={handleTaskModalOpen}
          taskDate={taskDate}
        />
      )}
        <ViewTask
        drawerVisible={taskDrawerVisible}
        onClose={onCloseTaskDrawer}
        Data={todo}
      />
    </TaskCardStyle>
  );
}

export default TaskCard;
