import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function SearchFor(props) {
  return (
    <>
      <Input
        {...props}
        placeholder={props.placeholder}
        prefix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          padding: "12px 10px",
          fontSize: "20px",
          gap: "8px",
        }}
      />
    </>
  );
}

export default SearchFor;
