import styled from "styled-components";

export const AiTaskContainer = styled.div`
  .custom-search-button {
    background-color: #1677ff; /* Change to your desired background color */
    padding: 5px; /* Add padding if needed */
    border-radius: 5px; /* Make corners rounded */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it covers the entire button height */
  }

  /* Optionally style the icon specifically */
  .custom-search-button .anticon {
    color: #ffffff; /* Change the icon color */
  }
  .microphone-setting {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    color: #8c97a8;
    font-family: inter;
  }

  .micIcon {
    margin-top: 10px;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 2;
      color: #8c97a8;
      font-family: inter;
    }
  }

  .ai-text {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  .reset-button {
    float: right;
    margin-top: 15px;
  }

  .btn-container {
    position: fixed;
    bottom: 50px;
    width: 29%;
    display: flex;
    flex-direction: column;
    margin-top: 58px;
    gap: 20px;
    .ant-btn {
      padding: 12px;
      height: auto;
    }
  }
`;
