import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTask,
  addTask,
  updateTask,
  deleteTask,
  statusUpdate,
  addDuplicateTask,
  duplicateTask,
  updateGlobalDate,
  addAiTask,
  updatePin,
} from "../actions/task.actions";

const initialState = {
  tasks: [],
  loading: false,
  error: null,
  globalDate: "",
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    resetTasksState: (state) => {
      return {
        tasks: [],
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const tasksWithSubtasks = action.payload.map((task) => ({
          ...task,
          subtasks: task.subtasks || [], // Ensure subtasks array exists, or initialize as empty array
        }));
        state.tasks = tasksWithSubtasks;
      })
      .addCase(fetchTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(addTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { taskId, taskData } = action.payload;
        if (taskId !== null && taskData !== null) {
          state.tasks = [...state.tasks, { id: taskId, ...taskData }];
        }
      })
      .addCase(addTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(addDuplicateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDuplicateTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { taskId, taskData } = action.payload;
        state.tasks = [...state.tasks, { id: taskId, ...taskData }];
      })
      .addCase(addDuplicateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const updatedTaskIndex = state.tasks.findIndex(
          (task) => task.id === action.payload.taskId
        );
        if (updatedTaskIndex !== -1) {
          state.tasks[updatedTaskIndex] = action.payload.taskData;
          if (action.payload.taskData.subtasks) {
            const taskWithSubtasks = state.tasks[updatedTaskIndex];
            taskWithSubtasks.subtasks = action.payload.taskData.subtasks;
          }
        }
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tasks = state.tasks.filter(
          (task) => task.id !== action.payload.id
        );
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(statusUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(statusUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedTaskIndex = state.tasks.findIndex(
          (task) => task.id === action.payload.id
        );
        if (updatedTaskIndex !== -1) {
          state.tasks[updatedTaskIndex] = action.payload;
        }
      })
      .addCase(statusUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(duplicateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(duplicateTask.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { taskId, taskData } = action.payload;
        state.tasks = [...state.tasks, { id: taskId, ...taskData }];
      })
      .addCase(duplicateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updateGlobalDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGlobalDate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.globalDate = action.payload;
      })
      .addCase(updateGlobalDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updatePin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const updatedTaskIndex = state.tasks.findIndex(
          (task) => task.id === action.payload.id
        );
        if (updatedTaskIndex !== -1) {
          state.tasks[updatedTaskIndex].pin = action.payload.pin;
        }
      })
      .addCase(updatePin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectTasks = (state) => state.task.tasks;
export const selectLoading = (state) => state.task.loading;
export const selectError = (state) => state.task.error;
export const { resetTasksState } = taskSlice.actions;
export default taskSlice.reducer;
