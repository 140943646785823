import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { db } from "../../backend/firebase";
import moment from "moment";

export const addTeam = createAsyncThunk(
  "teams/AddTeam",
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const dbRef = collection(db, "teams");
      const timestamp = serverTimestamp();
      const newDocRef = await addDoc(dbRef, {
        user_id: userId,
        userGroup: [userId],
        name: data.teamName,
        description: data.teamDescription,
        createdOn: timestamp,
      });

      const currentUser = await getUser(userId);
      const currentMemberData = {
        actions: "Admin",
        email: currentUser.email,
        user_id: userId,
        status: true,
      };

      await addMemberToTeam(newDocRef, currentMemberData);

      // let membersData = [];
      // if (data.teamMembers && data.teamMembers.length > 0) {
      //   const TeamsRef = collection(newDocRef, "members");
      //   try {
      //     for (const item of data.teamMembers) {
      //       const newTeamsRef = await addDoc(TeamsRef, {
      //         actions: "User",
      //         email: item,
      //         status: false,
      //         user_id: "",
      //       });
      //       const newTeamsDoc = await getDoc(newTeamsRef);

      //       await getUserDetails(item);
      //       // Send Email
      //       await sendInvitationEmail(
      //         item,
      //         data.teamName,
      //         newDocRef.id,
      //         newTeamsRef.id
      //       );

      //       membersData.push({
      //         TeamsId: newTeamsRef.id,
      //         ...newTeamsDoc.data(),
      //       });
      //     }
      //   } catch (error) {
      //     console.error("Error adding subtask:", error);
      //   }
      // }
      const membersData = await Promise.all(
        (data.teamMembers || []).map(async (item) => {
          const newTeamsRef = await addDoc(collection(newDocRef, "members"), {
            actions: "User",
            email: item,
            status: false,
            user_id: "",
          });

          const newTeamsDoc = await getDoc(newTeamsRef);

          // Assuming these functions are asynchronous
          await getUserDetails(item);
          await sendInvitationEmail(
            item,
            data.teamName,
            newDocRef.id,
            newTeamsRef.id
          );

          return {
            TeamsId: newTeamsRef.id,
            ...newTeamsDoc.data(),
          };
        })
      );

      const addedTeamData = {
        id: newDocRef.id,
        user_id: userId,
        name: data.teamName,
        description: data.teamDescription,
        createdOn: timestamp,
        members: membersData,
      };

      return addedTeamData;
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "teams/Update",
  async ({ teamId, data }, { rejectWithValue }) => {
    try {
      const dbRef = collection(db, "teams");
      const updateDocRef = doc(dbRef, teamId);
      await updateDoc(updateDocRef, {
        name: data.name,
        description: data.description,
      });
      const updatedDocSnapshot = await getDoc(updateDocRef);
      const updatedData = updatedDocSnapshot.data();
      return { teamId, data };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

async function sendInvitationEmail(email, teamName, teamId, memberId) {
  const url = `https://tickit-fidii.netlify.app/invitations/${teamId}/${memberId}`;
  const subject = `Invitation to Join ${teamName} Team`;

  const content = `
          <html>
            <body style="font-family: Arial, sans-serif;">
              <h2 style="color: #333;">Invitation to Join Team: <strong>${teamName}</strong></h2>
              <p>You've been invited to join the team <strong>${teamName}</strong> on our platform.</p>
              <p>You can accept or decline this invitation by clicking the button below:</p>
              <a href="${url}" target="_blank" style="text-decoration: none; color: #007bff;">
                <button style="background-color: #007bff; color: #fff; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer;">View Invitation</button>
              </a>
              <p>Kind Regards,<br/>Tickit</p>
            </body>
          </html>
        `;

  await sendMails(email, subject, content);
}

async function getUser(userId) {
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();
  return {
    email: userData.email,
    name: userData.name,
    phone: userData.phone,
    imageUrl: userData.imageUrl,
  };

  // return userSnap.data();
}

async function addMemberToTeam(teamRef, memberData) {
  const newTeamsRef = await addDoc(collection(teamRef, "members"), memberData);
  return newTeamsRef;
}

export const fetchTeamsList = createAsyncThunk(
  "teams/fetchTeamsListAsync",
  async (userId, { rejectWithValue }) => {
    try {
      const teamsData = [];
      // const teamsQuerySnapshot = await getDocs(
      //   collection(db, "teams"),
      //   orderBy("createdOn", "desc")
      // );
      const q = query(
        collection(db, "teams"),
        where("userGroup", "array-contains", userId),
        orderBy("createdOn", "desc")
      );

      const teamsQuerySnapshot = await getDocs(q);

      const memberPromises = [];

      teamsQuerySnapshot.forEach((teamDoc) => {
        const team = { id: teamDoc.id, ...teamDoc.data() };
        teamsData.push(team);

        const memberPromise = getDocs(collection(teamDoc.ref, "members"))
          .then(async (membersQuerySnapshot) => {
            const membersData = [];
            // membersQuerySnapshot.forEach((memberDoc) => {
            for (const memberDoc of membersQuerySnapshot.docs) {
              const memberInfo = { id: memberDoc.id, ...memberDoc.data() };

              let userData = null;
              if (memberInfo.user_id) {
                try {
                  userData = await getUserDetails(memberInfo.user_id);
                } catch (getUserError) {
                  console.error("Error fetching user details:", getUserError);
                }
              }

              // if (memberInfo.actions === "User") {
              membersData.push({
                ...memberInfo,
                ...(userData ? { userInfo: userData } : {}),
              });
              // }
            }
            team.members = membersData;
          })
          .catch((error) => {
            console.error("Error fetching members:", error);
          });

        memberPromises.push(memberPromise);
      });

      await Promise.all(memberPromises);

      return teamsData;
    } catch (error) {
      console.error("Error fetching teams:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const getUserDetails = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        email: userData.email,
        userId: userData.user_id,
        name: userData.name,
        phone: userData.phone,
        imageUrl: userData.imageUrl,
      };
    } else {
      console.error("User does not exist");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const deleteTeamMember = createAsyncThunk(
  "teams/deleteTeamMemberAsync",
  async ({ teamId, memberId }, { rejectWithValue }) => {
    try {
      await deleteDoc(doc(db, `teams/${teamId}/members/${memberId}`));
      const teamDocRef = doc(db, "teams", teamId);
      const teamDoc = await getDoc(teamDocRef);
      if (teamDoc.exists()) {
        const teamData = teamDoc.data();
        // Remove memberId from userGroup if it exists
        if (teamData.userGroup && teamData.userGroup.includes(memberId)) {
          teamData.userGroup = teamData.userGroup.filter(
            (id) => id !== memberId
          );
          await updateDoc(teamDocRef, { userGroup: teamData.userGroup });
        }
      }
      return { teamId, memberId };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "teams/addTeamMemberAsync",
  async ({ teamId, memberData }, { rejectWithValue }) => {
    try {
      const existingMemberQuery = await getDocs(
        query(
          collection(db, `teams/${teamId}/members`),
          where("email", "==", memberData.email)
        )
      );

      if (!existingMemberQuery.empty) {
        throw new Error("Email already exists");
      }
      const docRef = await addDoc(
        collection(db, `teams/${teamId}/members`),
        memberData
      );
      const addedDocSnapshot = await getDoc(docRef);

      const teamName = await getTeamName(teamId);
      const prod = "https://tickit-fidii.netlify.app";
      // Teams url:
      const url = `${prod}/invitations/${teamId}/${addedDocSnapshot.id}`;

      const content = `
                <html>
                  <body style="font-family: Arial, sans-serif;">
                    <h2 style="color: #333;">Invitation to Join Team: <strong>${teamName}</strong></h2>
                    <p>You've been invited to join the team <strong>${teamName}</strong> on our platform.</p>
                    <p>You can accept or decline this invitation by clicking the button below:</p>
                    <a href="${url}" target="_blank" style="text-decoration: none; color: #007bff;">
                      <button style="background-color: #007bff; color: #fff; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer;">View Invitation</button>
                    </a>
                    <p>Kind Regards,<br/>Tickit</p>
                  </body>
                </html>
            `;
      const subject = `Invitation to Join ${teamName} Team`;

      sendMails(memberData.email, subject, content);

      const a = { id: addedDocSnapshot.id, ...addedDocSnapshot.data() };
      return {
        teamId: teamId,
        memberData: a,
      };
    } catch (error) {
      console.error("Error adding team member:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

async function getTeamName(teamId) {
  const teamDocRef = doc(db, "teams", teamId);
  const teamDoc = await getDoc(teamDocRef);
  if (teamDoc.exists()) {
    return teamDoc.data().name;
  }
  return null;
}

const sendMails = async (recipient, subject, content) => {
  try {
    const response = await fetch(
      "https://us-central1-new-todo-31c23.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recipient,
          subject,
          content,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error sending email: ${response.statusText}`);
    }

    return true; // Return true if email sent successfully
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const checkInvitations = createAsyncThunk(
  "teams/Invitations",
  async ({ teamId, memberId }, { rejectWithValue }) => {
    try {
      if (teamId && memberId) {
        const memberDocRef = doc(db, `teams/${teamId}/members`, memberId);
        const memberDoc = await getDoc(memberDocRef);

        if (!memberDoc.exists()) {
          console.error("Member ID does not exist in the team");
          return rejectWithValue("Member ID does not exist in the team");
        }

        const memberData = {
          id: memberDoc.id,
          ...memberDoc.data(),
        };
        if (memberData.status === true) {
          return rejectWithValue("Member is already verified");
        }

        const teamDocRef = doc(db, "teams", teamId);
        const teamDoc = await getDoc(teamDocRef);

        if (!teamDoc.exists()) {
          console.error("Team does not exist");
          return rejectWithValue("Team does not exist");
        }

        const usersDocRef = doc(db, "users", teamDoc.data().user_id);
        const usersDoc = await getDoc(usersDocRef);

        const teamData = {
          teamId: teamDoc.id,
          ...teamDoc.data(),
          userInfo: {
            name: usersDoc.data().name,
            email: usersDoc.data().email,
          },
        };

        return {
          teamData,
          memberData,
        };
      } else {
        console.error("Team ID or Member ID is missing");
        return rejectWithValue("Team ID or Member ID is missing");
      }
    } catch (error) {
      console.error("Error fetching teams id:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const AcceptInvitationsAsync = createAsyncThunk(
  "teams/InvitationAccepts",
  async (data, { rejectWithValue }) => {
    try {
      const memberDocRef = doc(
        db,
        `teams/${data.teamId}/members`,
        data.memberId
      );
      // Update the member document
      await updateDoc(memberDocRef, {
        status: true,
        user_id: data.user_id,
        actions: data.actions,
      });
      // Update the userGroup in the team document
      const teamDocRef = doc(db, "teams", data.teamId);
      const teamDoc = await getDoc(teamDocRef);
      if (teamDoc.exists()) {
        const teamData = teamDoc.data();
        // Add userId to userGroup if not already present
        // if (!teamData.userGroup) {
        //   teamData.userGroup = [data.user_id]; // Initialize userGroup array if it doesn't exist
        // } else if (!teamData.userGroup.includes(data.user_id)) {
        //   teamData.userGroup.push(data.user_id); // Add userId to userGroup if not already present
        // }

        if (!teamData.userGroup) {
          teamData.userGroup = [data.user_id];
        } else {
          if (!teamData.userGroup.includes(data.user_id)) {
            teamData.userGroup.push(data.user_id);
          }
        }
        await updateDoc(teamDocRef, { userGroup: teamData.userGroup });
      }
      return data;
    } catch (error) {
      console.error("Error fetching teams id:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  "DeleteTeam",
  async (teamId, { rejectWithValue }) => {
    try {
      let dbRef = collection(db, "teams");
      const deleteRef = doc(dbRef, teamId.id);
      const newDocRef = await deleteDoc(deleteRef);
      const addedDocSnapshot = await getDoc(deleteRef);
      return {
        id: addedDocSnapshot.id,
        ...addedDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error fetching tasks:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addTask = createAsyncThunk(
  "teams/AddTask",
  async (
    { teamId, payloadData, selectedDates, selectedTime },
    { rejectWithValue }
  ) => {
    try {
      const timestamp = serverTimestamp();
      const formattedDate = selectedDates
        ? moment(selectedDates).format("YYYY-MM-DD")
        : null;
      let currentDate = new Date();
      if (formattedDate !== "" && formattedDate !== null) {
        currentDate = formattedDate;
      } else {
        currentDate = currentDate.toISOString().split("T")[0];
      }
      const combinedDateAndTime = `${currentDate} ${selectedTime}`;
      const DateAndTime = new Date(combinedDateAndTime);
      const taskData = {
        title: payloadData.title || "",
        description: payloadData.description || "",
        dueDate: new Date(DateAndTime),
        time: selectedTime || "",
        priority: payloadData.priority || "4",
        pin: payloadData.pin || false,
        assignTo: payloadData.assignTo || "",
        status: "Pending",
        assignBy: payloadData.createdBy,
        createdOn: timestamp,
      };
      const teamRef = doc(db, "teams", teamId);
      const taskCollectionRef = collection(teamRef, "tasks");
      const taskDocRef = await addDoc(taskCollectionRef, taskData);
      const taskDoc = await getDoc(taskDocRef);
      let subtasksData = [];

      if (payloadData.subtasks) {
        for (let subtask of payloadData.subtasks) {
          console.log(subtask, "subtask");
          const subtaskData = {
            title: subtask.title,
            description: subtask.description,
          };

          const subtaskCollectionRef = collection(taskDocRef, "subtasks");
          const subtaskDocRef = await addDoc(subtaskCollectionRef, subtaskData);
          const subtaskDoc = await getDoc(subtaskDocRef);

          subtasksData.push({
            subtaskId: subtaskDocRef.id,
            ...subtaskDoc.data(),
          });
        }
      }
      var userInfo = "";
      if (taskData.assignTo) {
        try {
          const assignedToData = await getUserDetails(taskData.assignTo);
          userInfo = assignedToData;
        } catch (getUserError) {
          console.error("Error fetching assigned user:", getUserError);
        }
      }

      const taskDocData = taskDoc.data();
      const taskResponse = {
        teamId: teamId,
        taskData: {
          id: taskDocRef.id,
          ...taskDocData,
          subtasks: subtasksData,
          teamId: teamId,
          userInfo: userInfo,
        },
      };
      return taskResponse;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

export const getTeamsTask = createAsyncThunk(
  "teams/GetTask",
  async (teamId, { rejectWithValue }) => {
    try {
      const teamRef = doc(db, "teams", teamId);
      const taskCollectionRef = collection(teamRef, "tasks");
      const snapshot = await getDocs(
        taskCollectionRef,
        orderBy("createdOn", "desc")
      );

      const tasksPromises = [];

      snapshot.forEach((doc) => {
        const taskData = doc.data();

        const taskPromise = (async () => {
          const formattedTaskData = {
            ...taskData,
            createdOn: taskData.createdOn.toDate(),
            dueDate: taskData.dueDate,
            time: taskData.time,
            id: doc.id,
            subtasks: [],
          };

          // Fetch assigned user
          if (taskData.assignTo) {
            try {
              const assignedToData = await getUserDetails(taskData.assignTo);
              formattedTaskData.userInfo = assignedToData;
            } catch (getUserError) {
              console.error("Error fetching assigned user:", getUserError);
            }
          }

          // Fetch subtasks
          const subtaskCollectionRef = collection(doc.ref, "subtasks");
          const subtaskSnapshot = await getDocs(subtaskCollectionRef);
          subtaskSnapshot.forEach((subtaskDoc) => {
            const subtaskData = subtaskDoc.data();
            const formattedSubtaskData = {
              ...subtaskData,
              id: subtaskDoc.id,
            };
            formattedTaskData.subtasks.push(formattedSubtaskData);
          });

          return formattedTaskData;
        })();

        tasksPromises.push(taskPromise);
      });

      const tasks = await Promise.all(tasksPromises);

      return tasks;
    } catch (error) {
      console.error("Error fetching tasks by teamId:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateTask = createAsyncThunk(
  "teams/UpdateTask",
  async (
    {
      teamId,
      taskId,
      payloadData,
      selectedDate,
      selectedTime,
      dateCheck,
      timeCheck,
    },
    { rejectWithValue }
  ) => {
    try {
      let currentDate = new Date();
      let DateAndTime;
      if (dateCheck !== "" && timeCheck !== "") {
        const formattedDate = moment(dateCheck).format("YYYY-MM-DD");
        if (formattedDate !== "" && formattedDate !== null) {
          currentDate = formattedDate;
        } else {
          currentDate = currentDate.toISOString().split("T")[0];
        }
        const combinedDateAndTime = `${currentDate} ${timeCheck}`;
        DateAndTime = new Date(combinedDateAndTime);
      } else {
        const formattedDate = selectedDate
          ? moment(selectedDate).format("YYYY-MM-DD")
          : null;
        if (formattedDate !== "" && formattedDate !== null) {
          currentDate = formattedDate;
        } else {
          currentDate = currentDate.toISOString().split("T")[0];
        }
        const combinedDateAndTime = `${currentDate} ${selectedTime}`;
        DateAndTime = new Date(combinedDateAndTime);
      }
      const taskData = {
        title: payloadData.title,
        description: payloadData.description,
        dueDate: DateAndTime,
        time: selectedTime,
        priority: payloadData.priority,
        pin: payloadData.pin,
        assignTo: payloadData.assignTo,
        status: "Pending",
        assignBy: "",
      };

      const teamRef = doc(db, "teams", teamId);
      const taskDocRef = doc(teamRef, "tasks", taskId);
      await updateDoc(taskDocRef, taskData);
      const addedDocSnapshot = await getDoc(taskDocRef);
      let subtasksData = [];
      // First, delete existing subtasks
      const subtaskCollectionRef = collection(taskDocRef, "subtasks");
      const subtaskQuerySnapshot = await getDocs(subtaskCollectionRef);
      subtaskQuerySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      // Then, add new subtasks
      for (let subtask of payloadData.subtasks) {
        const subtaskData = {
          title: subtask.title,
          description: subtask.description,
        };

        const subtaskDocRef = await addDoc(subtaskCollectionRef, subtaskData);
        const subtaskDoc = await getDoc(subtaskDocRef);

        subtasksData.push({
          subtaskId: subtaskDocRef.id,
          ...subtaskDoc.data(),
        });
      }
      var userInfo = "";
      if (taskData.assignTo) {
        try {
          const assignedToData = await getUserDetails(taskData.assignTo);
          userInfo = assignedToData;
        } catch (getUserError) {
          console.error("Error fetching assigned user:", getUserError);
        }
      }

      const taskResponse = {
        teamId: teamId,
        taskId: taskId,
        taskData: {
          // id: taskUpdateDocRef.id,
          ...addedDocSnapshot.data(),
          subtasks: subtasksData,
          teamId: teamId,
          taskId: taskId,
          userInfo: userInfo,
        },
      };

      return taskResponse;
    } catch (e) {
      console.log(e, "edit error");
      return rejectWithValue(e.message);
    }
  }
);

export const deleteTeamTask = createAsyncThunk(
  "teams/DeleteTask",
  async ({ teamId, taskId }, { rejectWithValue }) => {
    try {
      const teamRef = doc(db, "teams", teamId);
      const taskDocRef = doc(teamRef, "tasks", taskId);

      await deleteDoc(taskDocRef);

      const subtaskCollectionRef = collection(taskDocRef, "subtasks");
      const subtaskQuerySnapshot = await getDocs(subtaskCollectionRef);

      subtaskQuerySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      return { teamId, taskId };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const teamsDuplicateTask = createAsyncThunk(
  "teams/DuplicateTask",
  async ({ teamId, taskId, assignTo }, { rejectWithValue }) => {
    try {
      const timestamp = serverTimestamp();
      const taskDocRef = doc(db, "teams", teamId, "tasks", taskId);
      const dbRef = collection(db, "teams", teamId, "tasks");
      const taskSnapshot = await getDoc(taskDocRef);
      const taskData = taskSnapshot.data();
      const newTitle = await handleTaskTitleDuplication(taskData.title, teamId);

      const newTaskData = {
        title: newTitle,
        description: taskData.description || "",
        dueDate: taskData.dueDate,
        time: taskData.time || "",
        pin: taskData.pin || false,
        priority: taskData.priority ? taskData.priority : 4,
        status: taskData.status || "Pending",
        createdOn: timestamp,
        assignTo: taskData.assignTo ? taskData.assignTo : "",
      };
      const newTaskRef = await addDoc(dbRef, newTaskData);

      let subtasksData = [];
      if (taskData.subtasks) {
        const subtasksSnapshot = await getDocs(
          collection(taskDocRef, "subtasks")
        );
        const subtaskPromises = [];
        subtasksSnapshot.forEach(async (subtaskDoc) => {
          const subtaskData = subtaskDoc.data();
          const subtaskCollectionRef = collection(newTaskRef, "subtasks");
          const subtaskDocRef = await addDoc(subtaskCollectionRef, {
            title: subtaskData.title,
            description: subtaskData.description,
          });

          const addedSubtaskDoc = await getDoc(subtaskDocRef);
          subtasksData.push({
            subtaskId: subtaskDocRef.id,
            ...addedSubtaskDoc.data(),
          });
        });

        await Promise.all(subtaskPromises);
      }

      var userInfo = "";
      if (taskData.assignTo) {
        try {
          const assignedToData = await getUserDetails(taskData.assignTo);
          userInfo = assignedToData;
        } catch (getUserError) {
          console.error("Error fetching assigned user:", getUserError);
        }
      }

      const addedDocSnapshot = await getDoc(newTaskRef);

      const a = {
        teamId: teamId,
        taskId: newTaskRef.id,
        taskData: {
          id: newTaskRef.id,
          ...addedDocSnapshot.data(),
          subtasks: subtasksData,
          teamId: teamId,
          userInfo: userInfo,
        },
      };

      return a;
    } catch (error) {
      console.error("Error duplicating task:", error);
      return rejectWithValue(
        error.response ? error.response.data.message : "An error occurred"
      );
    }
  }
);

async function handleTaskTitleDuplication(originalTitle, teamId, user_id) {
  try {
    let updatedTitle = originalTitle.trim();

    const existingTasksQuery = query(
      collection(db, "teams", teamId, "tasks"),
      where("title", "==", updatedTitle)
    );

    const existingTasksSnapshot = await getDocs(existingTasksQuery);

    if (!existingTasksSnapshot.empty) {
      updatedTitle += " copy";
    }

    return updatedTitle;
  } catch (error) {
    console.error("Error handling task title duplication:", error);
    throw error;
  }
}

export const statusUpdate = createAsyncThunk(
  "statusUpdate",
  async ({ teamId, taskId, taskStatus }, { rejectWithValue }) => {
    try {
      const teamRef = doc(db, "teams", teamId);
      const statusUpdates = doc(teamRef, "tasks", taskId);
      await updateDoc(statusUpdates, {
        status: taskStatus,
      });
      const getDocSnapshot = await getDoc(statusUpdates);
      return {
        id: getDocSnapshot.id,
        ...getDocSnapshot.data(),
      };
    } catch (error) {
      console.error("Error updating task status:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateGlobalDate = createAsyncThunk(
  "task/globalDate",
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      console.error("Error global date:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchTask = createAsyncThunk(
  "fetchTask",
  async ({ teamId, date }, { rejectWithValue }) => {
    try {
      // Parse the dateString into a JavaScript Date object
      const dateString = new Date(date);

      const teamRef = doc(db, "teams", teamId);
      const taskCollectionRef = collection(teamRef, "tasks");
      const snapshot = await getDocs(
        taskCollectionRef,
        orderBy("createdOn", "desc")
      );
      let tasks = [];
      for (const doc of snapshot.docs) {
        const taskData = doc.data();

        // Convert dueDate to JavaScript Date object
        const dueDate = taskData.dueDate.toDate();

        // Check if the dueDate matches the provided date
        if (dueDate.toDateString() === dateString.toDateString()) {
          const formattedTaskData = {
            ...taskData,
            createdOn: taskData.createdOn.toDate(),
            dueDate: taskData.dueDate,
            time: taskData.time,
            id: doc.id,
            subtasks: [],
          };

          // Fetch assigned user
          if (taskData.assignTo) {
            const assignedToData = await getUserDetails(taskData.assignTo);
            formattedTaskData.userInfo = assignedToData;
          }

          // Fetch subtasks
          const subtaskCollectionRef = collection(doc.ref, "subtasks");
          const subtaskSnapshot = await getDocs(subtaskCollectionRef);
          subtaskSnapshot.forEach((subtaskDoc) => {
            const subtaskData = subtaskDoc.data();
            const formattedSubtaskData = {
              ...subtaskData,
              id: subtaskDoc.id,
            };
            formattedTaskData.subtasks.push(formattedSubtaskData);
          });

          tasks.push(formattedTaskData);
        }
      }
      return tasks;
    } catch (error) {
      console.error("Error fetching tasks by teamId:", error);
      return rejectWithValue(error.message);
    }
  }
);
