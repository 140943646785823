import styled from "styled-components";

export const DashboardStyles = styled.div`
  .cardTitle {
    img {
      width: 25px;
    }
    .title {
      position: relative;
      top: -8px;
      left: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #8c97a8;
    }
    .count {
      float: right;
      position: relative;
      top: 2px;
    }
  }
`;
