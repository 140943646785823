import styled from "styled-components";

export const TaskViewStyle = styled.div`
  .main-taskView {
    .breadcrumb-title {
      display: flex;
      text-align: center;
      .back-arrow {
        margin-right: 10px;
        margin-top: 5px;
        color: rgb(0, 0, 0);
        font-weight: 700;
      }
      .title-bar {
        margin-bottom: 10px;
        color: rgb(0, 0, 0);
        text-align: center;
        font-weight: 700;
        flex: 1 1 0%;
        font-size: 20px;
      }
    }

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .task-card {
      p {
        margin-top: 12px;
      }
      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-transform: capitalize;
        letter-spacing: -0.32px;
        color: #1e232cde;
      }

      .periority {
        color: #778399;
        font-weight: 500;
        font-size: 16px;
        line-height: 19.36px;
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #666666;
      }
      .date {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #afafaf;
      }
      .status {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: right;
      }
    }
  }
`;
