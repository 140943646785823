import React, { useState } from "react";
import {
  LoginButtons,
  NoAccount,
  OtherLogins,
  PageHeading,
  SignupStyle,
  ForgotPasswordLink,
} from "./styles";
import {
  Button,
  Form,
  Input,
  notification,
  Spin,
  Checkbox,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google.svg";
import MailIcon from "../../assets/emailIcon.svg";
import PasswordIcon from "../../assets/lockIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  googleLogin,
  signInWithEmailPassword,
} from "../../redux/actions/auth.thunks";
import useNotifications from "../../components/Notification/index";
import Img1 from "../../assets/auth/img1.svg";
import Img2 from "../../assets/auth/img2.svg";
import logoImage from "../../assets/navbar/logo.svg";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export const SigninPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const authLoading = useSelector((state) => state.auth.loading);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [api, contextHolder] = notification.useNotification();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = async () => {
    try {
      const userData = await dispatch(
        signInWithEmailPassword({ email, password })
      );
      if (
        userData &&
        userData.type === "auth/signInWithEmailAndPassword/rejected"
      ) {
        if (userData.error.code === "auth/wrong-password") {
          api.open({
            message: "Incorrect Password",
            description: null,
          });
        } else if (userData.error.code === "auth/invalid-email") {
          api.open({
            message: "Invalid Email",
            description: null,
          });
        } else if (userData.error.code === "auth/user-not-found") {
          api.open({
            message: "User not found",
            description: null,
          });
        } else if (userData.error.code === "auth/too-many-requests") {
          api.open({
            message:
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
            description: null,
          });
        } else if (userData.error.code === "auth/invalid-credential") {
          api.open({
            message: "Invalid Credential",
            description: null,
          });
        }
      } else {
        await notifications.getNotificationToken();
        navigate("/");
      }
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const googleAuthLogin = async () => {
    const result = await dispatch(googleLogin());
    if (
      result.type === "auth/googleLogin/fulfilled" &&
      result.payload.email != ""
    ) {
      await notifications.getNotificationToken();
      navigate("/");
    } else {
      api.open({
        message: "Invalid Credential",
        description: null,
      });
    }
  };

  return (
    <SignupStyle>
      {contextHolder}
      <Row className="main-row">
        <Col md={10} lg={10} xl={10} className="leftside-pic">
          <img src={Img1} alt="" />
        </Col>
        <Col md={6} lg={6} xl={6} className="todo-main">
          {/* {!authLoading && ( */}
          <div className="todo-signin">
            <img src={logoImage} alt="" className="rightside-pic" />
            <PageHeading>Signin</PageHeading>
            <Form
              name="basic"
              layout="vertical"
              className="todo-form"
              style={{
                width: "100%",
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={handleSignIn}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                  {
                    required: true,
                    message: "Please enter your Email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<img alt="email" src={MailIcon} />}
                  onChange={handleEmailChange}
                  value={email}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    type: "password",
                  },
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Password"
                  prefix={<img alt="password" src={PasswordIcon} />}
                  onChange={handlePasswordChange}
                  value={password}
                />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName=""
                className="keep-me-signin"
              >
                <Checkbox>Keep me signed in</Checkbox>
              </Form.Item>

              <ForgotPasswordLink>
                <a href="#">Forgot password?</a>
              </ForgotPasswordLink>
              <Form.Item
                className="todo-ant-button"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" className="primaryBtn" htmlType="submit">
                  Signin
                </Button>
              </Form.Item>
            </Form>
            <OtherLogins>
              <span className="seperator">Or Signin with</span>
              <LoginButtons>
                <Button onClick={googleAuthLogin}>
                  <img alt="google" src={GoogleIcon} />
                </Button>
              </LoginButtons>
            </OtherLogins>
            <NoAccount>
              <p>
                Don't have an account?{" "}
                <span
                  className="todo-signup-free"
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  Register Now
                </span>
              </p>
            </NoAccount>
          </div>
          {/* )} */}
          {authLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </Col>
        <img src={Img2} alt="" className="bottom-pic" />
      </Row>
    </SignupStyle>
  );
};
