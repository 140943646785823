import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import prioritySlice from "../reducers/priority.reducer";
import authSlice from "../reducers/auth.reducer";
import taskSlice from "../reducers/tasks.reducer";
import notificationSlice from "../reducers/notification.reducer";
import dashboardSlice from "../reducers/dashboard.reducer";
import teamsSlice from "../reducers/teams.reducer";

const persistConfig = {
  key: "root", // Key for the root of the state in storage
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    priority: prioritySlice,
    auth: authSlice,
    tasks: taskSlice,
    notifications: notificationSlice,
    taskCounts: dashboardSlice,
    teams: teamsSlice,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: true,
});

const persistor = persistStore(store);

export { store, persistor };
