import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, message, Space, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { addTeam } from "../../../redux/actions/teams.actions";
import { AddTeamsContainer } from "./styles";

function AddTeams({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth?.user);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setIsLoading(true);
    message.loading({ content: "Loading", key: "loading" });
    const { teamName } = values;
    const { teamDescription } = values;
    const data = {
      teamName: teamName.trim() || "",
      teamDescription: teamDescription.trim() || "",
      teamMembers: teamMembers.filter((member) => member.trim() !== ""),
      teamTasks: [{ name: "new task" }],
    };
    await dispatch(addTeam({ userId: currentUser?.user_id, data }));
    message.success({ content: "Team added successfully", key: "loading" });
    form.resetFields();
    setIsFormDirty(false);
    setIsLoading(false);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const onValuesChange = () => {
    setIsFormDirty(true);
  };

  const handleAddTeamMember = () => {
    setTeamMembers([...teamMembers, ""]);
  };

  const handleRemoveTeamMember = (indexToRemove) => {
    setTeamMembers(teamMembers.filter((_, index) => index !== indexToRemove));
  };

  const handleChangeTeamMember = (value, index) => {
    const newMembers = [...teamMembers];
    newMembers[index] = value;
    setTeamMembers(newMembers);
  };

  return (
    <>
      <AddTeamsContainer>
        <div className="profileUpdate-card-main">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            className="addteam-form"
          >
            <Form.Item
              name="teamName"
              label="Team Name"
              className="teamname-input"
              rules={[
                { required: true, message: "Please input the Team Name!" },
                { whitespace: true, message: "Enter Team Name" },
              ]}
            >
              <Input placeholder="Enter team name" />
            </Form.Item>
            <Form.Item
              name="teamDescription"
              label="Description"
              className="teamname-input team-description"
              rules={[{ whitespace: true, message: "Enter description" }]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Divider />
            {teamMembers.length > 0 && (
              <h3 className="teamMember-heading">Team Members</h3>
            )}
            {teamMembers.map((member, index) => (
              <div key={index} className="member-container">
                <Form.Item
                  name={`teamMember-${index}`}
                  className="teamMember-input"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                  style={{ flex: 1 }}
                >
                  <Input
                    value={member}
                    onChange={(e) =>
                      handleChangeTeamMember(e.target.value, index)
                    }
                    placeholder="Add team member"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => handleRemoveTeamMember(index)}
                    type="primary"
                    danger
                    style={{ float: "right" }}
                  >
                    Remove
                  </Button>
                </Form.Item>
              </div>
            ))}

            <Form.Item>
              <Button
                onClick={handleAddTeamMember}
                type="primary"
                ghost
                style={{ float: "right", width: "60%" }}
              >
                Add Team Member
              </Button>
            </Form.Item>

            <Form.Item className="btn-container">
              <Button onClick={handleCancel} style={{ marginRight: "10px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </AddTeamsContainer>
    </>
  );
}

export default AddTeams;
