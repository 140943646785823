import styled from "styled-components";

export const NotificationListStyle = styled.div`
  .notification-card-main {
    height: 300px;
    overflow-y: auto;
    h4 {
      cursor: pointer;
      float: right;
      font-weight: 500;
      font-size: 16px;
      color: #212121;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      color: #757575;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .notification-card {
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 0px 10px 10px 0px;

      .ant-card-body {
        padding: 15px;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #212121;
        text-transform: capitalize;
      }
      .date {
        font-size: 12px;
        font-weight: 400;
        color: #adadad;
      }
    }
  }
`;
