import React, { useState, useEffect  } from 'react';
import { Upload , message, Button } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from "react-redux"; 
import { EditFilled } from '@ant-design/icons';
import { ImpageFileUpload } from '../../redux/actions/auth.thunks';
import {ImageUploadStyle } from './styles'
import Avatar from '../../assets/profile/avatar.png';
const ProfileImage = () => {
  const [imageList, setImageList] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const onChange1 = async(info) => {
    message.loading({ content: "Loading", key: "loading" }); 
    if (info.file.status === 'done') {
        const url = await dispatch(ImpageFileUpload(info.file.originFileObj)); 
        setImageList([{ uid: info.file.uid, name: info.file.name, status: 'done', url: url }]);
      }else if (info.file.status === 'uploading') {
        const url = await dispatch(ImpageFileUpload(info.file.originFileObj)); 
        setImageList([{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: url.payload,
        }])
      }else{
        setImageList([]);
      }
      message.success({ content: "Updated successfully", key: "loading" }); 
  };

  const onChange = async(info) => {
    setImageList(info.fileList);
    const updatedCount = count + 1;
    setCount(updatedCount);

    if (updatedCount >= 3) {
      setCount(0);
    }
    message.loading({ content: "Loading", key: "loading" }); 
  

    if (updatedCount === 1) {
      setImageList(info.fileList);
    }

    if (updatedCount === 2 && info.file.status === 'uploading') {
      const url = await dispatch(ImpageFileUpload(info.file)); 
      setImageList([{
        uid: info.file.uid,
        name: info.file.name,
        status: 'done',
        url: url.payload,
      }]);
      setImage(url.payload);

    message.success({ content: "Updated successfully", key: "loading" }); 
    }

  };
    
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
  
    if (currentUser && currentUser.imageUrl) {
      setImageList([{ uid: '-1', name: 'image', status: 'done', url: currentUser.imageUrl }]);
    } else {
      setImageList([{ uid: '-1', name: 'avatar', status: 'done', url: Avatar }]);
    }
  }, [currentUser]);

  return (
    <ImageUploadStyle >
    <ImgCrop rotationSlider modalTitle="Profile Image Upload">
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-circle"
        onChange={onChange}
        beforeUpload={beforeUpload}
        onPreview={onPreview}
        fileList={imageList}
        showRemoveIcon={false} // Hide the delete icon
        showPreviewIcon={false}
        maxCount={1}
      >
          <Button icon={<EditFilled style={{color: '#fff'}} />} ></Button>
      </Upload>
    </ImgCrop>
    </ImageUploadStyle>
  );
};
export default ProfileImage;