import React from "react";
import { Drawer } from "antd";
import EditTeams from "./editTeams";
import { EditTeamsDrawerStyle } from "./styles";
function EditTeamsDrawer({ drawerVisible, onClose, Data }) {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  return (
    <Drawer
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: "24px",
            textAlign: "center",
            color: "#000",
          }}
        >
          Edit a Team
        </div>
      }
      placement={isMobile ? "bottom" : "right"}
      // width={ isMobile ? '90%' : 378 }
      closable={true}
      onClose={onClose}
      visible={drawerVisible}
      className="drawer"
    >
      <EditTeamsDrawerStyle>
        <EditTeams onClose={onClose} Data={Data} />
      </EditTeamsDrawerStyle>
    </Drawer>
  );
}

export default EditTeamsDrawer;
