import React, { useState, useEffect, useCallback, useRef } from "react";
import { Drawer, Switch, Input, Button, message, Tooltip } from "antd";
import { generateContent } from "../../services";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { addAiTask } from "../../redux/actions/task.actions";
import { useSelector, useDispatch } from "react-redux";
import AiAnswer from "../../assets/task/aiAnswer.svg";
import MicIcons from "../../assets/task/mic.svg";
import pauseIcon from "../../assets/task/pause.svg";
import cancelIcon from "../../assets/task/cancel.svg";
import { AiTaskContainer } from "./styles";

const { TextArea, Search } = Input;

function TaskAiDrawer({ drawerVisible, onClose }) {
  const [listening, setListening] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const [task, setTask] = useState("");
  const dispatch = useDispatch();
  const previousTranscript = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    assignTo: "",
    pin: false,
    priorityId: "4",
    subtasks: [],
  });

  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  useEffect(() => {
    setTask(transcript);
  }, [transcript]);

  const handleTaskChange = (e) => {
    setTask(e.target.value);
    previousTranscript.current = e.target.value;
  };

  const toggleListening = useCallback(() => {
    if (!isMicrophoneAvailable) {
      alert("Microphone is not available.");
      return;
    }

    if (listening) {
      SpeechRecognition.stopListening();
      setListening(false);
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        interimResults: true,
      });
      setListening(true);
    }
  }, [isMicrophoneAvailable, listening]);

  const handleCancel = () => {
    SpeechRecognition.stopListening(); // Turn off the microphone
    setListening(false); // Update the state to reflect the microphone status
    resetTranscript();
    setTask("");
    onClose();
  };

  const aiFunction = async () => {
    if (!transcript) {
      alert("Transcript is empty. Please use the microphone or enter text.");
      return;
    }
    setIsLoading(true);
    try {
      const prompt = `From this text, please give me task title and assign to alone in json format. Json keywords should be 'title', 'description', and 'assignTo'.  If I give many assign to members, you have to just give only one member as assign to. If I give mail id as assign to, please take thae that mail id as assign to. text=${transcript}`;
      const result = await generateContent(prompt);

      const parsedResult = JSON.parse(result);
      const updatedFormData = {
        ...formData,
        title: parsedResult.title,
        description: parsedResult.description,
        assignTo: parsedResult.assignTo,
      };
      setFormData(updatedFormData);
      const user_id = currentUser?.user_id;
      await dispatch(addAiTask({ formData: updatedFormData, user_id }));
      message.success({ content: "Task added successfully", key: "loading" });
      setIsLoading(false);
      handleCancel();
    } catch (err) {
      message.error({ content: "Error generating content" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={
        <div
          style={{
            fontWeight: 600,
            fontSize: "20px",
            textAlign: "center",
            color: "#000",
          }}
        >
          Add Task using AI
        </div>
      }
      width={isMobile ? "100%" : 500}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={drawerVisible}
      className="drawer"
    >
      <AiTaskContainer>
       
        <div className="ai-text">
          <img src={AiAnswer} alt="" style={{ flex: ".5 .1 0%" }} />
          <TextArea
            placeholder={!listening ? "Start speaking" : "Listening..."}
            value={task}
            // allowClear
            rows={4}
            readOnly
            onChange={handleTaskChange}
          />
          {!listening ? (
            <>
              <Button
                type="text"
                shape="circle"
                style={{
                  marginTop: "10px",
                  flex: ".5 .1 0%",
                  borderRadius: "50%",
                }}
                onClick={toggleListening}
                icon={<img src={MicIcons} alt="" className="" />}
              />
            </>
          ) : (
            ""
          )}
          {listening ? (
            <>
              <Tooltip title="Pause">
                <Button
                  type="text"
                  shape="circle"
                  style={{ marginTop: "10px", borderRadius: "50%" }}
                  onClick={toggleListening}
                  icon={<img src={pauseIcon} alt="" className="" />}
                />
              </Tooltip>
              <Tooltip title="Reset">
                <Button
                  type="text"
                  shape="circle"
                  style={{ marginTop: "10px", borderRadius: "50%" }}
                  onClick={resetTranscript}
                  icon={<img src={cancelIcon} alt="" className="" />}
                />
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </div>
        

        <div className="btn-container">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={aiFunction}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </AiTaskContainer>
    </Drawer>
  );
}

export default TaskAiDrawer;
