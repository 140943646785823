import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateTask from "../../components/Task/CreateTask";
import Navbar from "../../components/Navbar";
import { ProfileStyle } from "./styles";
import ProfileUpdate from "../../components/ProfileUpdate";

function Profile() {
  const navigate = useNavigate();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);

  const handleGoBack = () => {
    navigate("/");
  };

  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  return (
    <ProfileStyle>
      <Navbar handleTaskModalOpen={handleTaskModalOpen} />
      <div className="main-profiles">
        <div className="breadcrumb-title">
          <p className="back-arrow">
            <ArrowLeftOutlined onClick={handleGoBack} />
          </p>
          <p className="title-bar">Profile Settings</p>
        </div>
        <ProfileUpdate />
      </div>

      {isTaskModalOpen == true && (
        <CreateTask
          handleTaskModalOpen={handleTaskModalOpen}
          isTaskModalSet=""
        />
      )}
    </ProfileStyle>
  );
}

export default Profile;
