import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchNotificationsAsync, markAllNotificationsAsReadAsync, updateNotificationStatusAsync } from "../../redux/actions/notification.actions";
import CreateTask from "../../components/Task/CreateTask";
import Navbar from "../../components/Navbar";
import NotificationList from "../../components/Notification/notificationList";
import { NotificationStyle } from "./styles";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "../../components/Sidebar";

const { Content } = Layout;
function Notification() {
  const navigate = useNavigate();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const handleGoBack = () => {
    navigate("/");
  };

  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content>
          <NotificationStyle>
            <Navbar handleTaskModalOpen={handleTaskModalOpen} />
            <div className="main-notification">
              <div className="breadcrumb-title">
                <p className="back-arrow">
                  <ArrowLeftOutlined onClick={handleGoBack} />
                </p>
                <p className="title-bar">Notification</p>
              </div>
              <NotificationList />
            </div>

            {isTaskModalOpen == true && (
              <CreateTask
                handleTaskModalOpen={handleTaskModalOpen}
                isTaskModalSet=""
              />
            )}
          </NotificationStyle>
          ;
        </Content>
      </Layout>
    </Layout>
  );
}

export default Notification;
