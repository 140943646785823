import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { TeamsMainStyle } from "./styles";
import { Button, Layout, Card, Col, Row, message, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import TeamCardView from "../../components/Teams/cardView";
import LogoPic from "../../assets/navbar/logo.svg";
import {
  AcceptInvitationsAsync,
  checkInvitations,
} from "../../redux/actions/teams.actions";
import { InvitationsStyle, MessageContainer } from "./styles";
const { Content } = Layout;

function InvitationsMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const [datas, setDatas] = useState("");
  const { teamId, memberId } = useParams();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    getTeams(teamId, memberId);
  }, [teamId, memberId]);

  const getTeams = async (teamId, memberId) => {
    setLoading(true);
    try {
      const payload = { teamId, memberId };
      const a = await dispatch(checkInvitations(payload));
      if (a.type === "teams/Invitations/rejected") {
        message.error(a.payload);
        navigate("/teams");
      }
      setMessageText(a.payload);
      setDatas(a.payload);
    } catch (error) {
      message.error(error.message || "Error fetching teams");
      console.error("Error fetching teams:", error); // Error handling
    } finally {
      setLoading(false);
    }
  };

  const AcceptInvitation = async () => {
    setBtnLoading(true);
    const metaData = {
      status: true,
      user_id: currentUser?.user_id,
      memberId: memberId,
      teamId: teamId,
      actions: "User",
    };
    try {
      await dispatch(AcceptInvitationsAsync(metaData));
      message.success("Invitation accepted successfully!");
      setBtnLoading(false);
      navigate("/teams");
    } catch (error) {
      message.error("Failed to accept invitation. Please try again.");
      setBtnLoading(false);
    }
  };

  const CancelInvitations = () => {
    console.log("Invitation cancelled");
    navigate("/teams");
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Content>
          <Navbar />
          <InvitationsStyle>
            {loading ? (
              <div className="loader-container">
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div className="card-container">
                  <div className="card-content">
                    <img className="team-logo" src={LogoPic} alt="Team Logo" />
                    <h3 className="team-name">
                      Team Name: {datas?.teamData?.name}
                    </h3>
                    <p className="invited-by">
                      <span>Invited By:</span> {datas?.teamData?.userInfo?.name}
                    </p>
                    <p className="description">
                      <span>Description:</span> {datas?.teamData?.description}
                    </p>
                    <div className="button-container">
                      <Button
                        className="cancel-btn"
                        onClick={CancelInvitations}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="accept-btn"
                        loading={btnLoading}
                        onClick={AcceptInvitation}
                      >
                        Accept
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </InvitationsStyle>
        </Content>
      </Layout>
    </Layout>
  );
}

export default InvitationsMain;
