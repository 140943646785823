// fonts
export const FONT_FAMILY = 'Inter, sans-serif';
export const CONTENT_FONT_FAMILY = 'Inter, sans-serif'

// brand colors
export const APP_BACKGROUND_COLOR = '#FFFFFF';
export const PRIMARY_COLOR = '#3594FF';
export const MAIN_COLOR = "#1E232C";

// colors
export const WHITE_COLOR = '#FFFFFF';
export const BLACK_COLOR = '#000000';
export const STEEL = '#2B2B2A';
export const ALICE_BLUE = '#F8FAFB';
export const WHITE_SMOKE = '#F6F6F6';
export const CYAN_BLUE_LIGHT = "#E8ECF4";
export const DARK_GRAY = "#6A707C";
export const MEDIUM_GRAY = "#D6D6D6";
