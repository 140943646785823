import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  fetchNotificationsAsync,
  updateNotificationStatusAsync,
} from "../../redux/actions/notification.actions";
import { Card, Spin, Drawer } from "antd";
import { NotificationListStyle } from "./styles";
import { useNavigate } from "react-router-dom";
import TaskView from "../Task/TaskView";

function NotificationList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.notifications);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const notificationRead = async (notificationId, taskId) => {
    navigate(`/task-view/${taskId}`);
    await dispatch(updateNotificationStatusAsync(notificationId));
  };

  const notificationReadWeb = async (notificationId, taskId) => {
    await dispatch(updateNotificationStatusAsync(notificationId));
  };
  
  useEffect(() => {
    dispatch(fetchNotificationsAsync(currentUser?.user_id));
  }, [dispatch, currentUser]);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const lastWeek = new Date(today);
  lastWeek.setDate(lastWeek.getDate() - 7);
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const lastYear = new Date(today);
  lastYear.setFullYear(lastYear.getFullYear() - 1);

  const filteredNotifications = {
    today: [],
    yesterday: [],
    lastWeek: [],
    lastMonth: [],
    lastYear: [],
  };

  notifications.notificationList?.forEach((notification) => {
    const notificationDate = new Date(notification.timestamp.seconds * 1000); // Convert seconds to milliseconds
    if (isToday(notificationDate)) {
      filteredNotifications.today.push(notification);
    } else if (isYesterday(notificationDate)) {
      filteredNotifications.yesterday.push(notification);
    } else if (isLastWeek(notificationDate)) {
      filteredNotifications.lastWeek.push(notification);
    } else if (isLastMonth(notificationDate)) {
      filteredNotifications.lastMonth.push(notification);
    } else if (isLastYear(notificationDate)) {
      filteredNotifications.lastYear.push(notification);
    }
  });

  function isToday(date) {
    return date.toDateString() === today.toDateString();
  }

  function isYesterday(date) {
    return date.toDateString() === yesterday.toDateString();
  }

  function isLastWeek(date) {
    return date > lastWeek;
  }

  function isLastMonth(date) {
    return date > lastMonth;
  }

  function isLastYear(date) {
    return date > lastYear;
  }

  const formatDate = (timestamp) => {
    const momentTimestamp = moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    if (momentTimestamp.isSame(moment(), "day")) {
      return `Today at ${momentTimestamp.format("h:mm A")}`;
    } else if (momentTimestamp.isSame(moment().subtract(1, "days"), "day")) {
      return `Yesterday at ${momentTimestamp.format("h:mm A")}`;
    } else {
      return momentTimestamp.format("MMM D, YYYY [at] h:mm A");
    }
  };

  const formatDayOfWeek = (timestamp) => {
    return moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    ).format("dddd [at] h:mm A");
  };

  const formatMonthAndDay = (timestamp) => {
    return moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    ).format("MMM D [at] h:mm A");
  };

  const formatMonthDayAndYear = (timestamp) => {
    return moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    ).format("MMM D, YYYY [at] h:mm A");
  };

  const renderNotificationCard = (notification, index, days) => {
    return (
      <Card
        key={index}
        className="notification-card"
        // onClick={() => notificationRead(notification.id, notification.taskId)}
        onClick={
          isMobile
            ? () => notificationRead(notification.id, notification.taskId)
            : () => {
              notificationReadWeb(notification.id, notification.taskId)
                setSelectedTask(notification.taskId);
                setDrawerVisible(true);
              }
        }
        style={{
          borderLeft:
            notification?.task?.status == "Pending"
              ? `3px solid #F43F3F`
              : `3px solid #3DD193`,
          backgroundColor:
            notification?.status == "unread" ? "#F6FAFB" : "transparent",
        }}
      >
        <p className="title">{notification.title}</p>
        <p className="date">
          {days === "today" && (
            <p className="date">{formatDate(notification.timestamp)}</p>
          )}
          {days === "yesterday" && (
            <p className="date">{formatDate(notification.timestamp)}</p>
          )}
          {days === "lastWeek" && (
            <p className="date">{formatDayOfWeek(notification.timestamp)}</p>
          )}
          {days === "lastMonth" && (
            <p className="date">{formatMonthAndDay(notification.timestamp)}</p>
          )}
          {days === "lastYear" && (
            <p className="date">
              {formatMonthDayAndYear(notification.timestamp)}
            </p>
          )}
        </p>
      </Card>
    );
  };

  const onCloseDrawer = () => {
    setSelectedTask(null);
    setDrawerVisible(false);
  };

  return (
    <NotificationListStyle>
      <div className="notification-card-main">
        <br />
        {notifications.loading ? (
          <div className="spinner">
            <Spin size="small" />
          </div>
        ) : notifications.notificationList &&
          notifications.notificationList.length > 0 ? (
          <>
            {filteredNotifications.today.length > 0 && (
              <div>
                <h3>Today</h3>
                {filteredNotifications.today.map((notification, index) =>
                  renderNotificationCard(notification, index, "today")
                )}
              </div>
            )}
            {filteredNotifications.yesterday.length > 0 && (
              <div>
                <h3>Yesterday</h3>
                {filteredNotifications.yesterday.map((notification, index) =>
                  renderNotificationCard(notification, index, "yesterday")
                )}
              </div>
            )}

            {filteredNotifications.lastWeek.length > 0 && (
              <div>
                <h3>Last Week</h3>
                {filteredNotifications.lastWeek.map((notification, index) =>
                  renderNotificationCard(notification, index, "lastWeek")
                )}
              </div>
            )}

            {filteredNotifications.lastMonth.length > 0 && (
              <div>
                <h3>Last Month</h3>
                {filteredNotifications.lastMonth.map((notification, index) =>
                  renderNotificationCard(notification, index, "lastMonth")
                )}
              </div>
            )}

            {filteredNotifications.lastYear.length > 0 && (
              <div>
                <h3>Last Year</h3>
                {filteredNotifications.lastYear.map((notification, index) =>
                  renderNotificationCard(notification, index, "lastYear")
                )}
              </div>
            )}
          </>
        ) : (
          <p>No notifications found</p>
        )}
      </div>

      {/* Drawer */}

      <Drawer
        title="Task Details"
        placement="right"
        onClose={onCloseDrawer}
        visible={drawerVisible}
        width={400}
      >
        <TaskView taskId={selectedTask} />
      </Drawer>
    </NotificationListStyle>
  );
}

export default NotificationList;
