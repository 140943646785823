import styled from "styled-components";

export const CreateTaskStyle = styled.div`
  .flagColors path {
    fill: red;
  }

  .flagColors {
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
`;
