import { createSlice } from "@reduxjs/toolkit";
import {
  signUp,
  googleLogin,
  signInWithEmailPassword,
  profileUpdateApi,
  ImpageFileUpload
} from "../actions/auth.thunks";
const initialState = {
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(signInWithEmailPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInWithEmailPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.info;
        state.error = null;
      })
      .addCase(signInWithEmailPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.info;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(profileUpdateApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(profileUpdateApi.fulfilled, (state, action) => {
        state.loading = false;
        state.user.lastName = action.payload.lastName;
        state.user.name = action.payload.name;
        state.user.email = action.payload.email;
        state.user.phone = action.payload.phone;
        state.user.countryCode = action.payload.countryCode;
        state.error = null;
      })
      .addCase(profileUpdateApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(ImpageFileUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ImpageFileUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.user.imageUrl = action.payload;
        
        state.error = null;
      })
      .addCase(ImpageFileUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectUser = (state) => state.auth.user;
export const { resetState } = authSlice.actions;
export default authSlice.reducer;
