import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTask from "../../components/Task/CreateTask";
import moment from "moment";
import Navbar from "../../components/Navbar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { TaskViewMainStyle } from "./styles";
import { fetchTaskId } from "../../redux/actions/task.actions";
import { Card, Spin } from "antd";
import FlagImage from "../../components/FlagImages";
import TaskView from "../../components/Task/TaskView";
function TaskViewMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [taskData, setTaskData] = useState(null);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const priorityOptions = useSelector( (state) => state.priority.priorityOptions );
  
  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  const handleGoBack = () => {
    navigate("/notification");
  };
 

  return (
    <TaskViewMainStyle>
      <Navbar handleTaskModalOpen={handleTaskModalOpen} />
      <div className="main-taskView">
        <div className="breadcrumb-title">
          <p className="back-arrow">
            <ArrowLeftOutlined onClick={handleGoBack} />
          </p>
          <p className="title-bar">Tasks</p>
        </div>

        <TaskView taskId={taskId} />
        
      </div>

      {isTaskModalOpen === true && (
        <CreateTask
          handleTaskModalOpen={handleTaskModalOpen}
          isTaskModalSet=""
        />
      )}
    </TaskViewMainStyle>
  );
}

export default TaskViewMain;
