import React, { useState, useEffect } from "react";
import { Radio, message, Button, Dropdown, Row, Col, Card, Modal } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { statusUpdate } from "../../../redux/actions/teams.actions";
import { CaretDownOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { TaskCardStyle, TeamCardstyle } from "./styles";
import duplicateIcon from "../../../assets/home/create-task/duplicate_icon.svg";
import editMenu from "../../../assets/teams/editMenu.svg";
import deleteMenu from "../../../assets/teams/deleteMenu.svg";
import taskView from "../../../assets/teams/carbon_task-view.svg";
import clockIcon from "../../../assets/task/clock.svg";
import FlagImage from "../../FlagImages";
import {
  deleteTeamTask,
  teamsDuplicateTask,
} from "../../../redux/actions/teams.actions";
import TeamsTaskView from "./viewTask";
import dayjs from "dayjs";
import TeamsCreateTaskDrawer from "./createTask";
import createBtn from "../../../assets/home/create-task.svg";

function TeamsTaskCards({ task, todo, index, taskStatus, taskDate, teamIds }) {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
  const [createDrawerVisible, setCreateDrawerVisible] = useState(false);
  const [viewTaskData, setViewTaskData] = useState("");
  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );
  const [membersList, setMembersList] = useState([]);
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
  const [isEditData, setIsEditData] = useState("");
  const [downOutlineClass, setDownOutlineClass] = useState("");
  const [upOutlineClass, setUpOutlineClass] = useState("icon-display");
  const [isDownOutlinedClick, setIsDownOutlinedClick] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const TeamsList = useSelector((state) => state.teams);

  const handleDelete = (teamIds, task) => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure you want to delete this task?",
      onOk: async () => {
        try {
          // message.loading({ content: "Loading", key: "loading" });
          await dispatch(deleteTeamTask({ teamId: teamIds, taskId: task.id }));
          message.success({
            content: "Task delete successfuly",
            key: "loading",
          });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const handleEditModalSet = () => {
    setIsEditTaskOpen(true);
    const dueDate = dayjs.unix(task.dueDate.seconds);
    const formattedDueDate = dueDate.format("YYYY-MM-DD");
    const formattedTime = dueDate.format("HH:mm:ss");
    const updatedEditValues = {
      ...task,
      dueDate: formattedDueDate,
      time: formattedTime,
    };
    setIsEditData(updatedEditValues);
    setEditDrawerVisible(true);
  };

  const handleDuplicate = async (teamIds, task) => {
    message.loading({ content: "Loading", key: "loading" });
    await dispatch(teamsDuplicateTask({ teamId: teamIds, taskId: task.id }));
    message.success({ content: "Task added successfuly", key: "loading" });
  };

  const handleTaskView = async (teamIds, task) => {
    setViewTaskData(task);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onCreateCloseDrawer = () => {
    setCreateDrawerVisible(false);
  };

  const onEditCloseDrawer = () => {
    setEditDrawerVisible(false);
  };
  useEffect(() => {
    if (teamIds) {
      const a = TeamsList?.teamsList?.filter((x) => x.id === teamIds);
      setMembersList(a[0]?.members);
    }
  }, [teamIds]);

  var formattedDate = "";
  var dateValue = "";
  var formattedTime = "";

  if (task && task?.dueDate !== null && task?.dueDate !== "") {
    const { seconds, nanoseconds } = task?.dueDate;

    if (seconds && seconds != "") {
      formattedDate = new Date(seconds * 1000 + nanoseconds / 1000000);
      formattedDate = moment(formattedDate);
      if (moment().isSame(formattedDate, "day")) {
        dateValue = "Today";
      } else if (moment().subtract(1, "days").isSame(formattedDate, "day")) {
        dateValue = "Yesterday";
      } else {
        dateValue = formattedDate.format("DD MMM YYYY");
      }
      // Format time as "-h:mm a"
      var timeValue = formattedDate.format("h:mm a");
      formattedDate = `${dateValue} `;
      formattedTime = timeValue;
    }
  }

  let priorityName = "";
  if (task && task?.priority) {
    const checkPrioritys = priorityOptions?.find((x) => x.id === task.priority);
    if (checkPrioritys) {
      priorityName = checkPrioritys.name;
    } else {
      priorityName = "No Priority";
    }
  } else {
    priorityName = "No Priority";
  }

  const items = [];

  items.push({
    key: "1",
    label: (
      <span onClick={() => handleEditModalSet()}>
        <img
          src={editMenu}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Edit Task
      </span>
    ),
  });

  items.push({
    key: "3",
    label: (
      <span onClick={() => handleDuplicate(teamIds, task)}>
        <img
          src={duplicateIcon}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Duplicate
      </span>
    ),
  });

  items.push({
    key: "4",
    label: (
      <span onClick={() => handleTaskView(teamIds, task)}>
        <img
          src={taskView}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Task View
      </span>
    ),
  });
  // Delete
  items.push({
    key: "2",
    label: (
      <span onClick={() => handleDelete(teamIds, task)}>
        <img
          src={deleteMenu}
          className="dropdownbtn"
          style={{ width: "15px", position: "relative", top: "2px" }}
          alt=""
        />{" "}
        Delete
      </span>
    ),
  });

  const getColorFromString = (str) => {
    // Generate a color based on a string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.abs(hash).toString(16).substring(0, 6);
    return "#" + "0".repeat(6 - color.length) + color; // Pad the color if necessary
  };

  const handleRadioClick = async () => {
    try {
      message.loading({ content: "Loading", key: "loading" });
      await dispatch(
        statusUpdate({
          teamId: teamIds,
          taskId: task.id,
          taskStatus: taskStatus,
        })
      );
      message.success({ content: "Task update successfuly", key: "loading" });
    } catch (error) {
      message.error("Failed to update task");
    }
  };

  const handleSubtask = (val) => {
    setIsDownOutlinedClick(val);
    if (val === true) {
      setDownOutlineClass("icon-display");
      setUpOutlineClass("");
    } else if (val === false) {
      setDownOutlineClass("");
      setUpOutlineClass("icon-display");
    }
  };

  const handleTaskModalOpen = () => {
    setIsCreateTaskOpen(true);
    setCreateDrawerVisible(true);
  };

  return (
    <>
      <TeamCardstyle>
        <div className="teamsTask-webview">
          <Row gutter={16} key={index}>
            <Col span={24}>
              <Card className="teams-card" bordered={false}>
                <div className="card-main">
                  <div
                    style={{
                      // flex: "0.5 1 0%",
                      flex: "0",
                      padding: "2px 10px",
                    }}
                  >
                    <Radio
                      className="todo-radio"
                      onClick={handleRadioClick}
                      checked={taskStatus === "Pending"}
                    ></Radio>
                  </div>
                  <div className="timers" style={{ flex: "0.7 1 0%" }}>
                    <h5>{formattedDate}</h5>
                    <p className="time-container">
                      <img src={clockIcon} alt="" />{" "}
                      <span>{formattedTime}</span>
                    </p>
                  </div>
                  <div
                    style={{
                      flex: "1.6 1 0%",
                      borderRight: "3px solid #e8edf3",
                      padding: "2px 10px",
                    }}
                    className="teamTask-contents"
                  >
                    <h5>{task?.title}</h5>
                    <p>{task?.description}</p>
                  </div>
                  <div
                    style={{
                      flex: "1 1 0%",
                      borderRight: "3px solid #e8edf3",
                      padding: "2px 20px",
                    }}
                  >
                    <h5>Assign to</h5>
                    <div className="team-container">
                      {task?.userInfo ? (
                        <>
                          {task?.userInfo?.imageUrl ? (
                            <img
                              src={task?.userInfo?.imageUrl}
                              alt=""
                              className="profile-picture"
                            />
                          ) : (
                            <span
                              className="team-profileName"
                              style={{
                                backgroundColor: getColorFromString(
                                  task?.userInfo?.email
                                ),
                              }}
                            >
                              {task?.userInfo?.name?.charAt(0).toUpperCase()}
                            </span>
                          )}
                          <span>{task?.userInfo?.name}</span>
                        </>
                      ) : (
                        <>
                          <span
                            className="team-profileName"
                            style={{ backgroundColor: "#FF8080" }}
                          >
                            X
                          </span>
                          <span>No one</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1.2 1 0%",
                      borderRight: "3px solid #e8edf3",
                    }}
                    className="prioritys"
                  >
                    <p
                      style={{
                        padding: "2px 10px 2px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FlagImage
                        index={
                          task?.priority ? parseInt(task?.priority, 10) - 1 : 3
                        }
                      />
                      <span>{priorityName}</span>
                    </p>
                  </div>

                  <div
                    style={{
                      flex: "0.7 1 0%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Dropdown menu={{ items }} placement="bottomRight" arrow>
                      <Button className="teams-menu-btn">
                        Menu <CaretDownOutlined style={{ color: "#000000" }} />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="teamsTask-mobview">
          <Row gutter={16} key={index}>
            <Col span={24}>
              <Card className="teams-task-card" bordered={false}>
                <div className="taskcard-main">
                  <div className="taskcard-main1" style={{ width: "100%" }}>
                    <div>
                      <Radio
                        className="todo-radio"
                        onClick={handleRadioClick}
                        checked={taskStatus === "Pending"}
                      ></Radio>
                    </div>

                    <div
                      className="teamsTask-content"
                      style={{ width: "100%" }}
                      onClick={() => handleTaskView(teamIds, task)}
                    >
                      <h5 className="teamsTask-content-title">{task?.title}</h5>
                      <h5 className="teamsTask-content-date">
                        {formattedDate}
                      </h5>
                      <div className="team-container">
                        {task?.userInfo ? (
                          <>
                            {task?.userInfo?.imageUrl ? (
                              <img
                                src={task?.userInfo?.imageUrl}
                                alt=""
                                className="profile-picture"
                              />
                            ) : (
                              <span
                                className="team-profileName"
                                style={{
                                  backgroundColor: getColorFromString(
                                    task?.userInfo?.email
                                  ),
                                }}
                              >
                                {task?.userInfo?.name?.charAt(0).toUpperCase()}
                              </span>
                            )}
                            <span className="team-userName">
                              {task?.userInfo?.name}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="team-profileName"
                              style={{ backgroundColor: "#FF8080" }}
                            >
                              X
                            </span>
                            <span className="team-userName">No one</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="taskcard-main2">
                    {/* <span className="flag-img">
                      <FlagImage
                        index={
                          task?.priority ? parseInt(task?.priority, 10) - 1 : 3
                        }
                      />
                    </span> */}
                    <Dropdown menu={{ items }} placement="bottomRight" arrow>
                      <Button className="teams-menu-btn">
                        <CaretDownOutlined style={{ color: "#000000" }} />
                      </Button>
                    </Dropdown>
                    {task?.subtasks &&
                      task?.subtasks.length !== 0 &&
                      task?.subtasks[0]?.title !== "" && (
                        <>
                          {" "}
                          <DownOutlined
                            className={downOutlineClass}
                            onClick={() => handleSubtask(true)}
                          />
                          <UpOutlined
                            className={upOutlineClass}
                            onClick={() => handleSubtask(false)}
                          />
                        </>
                      )}
                  </div>
                </div>
                {isDownOutlinedClick === true && (
                  <div className="todo-subtask">
                    <ul>
                      {task.subtasks.length !== 0 &&
                        task.subtasks.map((subtask, index) => (
                          <>
                            {/* <Radio className="todo-radio"></Radio> */}
                            <li key={index}>{subtask.title}</li>
                          </>
                        ))}
                    </ul>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          {/* <img
            className="todo-task-btn"
            onClick={() => handleTaskModalOpen(true)}
            src={createBtn}
            alt=""
          /> */}
        </div>
      </TeamCardstyle>
      {/* {isTaskModalOpen === true && ( */}
      <TeamsCreateTaskDrawer
        drawerVisible={createDrawerVisible}
        onClose={onCreateCloseDrawer}
        membersList={membersList}
        teamId={teamIds}
      />

      <TeamsTaskView
        drawerVisible={drawerVisible}
        onClose={onCloseDrawer}
        Data={viewTaskData}
      />

      {isEditTaskOpen === true && (
        <TeamsCreateTaskDrawer
          drawerVisible={editDrawerVisible}
          onClose={onEditCloseDrawer}
          membersList={membersList}
          teamId={teamIds}
          editValues={isEditData}
        />
      )}
    </>
  );
}

export default TeamsTaskCards;
