import React, { useState } from "react";
import { Layout, Menu, Modal } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../backend/firebase";
import logo from "../../assets/navbar/logo.svg";
import createBtn from "../../assets/home/create-task.svg";
import { ReactComponent as ReacthomeIcon } from "../../assets/sidebar/newHome.svg";
import { ReactComponent as ReacttaskIcon } from "../../assets/sidebar/newTask.svg";
import { ReactComponent as ReactlogoutIcon } from "../../assets/sidebar/newLogout.svg";
import { ReactComponent as ReactcalendarIcon } from "../../assets/sidebar/newCalender.svg";
import { ReactComponent as ReactsettingsIcon } from "../../assets/sidebar/newSetting.svg";
// import { ReactComponent as ReactAiIcon } from "../../assets/sidebar/aiIcon.svg";
import { ReactComponent as ReactTeamsIcon } from "../../assets/sidebar/newTeams.svg";

import { SidebarStyle } from "./styles";
import ProfileDrawer from "../ProfileUpdate/profileDrawer";
import { logout } from "../../redux/actions/signOut.actions";
import CreateTask from "../Task/CreateTask";
// import TaskAiDrawer from "../Ai";
const { Sider } = Layout;
const { SubMenu } = Menu;

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [taskAiDrawerVisible, setTaskAiDrawerVisible] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  const handleMenuItemClick = (key) => {
    setSelectedKey(key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = (key) => {
    setSelectedKey(key);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  // const showTaskAiDrawer = (key) => {
  //   setSelectedKey(key);
  //   setTaskAiDrawerVisible(true);
  // };

  // const onCloseTaskAiDrawer = () => {
  //   setTaskAiDrawerVisible(false);
  // };

  const handleSignOut = async (key) => {
    setSelectedKey(key);
    Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to logout?",
      onOk: async () => {
        try {
          await dispatch(logout());
          await signOut(auth);
          navigate("/signin");
        } catch (err) {
          console.log(err);
        }
      },
    });
  };
  const handleHome = () => {
    navigate("/");
  };

  return (
    // <div style={{ position: 'fixed', left: 0, top: 0, bottom: 0 }}>
    <SidebarStyle>
      <Sider
        className="todo-sidebar"
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
        style={{
          backgroundColor: "#fff",
          width: collapsed ? 80 : 200,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          className="logo"
          onClick={handleHome}
          style={{ cursor: "pointer" }}
        >
          <img src={logo} alt="Logo" />
        </div>
        <Menu
          theme="light"
          mode="inline"
          className="sidebarHead"
          selectedKeys={[selectedKey]}
        >

          <Menu.Item
            key="/"
            icon={
              <ReacttaskIcon
                className={selectedKey == "/" ? "selected" : ""}
                style={{ width: selectedKey === "/" ? "50%" : "100%" }}
              />
            }
            onClick={() => handleMenuItemClick("/")}
          >
            <NavLink to="/">Dashboard</NavLink>
          </Menu.Item>

          <Menu.Item
            key="/task"
            icon={
              <ReacthomeIcon
                className={selectedKey == "/task" ? "selected" : ""}
              />
            }
            onClick={() => handleMenuItemClick("/task")}
          >
            <NavLink to="/task">Task</NavLink>
          </Menu.Item>
         

          <Menu.Item
            key="/teams"
            icon={
              <ReactTeamsIcon
                className={selectedKey == "/teams" ? "selected" : ""}
                style={{ width: selectedKey === "/" ? "50%" : "100%" }}
              />
            }
            onClick={() => handleMenuItemClick("/teams")}
          >
            <NavLink to="/teams">Teams</NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key="/calendar"
            icon={<ReactcalendarIcon className={selectedKey == "/calendar" ? "selected" : ""} />}
            onClick={() => handleMenuItemClick("/calendar")}
          >
             Calendar
          </Menu.Item> */}
          <Menu.Item
            key="/settings"
            icon={
              <ReactsettingsIcon
                className={selectedKey == "/settings" ? "selected" : ""}
              />
            }
            onClick={() => showDrawer("/settings")}
          >
            Settings
          </Menu.Item>

          {/* <Menu.Item
            key="/aiTask"
            icon={
              <ReactAiIcon
                className={selectedKey == "/aiTask" ? "selected" : ""}
              />
            }
            onClick={() => showTaskAiDrawer("/aiTask")}
          >
            Ask AI
          </Menu.Item> */}

          <Menu.Item
            key="/logout"
            icon={
              <ReactlogoutIcon
                className={selectedKey == "/logout" ? "selected" : ""}
              />
            }
            onClick={() => handleSignOut("/logout")}
          >
            Logout
          </Menu.Item>
          <Menu.Item
            key="create-btn"
            style={{ height: "60px" }}
            // icon={<ReactlogoutIcon  onClick={handleSignOut} />}
            icon={
              <img
                className="todo-task-btn"
                onClick={() => handleTaskModalOpen(true)}
                src={createBtn}
                alt=""
              />
            }
            // onClick={() => handleMenuItemClick("/logout")}
          >
            Create Task
          </Menu.Item>
        </Menu>
      </Sider>

      {isTaskModalOpen === true && (
        <CreateTask
          handleTaskModalOpen={handleTaskModalOpen}
          isTaskModalSet=""
        />
      )}
      <ProfileDrawer drawerVisible={drawerVisible} onClose={onCloseDrawer} />
      {/* <TaskAiDrawer drawerVisible={taskAiDrawerVisible} onClose={onCloseTaskAiDrawer} /> */}
    </SidebarStyle>
    // </div>
  );
}

export default Sidebar;
