import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Authentication from "./containers/Authentication";
import { auth } from "./backend/firebase";
import { Task } from "./containers/Task";
import { SignUpPage } from "./containers/Authentication/signup";
import { SigninPage } from "./containers/Authentication/signin";
import { useDispatch, useSelector } from "react-redux";
import { fetchPriorityOptionsAsync } from "./redux/actions/priority.actions";
import useNotifications from "./components/Notification/index";
import { fetchNotificationsAsync } from "./redux/actions/notification.actions";
import Notification from "./containers/Notification";
import TaskView from "./containers/TaskView";
import Profile from "./containers/Profile";
import Dashboard from "./containers/Dashboard";
import TeamsMain from "./containers/Teams";
import InvitationsMain from "./containers/Invitations";
import TeamTaskList from "./components/Teams/Tasks";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const currentUser = useSelector((state) => state.auth?.user);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        if (!user && location.pathname === "/") {
          navigate("/signin");
        } else if (
          location.pathname !== "/signup" &&
          location.pathname !== "/signin"
        ) {
          navigate("/authentication");
        }
      }
    });
    if (currentUser?.user_id != "" && currentUser?.user_id != undefined) {
      dispatch(fetchPriorityOptionsAsync());
      dispatch(fetchNotificationsAsync(currentUser?.user_id));
      notifications.requestNotificationPermission();
      notifications.getNotificationToken();
      notifications.subscribeToMessages();
    }
  }, [dispatch, currentUser]);

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/task" element={<Task />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/invitations/:teamId/:memberId" element={<InvitationsMain />} />
      <Route path="/teams" element={<TeamsMain />} />
      <Route path="/teams/:teamId/tasks" element={<TeamTaskList />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/task-view/:taskId" element={<TaskView />} />
      <Route path="/authentication" element={<Authentication />} />
      <Route path="/signin" element={<SigninPage />} />
      <Route path="/signup" element={<SignUpPage />} />
    </Routes>
  );
}

export default App;
