import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../backend/firebase";

export const fetchPriorityOptionsAsync = createAsyncThunk(
  "priority/fetchPriorityOptionsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const priorityRef = collection(db, "priority");
      const prioritySnapshot = await getDocs(priorityRef);

      return prioritySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching priority options:", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
