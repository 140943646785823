import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Card, Spin } from "antd";
import { fetchTaskId } from "../../../redux/actions/task.actions";
import FlagImage from "../../../components/FlagImages";
import { TaskViewStyle } from "./styles";

function TaskView(Data) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );
  const [taskData, setTaskData] = useState(null);

  const formatDate = (timestamp) => {
    const momentTimestamp = moment(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    if (momentTimestamp.isSame(moment(), "day")) {
      return `Today at ${momentTimestamp.format("h:mm A")}`;
    } else if (momentTimestamp.isSame(moment().subtract(1, "days"), "day")) {
      return `Yesterday at ${momentTimestamp.format("h:mm A")}`;
    } else {
      return momentTimestamp.format("MMM D, YYYY [at] h:mm A");
    }
  };

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        setIsLoading(true);
        const getData = await dispatch(fetchTaskId(Data.taskId));
        setTaskData(getData.payload);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching task data:", error);
        setIsLoading(false);
      }
    };

    fetchTaskData();
  }, [Data]);

  return (
    <TaskViewStyle>
      <div className="main-taskView">
        {<div className="spinner">{isLoading && <Spin size="small" />}</div>}
        {!isLoading && taskData && (
          <Card className="task-card">
            <h2 className="title">{taskData.title}</h2>
            {priorityOptions?.map((priority) => (
              <div key={priority.id} value={priority.id}>
                {taskData.priorityId === priority.id ? (
                  <p>
                    <FlagImage index={priority.id} select="true" />{" "}
                    <span className="periority"> {priority.name} </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            ))}
            {taskData.priorityId === "" ? (
              <p>
                <FlagImage index={4} select="true" />{" "}
                <span className="periority"> No Priority</span>
              </p>
            ) : (
              ""
            )}
            <p className="description">{taskData.description}</p>
            <p className="date">{formatDate(taskData.dueDate)}</p>
            <p className="status">
              {taskData.status === "Pending" ? (
                <span style={{ color: "#F43F3F" }}>Task Not Completed</span>
              ) : (
                <span style={{ color: "#3DD193" }}>Task Completed</span>
              )}
            </p>
          </Card>
        )}
      </div>
    </TaskViewStyle>
  );
}

export default TaskView;
