import styled from "styled-components";

export const ProfileUpdateStyle = styled.div`
  .profileUpdate-card-main {
    h4 {
      cursor: pointer;
      float: right;
      font-weight: 500;
      font-size: 16px;
      color: #212121;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      color: #757575;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .submit-button span {
      display: flex;
      justify-content: space-around;
    }
  }
`;

export const ImageUploadStyle = styled.div`
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    border-radius: 10%;
    width: auto;
    height: auto;
    position: relative;
    top: 70px;
    border: navajowhite;
    background: gray;
    border-radius: 50%;
    margin-left: -30px;
  }

  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-thumbnail {
    object-fit: fill !important;
  }

  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-thumbnail,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-thumbnail,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail
    img,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item-thumbnail
    img,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-thumbnail
    img,
  :where(
      .css-dev-only-do-not-override-dkbvqv
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    .ant-upload-list-item-thumbnail
    img {
    object-fit: fill !important;
  }

  .ant-upload-list-item-actions {
    display: none;
  }
  .ant-upload-picture-circle-wrapper {
    text-align: center;
  }

  :where(.css-dev-only-do-not-override-dkbvqv).ant-btn-default {
    background: #877f7f;
    border-radius: 50%;
    border-color: #d9d9d900;
  }

  // PROD

  :where(.css-dkbvqv).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    border-radius: 10%;
    width: auto;
    height: auto;
    position: relative;
    top: 70px;
    border: navajowhite;
    background: gray;
    border-radius: 50%;
    margin-left: -30px;
  }

  :where(.css-dkbvqv).ant-btn.ant-btn-icon-only {
    background: #877f7f;
    border-radius: 50%;
    border-color: #d9d9d900;
  }

  @media only screen and (max-width: 768px) {
    :where(.css-dkbvqv).ant-upload-wrapper.ant-upload-picture-circle-wrapper
      .ant-upload.ant-upload-select {
      border-radius: 10%;
      width: auto;
      height: auto;
      position: absolute;
      top: 206px;
      border: navajowhite;
      background: gray;
      border-radius: 50%;
      margin-left: -30px;
    }

    :where(
        .css-dev-only-do-not-override-dkbvqv
      ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
      .ant-upload.ant-upload-select {
      border-radius: 10%;
      width: auto;
      height: auto;
      position: absolute;
      top: 206px;
      border: navajowhite;
      background: gray;
      border-radius: 50%;
      margin-left: -30px;
    }
  }
`;
