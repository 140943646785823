import React, { useState } from "react";
import { LoginButtons, OtherLogins, PageHeading, SignupStyle } from "./styles";
import { Button, Form, Input, notification, Row, Col } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../backend/firebase";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/google.svg";
import MailIcon from "../../assets/emailIcon.svg";
import PasswordIcon from "../../assets/lockIcon.svg";
import UserIcon from "../../assets/user.svg";
import { useDispatch } from "react-redux";
import { googleLogin, signUp } from "../../redux/actions/auth.thunks";
import useNotifications from "../../components/Notification/index";
import Img1 from "../../assets/auth/img1.svg";
import Img2 from "../../assets/auth/img2.svg";
import logoImage from "../../assets/navbar/logo.svg";

export const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const [registerInformation, setRegisterInformation] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [api, contextHolder] = notification.useNotification();

  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerInformation.email,
        registerInformation.password
      );
      const user = userCredential.user;
      const dbRef = doc(db, "users", `${auth.currentUser.uid}`);
      await setDoc(dbRef, {
        name: registerInformation.name,
        email: user.email,
        user_id: user.uid,
        tokens: [],
      });

      await dispatch(signUp({ user_id: user.uid }));
      const token = await notifications.getNotificationToken();
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        let tokens = userData.tokens || []; // Get existing tokens or initialize empty array
        tokens = Array.isArray(tokens) ? tokens : [tokens]; // Ensure tokens is an array
        if (!tokens.includes(token)) {
          tokens.push(token); // Add token if it doesn't already exist
          await updateDoc(userDocRef, { tokens }); // Update the document with the new tokens array
          // console.log("Token added to user document:", token);
        } else {
          // console.log("Token already exists in user document.");
        }
      }
      navigate("/");
    } catch (error) {
      console.log(error.code);
      if (error.code === "auth/email-already-in-use") {
        api.open({
          message: "The email address is already in use",
          description: null,
        });
      } else if (error.code === "auth/invalid-email") {
        api.open({
          message: "Invalid Email",
          description: null,
        });
      } else if (error.code === "auth/weak-password") {
        api.open({
          message:
            "The password is too weak. Please choose a stronger password.",
          description: null,
        });
      }
    }
  };

  const googleAuthLogin = async () => {
    await dispatch(googleLogin());
    await notifications.getNotificationToken();
    navigate("/");
  };

  return (
    <SignupStyle>
      {contextHolder}
      <Row className="main-row">
        <Col md={10} lg={10} xl={10} className="leftside-pic">
          <img src={Img1} alt="" />
        </Col>
        <Col md={6} lg={6} xl={6} className="todo-main">
          <div className="todo-signup">
            <img src={logoImage} alt="" className="rightside-pic" />
            <PageHeading>Sign Up</PageHeading>
            <Form
              name="basic"
              layout="vertical"
              className="todo-form"
              style={{
                width: "100%",
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={handleRegister}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    type: "text",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  prefix={<img alt="user" src={UserIcon} />}
                  value={registerInformation.name}
                  onChange={(e) =>
                    setRegisterInformation({
                      ...registerInformation,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<img alt="email" src={MailIcon} />}
                  value={registerInformation.email}
                  onChange={(e) =>
                    setRegisterInformation({
                      ...registerInformation,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    type: "password",
                  },
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Password"
                  prefix={<img alt="password" src={PasswordIcon} />}
                  value={registerInformation.password}
                  onChange={(e) =>
                    setRegisterInformation({
                      ...registerInformation,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    type: "password",
                  },
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  prefix={<img alt="password" src={PasswordIcon} />}
                  value={registerInformation.password}
                  // onChange={(e) =>
                  //   setRegisterInformation({
                  //     ...registerInformation,
                  //     password: e.target.value,
                  //   })
                  // }
                />
              </Form.Item>

              <Form.Item
                className="todo-ant-button"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button className="primaryBtn" type="primary" htmlType="submit">
                  Sign Up
                </Button>
              </Form.Item>
              {/* <p className="todo-have-acc">
              Have an account already?
              <span
                className="todo-haveacc-span"
                onClick={() => navigate("/signin")}
              >
                Sign In
              </span>{" "}
            </p> */}
            </Form>
            <OtherLogins>
              <span className="seperator">Or Login with</span>
              <LoginButtons>
                <Button onClick={googleAuthLogin}>
                  <img alt="google" src={GoogleIcon} />
                </Button>
              </LoginButtons>
            </OtherLogins>
          </div>
        </Col>
        <img src={Img2} alt="" className="bottom-pic" />
      </Row>
    </SignupStyle>
  );
};
