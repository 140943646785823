import React, { useState } from "react";
import { AuthContainer, SignupStyle } from "./styles";
import { Button, Form, Input } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import {
  signInWithEmailAndPassword
} from "firebase/auth";
import { auth } from "../../backend/firebase";
import { useNavigate } from "react-router-dom";
import AuthImg from "../../assets/authImg.png"

function Authentication() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/");
      })
      .catch((err) => alert(err.message));
  };

  return (
    <AuthContainer>
      <div className="wrapper">
        <div className="bannerImg">
          <img alt="authentication" src={AuthImg} />
        </div>
        <div className="btnGroup">
          <Button type="primary" className="primaryBtn" onClick={() => navigate("/signin")}>Login</Button>
          <Button className="outlinedBtn" onClick={() => navigate("/signup")}>Sign up</Button>
        </div>
      </div>
    </AuthContainer>
  );
}

export default Authentication;
