import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Drawer,
  Select,
  Divider,
  Switch,
  message,
  Menu,
  Dropdown,
  Modal,
  Calendar,
  Popover,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import {
  PushpinOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import FlagImage from "../../FlagImages";
import menu from "../../../assets/home/create-task/menu.svg";
import subtask from "../../../assets/home/create-task/tabler_subtask.svg";
import duplicate from "../../../assets/home/create-task/duplicate_icon.svg";
import deleteIcon from "../../../assets/home/create-task/delete.svg";

import timer from "../../../assets/home/create-task/timer.svg";
import redFlag from "../../../assets/home/red-flag.svg";
import yellowFlag from "../../../assets/home/yellow-flag.svg";
import greenFlag from "../../../assets/home/green-flag.svg";
import blackFlag from "../../../assets/home/black-flag.svg";
import flag from "../../../assets/home/create-task/flag.svg";
import send from "../../../assets/home/create-task/send.svg";
import backBtn from "../../../assets/home/create-task/back.svg";

import { useNavigate, useParams } from "react-router-dom";
import {
  addTask,
  deleteTeamTask,
  teamsDuplicateTask,
  updateTask,
} from "../../../redux/actions/teams.actions";
import expand from "../../../assets/home/create-task/expand.svg";
import { AddTask, CreateTeamsTaskStyle } from "./styles";
const { TextArea } = Input;
const { Option } = Select;

function TeamsCreateTaskDrawer({
  drawerVisible,
  onClose,
  membersList,
  teamId,
  editValues,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [pinValue, setPinValue] = useState(false); // Initialize pinValue as false
  const [teamIds, setTeamId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDates, setSelectedDates] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDatesValues, setSelectedDateValues] = useState("");
  const [taskDate, setTaskDate] = useState("Today");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [assignto, setAssignto] = useState("");

  const [taskCalendarView, setTaskCalendarView] = useState(false);
  const [clockClass, setClockClass] = useState("");
  const [flagClass, setFlagClass] = useState("");
  const [taskTimeView, setTaskTimeView] = useState(false);
  const [priorityClicked, setPriorityClicked] = useState(false);
  const [isSubtaskOpen, setIsSubtaskOpen] = useState(false);
  const [priority, setPriority] = useState(isEdit?.priority || null);

  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );
  const currentUser = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dueDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")} `,
    time: null,
    priority: null,
    pin: false,
    assignTo: "",
    subtasks: [],
    createdBy: currentUser?.name,
  });

  const onEditFinish = async (values) => {
    values.assignTo = assignto;
    // values.push(assignTo: assignTo)
    setIsLoading(true);
    await dispatch(
      updateTask({
        teamId: teamIds,
        taskId: editValues?.id,
        payloadData: values,
        selectedDate: selectedDates,
        selectedTime: selectedTime,
        dateCheck: selectedDates ? "" : editValues.dueDate,
        timeCheck: selectedTime ? "" : editValues.time,
      })
    );
    message.success({ content: "Team update successfully", key: "loading" });
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    const currentDate = new Date();
    const options = { weekday: "short" };
    const dayString = currentDate.toLocaleDateString("en-US", options);
    setSelectedDateValues(dayString);
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    // message.loading({ content: "Loading...", key: "loading" });
    await dispatch(
      addTask({
        teamId: teamIds,
        payloadData: values,
        selectedDates: selectedDates,
        selectedTime: selectedTime,
      })
    );
    message.success({ content: "Task added successfully" });
    form.setFieldsValue({
      title: "",
      description: "",
      dueDate: null,
      time: null,
      priority: null,
      pin: false,
      assignTo: "",
      createdBy: "",
      subtasks: [],
    });
    setPriority(null);
    setSelectedDateValues("");
    setPinValue(false);
    setIsFormDirty(false);
    setIsLoading(false);
    onClose();
  };

  const onValuesChange = () => {
    setIsFormDirty(true);
  };

  const handleFormChange = (changedValues, allValues) => {
    if ("pin" in changedValues) {
      setPinValue(changedValues.pin);
    }
    setFormData(allValues);
  };

  const handleDateChange = (date) => {
    if (!date) return;
    const selectedDate = new Date(date);
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const currentDate = new Date();
    const selectedWeekStart = moment(selectedDate).startOf("week");
    const currentWeekStart = moment(currentDate).startOf("week");
    const isThisWeek = selectedWeekStart.isSame(currentWeekStart, "day");
    let formattedDatess;
    if (isThisWeek) {
      formattedDatess = selectedDate.toLocaleDateString("en-US", {
        weekday: "short",
      });
    } else {
      formattedDatess = selectedDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    }
    //  const selectedUnixTimestamp = date.unix();
    // form.setFieldsValue({
    //   dueDate: {
    //     seconds: selectedUnixTimestamp,
    //     nanoseconds: 0, // Assuming nanoseconds should be 0
    //   },
    // });

    setFormData({
      ...formData,
      dueDate: date ? date.format("YYYY-MM-DD") : null,
    });

    setSelectedDateValues(formattedDatess);
  };

  const handleDateTime = () => {
    setTaskCalendarView(false);
    setTaskTimeView(true);
  };

  const handleTimeChange = (time) => {
    const utcTime = new Date(time);
    const localTime = utcTime.toLocaleTimeString();
    setSelectedTime(localTime);
    setFormData({
      ...formData,
      time: time ? time.format("HH:mm:ss") : null,
    });
  };

  const handlePriorityClickChange = (open) => {
    setPriorityClicked(open);
  };

  const handlePrioritySetChange = (value) => {
    setPriority(value);
    form.setFieldsValue({
      priority: value,
    });
    // setFormData({ ...formData, priority: value });
    setFlagClass("flag-true");
    setPriorityClicked(false);
  };

  const handlePriorityChange = (value) => {
    setPriorityClicked(true);
    setPriority(value);
  };

  const handlePinChange = (checked) => {
    setPinValue(!pinValue);
    form.setFieldsValue({
      pin: !pinValue,
    });
  };
  const handleAddSubtask = () => {
    // Make sure formData is initialized, or handle the case when it's not.
    setFormData((prevFormData) => ({
      ...prevFormData,
      subtasks: [...prevFormData.subtasks, { title: "", description: "" }],
    }));
  };
  const handleRemoveSubtask = (index) => {
    const newSubtasks = [...formData.subtasks];
    newSubtasks.splice(index, 1);
    setFormData({
      ...formData,
      subtasks: newSubtasks,
    });
  };

  const handleModalClose = () => {
    setIsTaskModalOpen(false);
  };

  const handleBackModalClose = () => {
    setIsTaskModalOpen(false);
    onClose();
    // handleTaskModalOpen(false);
  };
  const handleTaskPopover = () => {
    setIsSubtaskOpen(true);
    // setIsSubtaskPopoverOpen(false);
  };

  const handleDuplicate = async (teamIds, task) => {
    message.loading({ content: "Loading", key: "loading" });
    onClose();
    await dispatch(teamsDuplicateTask({ teamId: teamIds, taskId: task.id }));
    message.success({ content: "Task added successfuly", key: "loading" });
  };

  const handleDelete = (teamIds, task) => {
    onClose();
    Modal.confirm({
      title: "Delete",
      content: "Are you sure you want to delete this task?",
      onOk: async () => {
        try {
          message.loading({ content: "Loading", key: "loading" });
          await dispatch(deleteTeamTask({ teamId: teamIds, taskId: task.id }));
          message.success({
            content: "Task deleted successfuly",
            key: "loading",
          });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };

  const handleAssignToChange = (val) => {
    setAssignto(val);
  };

  const mobileMenuItems = (
    <Menu>
      <Menu.Item key="subtask" onClick={handleTaskPopover}>
        <img
          src={subtask}
          alt=""
          style={{ marginRight: "8px", width: "16px" }}
        />
        Add Subtask
      </Menu.Item>

      {isEdit && (
        <>
          <Menu.Item
            key="duplicate"
            onClick={() => handleDuplicate(teamIds, editValues)}
          >
            <img
              src={duplicate}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Duplicate
          </Menu.Item>
          <Menu.Item
            key="delete"
            onClick={() => handleDelete(teamIds, editValues)}
          >
            <img
              src={deleteIcon}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Delete
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  useEffect(() => {
    if (teamId) {
      setTeamId(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    if (editValues) {
      form.setFieldsValue(editValues);
      setAssignto(editValues.assignTo);
      setIsEdit(true);
      if (editValues.subtasks) {
        setIsSubtaskOpen(true);
      }
      if (editValues.dueDate) {
        const selectedDate = new Date(editValues.dueDate);
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        const currentDate = new Date();
        const selectedWeekStart = moment(selectedDate).startOf("week");
        const currentWeekStart = moment(currentDate).startOf("week");
        const isThisWeek = selectedWeekStart.isSame(currentWeekStart, "day");
        let formattedDatess;
        if (isThisWeek) {
          formattedDatess = selectedDate.toLocaleDateString("en-US", {
            weekday: "short",
          });
        } else {
          formattedDatess = selectedDate.toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          });
        }
        setSelectedDateValues(formattedDatess);
      }
      if (editValues.priority) {
        if (Number.isInteger(editValues.priority)) {
          // If it's an integer, convert it to a string
          const priorityAsString = editValues.priority.toString();
          setPriority(priorityAsString);
          form.setFieldsValue({
            priority: priorityAsString,
          });
        } else {
          setPriority(editValues.priority);
          form.setFieldsValue({
            priority: editValues.priority,
          });
        }
      }
      if (editValues.assignTo) {
        form.setFieldsValue({
          assignTo: editValues.assignTo,
        });
      }

      setPinValue(editValues.pin);

      // handleDateChange(editValues.dueDate)
    }
  }, [drawerVisible]);

  return (
    <CreateTeamsTaskStyle>
      <Drawer
        title={
          <div
            style={{
              fontWeight: 600,
              fontSize: "24px",
              textAlign: "center",
              color: "#000",
            }}
          >
            {isExpanded ? (
              <img
                style={{ width: "30px" }}
                onClick={handleBackModalClose}
                src={backBtn}
                alt=""
              />
            ) : (
              <span className="create-task-title">
                {" "}
                {isEdit ? "Edit" : "Add"} Task
              </span>
            )}
            {/* {!isExpanded && (isEdit ? "Edit Task" : "Create Task")} */}
            {isMobile && (
              <span className="create-task-icons">
                <Dropdown
                  overlay={mobileMenuItems}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <img src={menu} alt="Menu" />
                </Dropdown>
                <img
                  onClick={() => setIsExpanded(!isExpanded)}
                  src={expand}
                  alt=""
                />
              </span>
            )}
          </div>
        }
        placement={isMobile ? "bottom" : "right"}
        width={isMobile ? "100%" : 378}
        height="100vh"
        style={{
          height:
            isMobile && isExpanded
              ? "89vh"
              : isMobile && !isExpanded
              ? "310px"
              : !isMobile
              ? "100vh"
              : "",
        }}
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        className="task-drawer"
      >
        {isExpanded && (
          <label className="create-task-title">
            {isEdit ? "Edit" : "Add"} Task
          </label>
        )}
        <AddTask>
          <Form
            form={form}
            layout="vertical"
            initialValues={formData}
            onValuesChange={handleFormChange}
            onFinish={isEdit ? onEditFinish : onFinish}
            className="teams-task-form"
          >
            <Form.Item name="createdBy" initialValue={currentUser?.name} hidden>
            <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="title"
              label={!isMobile && <span className="custom-label">Title</span>}
              rules={[{ required: true, message: "Please enter a title" }]}
              className="customlabel"
            >
              <Input placeholder="Title" maxLength={100} />
            </Form.Item>

            <Form.Item name="description">
              <TextArea
                placeholder="Description"
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Form.Item>
            {isSubtaskOpen && formData && (
              <Form.List name="subtasks">
                {(fields, { add, remove }) => (
                  <>
                    <label className="subtask-label custom-label">
                      Subtasks
                    </label>
                    {formData?.subtasks &&
                      fields?.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            initialValue=""
                          >
                            <Input placeholder="Subtask Title" />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "description"]}
                            fieldKey={[field.fieldKey, "description"]}
                            initialValue=""
                          >
                            <TextArea
                              placeholder="Subtask Description"
                              autoSize={{ minRows: 3, maxRows: 6 }}
                            />
                          </Form.Item>
                          <Button
                            style={{ marginBottom: "15px", float: "right" }}
                            type="primary"
                            danger
                            onClick={() => {
                              remove(field.name);
                              handleRemoveSubtask(index);
                            }}
                          >
                            Remove
                          </Button>
                          <Divider />
                        </div>
                      ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        ghost
                        style={{ marginBottom: "15px", float: "right" }}
                        onClick={() => {
                          add({ title: "", description: "" });
                          handleAddSubtask();
                        }}
                        // block
                        icon={<PlusOutlined />}
                      >
                        Add Subtask
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
            {(isExpanded || !isMobile) && (
              <>
                <Form.Item
                  name="dueDate"
                  label={<span className="custom-label">Date</span>}
                  style={{ display: "flex", gap: "10px" }}
                >
                  {editValues ? (
                    <>
                      <DatePicker
                        defaultValue={dayjs(editValues.dueDate, "YYYY-MM-DD")}
                        onChange={handleDateChange}
                      />
                      <TimePicker
                        defaultValue={dayjs(editValues.time, "HH:mm:ss")}
                        onChange={handleTimeChange}
                        style={{ marginLeft: "10px" }}
                      />
                    </>
                  ) : (
                    <>
                      <DatePicker
                        value={
                          formData.dueDate
                            ? dayjs(formData.dueDate, "YYYY-MM-DD")
                            : null
                        }
                        onChange={handleDateChange}
                      />
                      {/* <TimePicker defaultValue = {dayjs(formData.time, "HH:mm:ss")} onChange={handleTimeChange} /> */}
                      <TimePicker
                        value={
                          formData.time
                            ? moment(formData.time, "HH:mm:ss")
                            : null
                        }
                        onChange={handleTimeChange}
                        style={{ marginLeft: "10px" }}
                      />
                    </>
                    //   <>
                    //   <DatePicker onChange={handleDateChange} />
                    //   <TimePicker onChange={handleTimeChange} />
                    // </>
                  )}
                </Form.Item>

                <Form.Item
                  name="priority"
                  label={<span className="custom-label">Priority</span>}
                >
                  <Select
                    placeholder="Select priority"
                    style={{ width: 200 }}
                    onChange={handlePriorityChange}
                  >
                    {priorityOptions?.map((priority) => (
                      <Option key={priority.id} value={priority.id}>
                        <FlagImage index={priority.id} select="true" />{" "}
                        {priority.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {!isMobile && (
                  <Form.Item
                    name="pin"
                    label={<span className="custom-label">Pin</span>}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <Switch
                        checked={pinValue}
                        onChange={handlePinChange}
                        checkedChildren={
                          <PushpinOutlined style={{ color: "#ffffff" }} />
                        }
                        unCheckedChildren={<PushpinOutlined />}
                        style={{
                          backgroundColor: pinValue ? "#0077d7" : "",
                          borderRadius: "20px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  </Form.Item>
                )}
                <Form.Item
                  name="assignTo"
                  label={<span className="custom-label">Assign To</span>}
                >
                  <Select
                    placeholder="Select User"
                    style={{ width: 200 }}
                    onChange={handleAssignToChange}
                  >
                    {membersList?.map((i) =>
                      i.user_id ? (
                        <Option key={i.id} value={i?.userInfo?.userId}>
                          <FlagImage index={i.id} select="true" /> {i.email}
                        </Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </>
            )}
            {isMobile && (
              <div
                className="taskset-container"
                style={{ marginTop: isExpanded ? "15.5vh" : "40px" }}
              >
                <span className="taskset-icons">
                  {!isExpanded ? (
                    <>
                      <Form.Item name="dueDate">
                        <img
                          src={timer}
                          onClick={() => setTaskCalendarView(true)}
                          className={clockClass}
                          alt=""
                        />
                        {selectedDatesValues ? (
                          <pre style={{ color: "#0077D7" }}>
                            {selectedDatesValues}
                          </pre>
                        ) : (
                          ""
                        )}
                        <Modal
                          open={taskCalendarView}
                          onOk={handleDateTime}
                          onCancel={() => setTaskCalendarView(false)}
                          okText="Choose Time"
                          cancelText="Cancel"
                          className="todo-date-modal"
                        >
                          {/* <Calendar
                            fullscreen={false}
                            value={
                              formData.dueDate
                                ? dayjs(
                                    moment
                                      .unix(formData.dueDate.seconds)
                                      .format("YYYY/MM/DD")
                                  )
                                : null
                            }
                            onChange={(date) => handleDateChange(date)}
                          /> */}
                          <Calendar
                            fullscreen={false}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </Modal>
                        <Modal
                          className="todo-time-modal"
                          open={taskTimeView}
                          onOk={() => setTaskTimeView(false)}
                          onCancel={() => setTaskTimeView(false)}
                          okText="Save"
                          cancelText="Cancel"
                          footer={null}
                        >
                          <TimePicker
                            onChange={(time) => handleTimeChange(time)}
                            onOk={() => setTaskTimeView(false)}
                            // inline
                          />
                        </Modal>
                      </Form.Item>
                      <Form.Item name="priority">
                        <Popover
                          placement="top"
                          trigger="click"
                          open={priorityClicked}
                          onOpenChange={handlePriorityClickChange}
                          content={priorityOptions?.map((priority, index) => (
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handlePrioritySetChange(priority.id)
                              }
                              className="priority-option"
                              key={priority.id}
                              value={priority.id}
                            >
                              <FlagImage index={index} /> {priority.name}
                            </li>
                          ))}
                        >
                          {priority == 1 ? (
                            <img
                              src={redFlag}
                              className={flagClass}
                              onClick={() => setPriorityClicked(true)}
                              alt=""
                            />
                          ) : priority == 2 ? (
                            <img
                              src={yellowFlag}
                              className={flagClass}
                              onClick={() => setPriorityClicked(true)}
                              alt=""
                            />
                          ) : priority == 3 ? (
                            <img
                              src={greenFlag}
                              className={flagClass}
                              onClick={() => setPriorityClicked(true)}
                              alt=""
                            />
                          ) : priority == 4 ? (
                            <img
                              src={blackFlag}
                              className={flagClass}
                              onClick={() => setPriorityClicked(true)}
                              alt=""
                            />
                          ) : (
                            <img
                              src={flag}
                              className={flagClass}
                              onClick={() => setPriorityClicked(true)}
                              alt=""
                            />
                          )}
                        </Popover>
                      </Form.Item>
                    </>
                  ) : null}
                  <Form.Item name="pin">
                    <label className="pin-checkbox">
                      <span
                        className={`pin-icon ${pinValue ? "checked" : ""}`}
                        onClick={handlePinChange}
                      >
                        <PushpinOutlined
                          onClick={handlePinChange}
                          style={{ color: pinValue ? "#0077d7" : "" }}
                        />
                      </span>
                    </label>
                  </Form.Item>
                </span>

                <span>
                  <Button htmlType="submit">
                    <img className="mob-send-btn" src={send} alt="" />
                  </Button>
                </span>
              </div>
            )}
            {isExpanded || (!isMobile && <Divider />)}
            {!isMobile && (
              <>
                {!isEdit && (
                  <Form.List name="subtasks">
                    {(fields, { add, remove }) => (
                      <>
                        <label className="subtask-label custom-label">
                          Subtasks
                        </label>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Form.Item
                              name={[field.name, "title"]}
                              fieldKey={[field.fieldKey, "title"]}
                              initialValue=""
                            >
                              <Input placeholder="Subtask Title" />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "description"]}
                              fieldKey={[field.fieldKey, "description"]}
                              initialValue=""
                            >
                              <TextArea
                                placeholder="Subtask Description"
                                autoSize={{ minRows: 3, maxRows: 6 }}
                              />
                            </Form.Item>
                            <Button
                              style={{ marginBottom: "15px", float: "right" }}
                              type="primary"
                              danger
                              onClick={() => {
                                remove(field.name);
                                handleRemoveSubtask(index);
                              }}
                            >
                              Remove
                            </Button>
                            <Divider />
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            type="primary"
                            ghost
                            style={{ marginBottom: "15px", float: "right" }}
                            onClick={() => {
                              add({ title: "", description: "" });
                              handleAddSubtask();
                            }}
                            // block
                            icon={<PlusOutlined />}
                          >
                            Add Subtask
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}

                <Button
                  onClick={handleModalClose}
                  default
                  style={{ width: "100%", marginTop: "30px" }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{ width: "100%", marginTop: "15px" }}
                >
                  Submit
                </Button>
              </>
            )}
          </Form>
        </AddTask>
      </Drawer>
    </CreateTeamsTaskStyle>
  );
}

export default TeamsCreateTaskDrawer;
