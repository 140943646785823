import React, { useState, useEffect } from "react";
import { PushpinOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  Input,
  Modal,
  Popover,
  Select,
  message,
  Button,
  Drawer,
  TimePicker,
  DatePicker,
  Calendar,
  Divider,
  Dropdown,
  Menu,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { CreateTaskStyle } from "./styles";
import "../../../styles/global.css";
import FlagImage from "../../FlagImages";
import menu from "../../../assets/home/create-task/menu.svg";
import expand from "../../../assets/home/create-task/expand.svg";
import cancelIcon from "../../../assets/home/create-task/cancel.svg";
import subtask from "../../../assets/home/create-task/tabler_subtask.svg";
import duplicate from "../../../assets/home/create-task/duplicate_icon.svg";
import deleteIcon from "../../../assets/home/create-task/delete.svg";
import timer from "../../../assets/home/create-task/timer.svg";
import flag from "../../../assets/home/create-task/flag.svg";
import send from "../../../assets/home/create-task/send.svg";
import backBtn from "../../../assets/home/create-task/back.svg";
import taskView from "../../../assets/teams/carbon_task-view.svg";
import {
  addDuplicateTask,
  addTask,
  deleteTask,
  updateTask,
} from "../../../redux/actions/task.actions";
import redFlag from "../../../assets/home/red-flag.svg";
import yellowFlag from "../../../assets/home/yellow-flag.svg";
import greenFlag from "../../../assets/home/green-flag.svg";
import blackFlag from "../../../assets/home/black-flag.svg";
import ViewTask from "../ViewTask/ViewTask";
//import { useTaskDate } from "../../../containers/Task";

const { Option } = Select;

const taskTemplate = {
  id: "",
  title: "",
  description: "",
  dueDate: null,
  time: "",
  pin: false,
  priorityId: "",
  isUpdate: false,
  user_id: "",
};

const currentDate = dayjs().format("YYYY-MM-DD");

function CreateTask({
  handleTaskModalOpen,
  isTaskModalSet,
  dataFetch,
  globalDates,

}) {

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const priorityOptions = useSelector(
    (state) => state.priority.priorityOptions
  );

  const taskDate = useSelector((state) => state.tasks.globalDate)
  //const { taskDate, setTaskDate } = useTaskDate();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [subtasks1, setSubtasks1] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [priorityClicked, setPriorityClicked] = useState(false);
  const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);

  // const [messageApi, contextHolder] = message.useMessage();
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ ...taskTemplate });
  const [taskCalendarView, setTaskCalendarView] = useState(false);
  const [taskTimeView, setTaskTimeView] = useState(false);
  const [selectedDates, setSelectedDates] = useState("");
  const [selectedDatesValues, setSelectedDateValues] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [pinValue, setPinValue] = useState(false);
  // const [pinClass, setPinClass] = useState("");
  const [clockClass, setClockClass] = useState("");
  const [flagClass, setFlagClass] = useState("");
  const [subtaskTitle, setSubtaskTitle] = useState("");
  const [subtaskDescription, setSubtaskDescription] = useState("");
  // const [subtaskDescription, setSubtaskDescription] = useState("");
  const [isSubtaskOpen, setIsSubtaskOpen] = useState(false);
  // const [isSubtaskPopoverOpen, setIsSubtaskPopoverOpen] = useState(false);
  const [todoTaskDate, setTodoTaskDate] = useState(
    moment().format("YYYY/MM/DD")
  );
  const [isExpanded, setIsExpanded] = useState(false);
  // const [currentDate, setCurrentDate] = useState(moment());
  const [priority, setPriority] = useState(isTaskModalSet?.priorityId || null); // State to store selected priority
  // const dateFormat = "YYYY/MM/DD";

  const handlePriorityChange = (value) => {
    setFormData({ ...formData, priorityId: value });
    setPriority(value);
    setPriorityClicked(true);
  };

  useEffect(() => {
    if (isTaskModalSet.dueDate && isTaskModalSet.dueDate.seconds) {
      const milliseconds = isTaskModalSet.dueDate.seconds * 1000;
      const dt = new Date(milliseconds);
      const currentDate = new Date();
      const selectedWeekStart = moment(dt).startOf("week");
      const currentWeekStart = moment(currentDate).startOf("week");
      const isThisWeek = selectedWeekStart.isSame(currentWeekStart, "day");

      let formattedDatess;
      if (isThisWeek) {
        formattedDatess = dt.toLocaleDateString("en-US", {
          weekday: "short", // Abbreviated day of the week (e.g., Mon, Tue)
        });
      } else {
        formattedDatess = dt.toLocaleDateString("en-US", {
          month: "short", // Abbreviated month name (e.g., Jan, Feb)
          day: "2-digit", // Day of the month (e.g., 01, 02)
        });
      }
      setSelectedDateValues(formattedDatess);
    }

    if (globalDates && globalDates != "" && globalDates != undefined) {
      setSelectedDates(globalDates);
    }

    setIsTaskModalOpen(true);
    if (taskDate === "Today") {
      const currentDate = moment().format("YYYY/MM/DD");

      setTodoTaskDate(currentDate);
    }
    if (taskDate !== "Today") {
      const currentDate = moment(taskDate).format("YYYY/MM/DD");
      setTodoTaskDate(currentDate);
    }
    if (isTaskModalSet !== "") {
      setIsEdit(true);
      setFormData({
        id: isTaskModalSet.id,
        title: isTaskModalSet.title,
        description: isTaskModalSet.description,
        dueDate: isTaskModalSet.dueDate,
        time: isTaskModalSet.time,
        pin: isTaskModalSet.pin,
        priorityId: isTaskModalSet.priorityId,
        isUpdate: isTaskModalSet.isUpdate,
        user_id: isTaskModalSet.user_id,
        subtaskTitle:
          isTaskModalSet.subtasks && isTaskModalSet.subtasks.length !== 0
            ? isTaskModalSet.subtasks[0].title
            : "",
        subtaskId:
          isTaskModalSet.subtasks && isTaskModalSet.subtasks.length !== 0
            ? isTaskModalSet.subtasks[0].subtaskId
            : "",
      });
      if (isTaskModalSet.subtasks && isTaskModalSet.subtasks.length !== 0) {
        setIsSubtaskOpen(true);
        const subtasksData = isTaskModalSet.subtasks.map((subtask) => subtask);
        setSubtasks1(subtasksData);
      }
      if (isTaskModalSet.dueDate || isTaskModalSet.time) {
        setClockClass("clock-true");
      }
      if (isTaskModalSet.priorityId) {
        setFlagClass("flag-true");
      }
      if (isTaskModalSet.pin) {
        setPinValue(true);
      }
    }
  }, []);

  const updateSuccess = () => {
    setIsLoading(false);
    message.success({ content: "Task update successfuly", key: "loading" });
  };

  const handleTaskView = async () => {
    setTaskDrawerVisible(true);
    // handleCancel();

  };
  const onCloseTaskDrawer = () => {
    setTaskDrawerVisible(false);
  };

  const addSuccess = () => {
    message.success({ content: "Task added successfuly", key: "loading" });
  };

  const addLoading = () => {
    setIsLoading(true);
    // message.loading({ content: "Loading", key: "loading" });
  };

  const deleteSuccess = () => {
    setIsLoading(false);
    message.success({ content: "Task delete successfuly", key: "loading" });
  };

  const handlePriorityClickChange = (open) => {
    setPriorityClicked(open);
  };

  //************* New  ***********

  const validateForm = () => {
    if (!formData.title || !formData.description) {
      message.error("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const handleModalClose = () => {
    setIsTaskModalOpen(false);
    handleTaskModalOpen(false);
  };

  const addTodoTask = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      addLoading();
      const formDataWithSubtasks = {
        ...formData,
        subtasks: subtasks1,
      };
      await dispatch(
        addTask({
          formData: formDataWithSubtasks,
          selectedDates: selectedDates,
          selectedTime: selectedTime,
          user_id: currentUser?.user_id,
          subtaskTitle: subtaskTitle,
          FilterDate: todoTaskDate,
        })
      );
      setIsTaskModalOpen(false);
      handleTaskModalOpen(false);
      addSuccess();
      dataFetch();

      setFormData({
        id: "",
        title: "",
        description: "",
        dueDate: null,
        time: "",
        pin: false,
        priorityId: "",
        isUpdate: false,
        user_id: "",
      });
      setIsSubtaskOpen(false);
      setSubtaskTitle("");
      setSubtasks1([]);
    } catch (error) {}
  };

  const addDuplicateTodoTask = async () => {
    try {
      addLoading();
      await dispatch(
        addDuplicateTask({
          formData: isTaskModalSet,
          user_id: currentUser?.user_id,
          subtaskTitle:
            isTaskModalSet.subtasks && isTaskModalSet.subtasks.length !== 0
              ? isTaskModalSet.subtasks[0].title
              : "",
          FilterDate: todoTaskDate,
        })
      );
      setIsTaskModalOpen(false);
      handleTaskModalOpen(false);
      addSuccess();
      dataFetch();
      setFormData({
        id: "",
        title: "",
        description: "",
        dueDate: null,
        time: "",
        pin: false,
        priorityId: "",
        isUpdate: false,
        user_id: "",
      });
      setIsSubtaskOpen(false);
      setSubtaskTitle("");
    } catch (error) {}
  };

  const handleCancel = () => {
    setFormData({
      id: "",
      title: "",
      description: "",
      dueDate: null,
      time: "",
      pin: false,
      priorityId: "",
      user_id: "",
    });
    setIsTaskModalOpen(false);
    handleTaskModalOpen(false);
    setIsEdit(false);
  };

  const handleEditConfirm = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      addLoading();
      const formDataWithSubtasks = {
        ...formData,
        subtasks: subtasks1,
      };
      await dispatch(
        updateTask({
          formData: formDataWithSubtasks,
          selectedDates: selectedDates,
          selectedTime: selectedTime,
          user_id: currentUser?.user_id,
          taskId: formData.id,
          dateCheck: selectedDates ? "" : formData.dueDate,
          timeCheck: selectedTime ? "" : formData.dueDate,
          subtaskTitle: subtaskTitle,
          subtaskId: formData.subtaskId,
        })
      );
      setIsTaskModalOpen(false);
      handleTaskModalOpen(false);
      updateSuccess();
      setFormData({
        id: "",
        title: "",
        description: "",
        dueDate: null,
        time: "",
        pin: false,
        priorityId: "",
        user_id: "",
      });

      setIsEdit(false);
    } catch (err) {}
  };

  const handleDateChange = (date) => {
    setClockClass("clock-true");
    if (!date) return;
    const selectedDate = new Date(date);
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setSelectedDates(formattedDate);
    const currentDate = new Date();
    const selectedWeekStart = moment(selectedDate).startOf("week");
    const currentWeekStart = moment(currentDate).startOf("week");
    const isThisWeek = selectedWeekStart.isSame(currentWeekStart, "day");
    let formattedDatess;
    if (isThisWeek) {
      formattedDatess = selectedDate.toLocaleDateString("en-US", {
        weekday: "short",
      });
    } else {
      formattedDatess = selectedDate.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    }
    const selectedUnixTimestamp = date.unix();
    setFormData({
      ...formData,
      dueDate: {
        seconds: selectedUnixTimestamp,
        nanoseconds: 0, // Assuming nanoseconds should be 0
      },
    });

    setSelectedDateValues(formattedDatess);
  };

  const handleTimeChange = (time) => {
    const utcTime = new Date(time);
    const localTime = utcTime.toLocaleTimeString();
    setSelectedTime(localTime);
    setTaskTimeView(false);
  };

  const handleDateTime = () => {
    setTaskCalendarView(false);
    setTaskTimeView(true);
  };

  const handlePrioritySetChange = (value) => {
    setPriority(value);
    setFormData({ ...formData, priorityId: value });
    setFlagClass("flag-true");
    setPriorityClicked(false);
  };

  const handlePinChange = () => {
    setPinValue(!pinValue);
    // if (pinValue == true) {
    // setPinClass("pinned-true");
    // } else {
    // setPinClass("");
    // }
    setFormData({ ...formData, pin: !pinValue });
  };

  const handleDelete = async () => {
    setIsTaskModalOpen(false);
    handleTaskModalOpen(false);
    addLoading();
    await dispatch(deleteTask({ taskId: formData.id }));
    deleteSuccess();
  };

  const handleTaskPopover = () => {
    setIsSubtaskOpen(true);
    // setIsSubtaskPopoverOpen(false);
  };

  const handleTitleChange = (e) => {
    // Limiting the number of rows to 3
    const lines = e.target.value.split("\n").slice(0, 5);
    const truncatedValue = lines.join("\n");
    setFormData({ ...formData, title: truncatedValue });
  };

  const handleAddSubtask = () => {
    if (subtaskTitle.trim() !== "" || subtaskDescription.trim() !== "") {
      setSubtasks1([
        ...subtasks1,
        { title: subtaskTitle, description: subtaskDescription },
      ]);
      setSubtaskTitle("");
      setSubtaskDescription("");
    }
  };

  const handleRemoveSubtask = (index) => {
    const updatedSubtasks = [...subtasks1];
    updatedSubtasks.splice(index, 1);
    setSubtasks1(updatedSubtasks);
  };

  const handleSubtaskTitleChange = (value, index) => {
    const updatedSubtasks = [...subtasks1];
    updatedSubtasks[index] = { ...updatedSubtasks[index], title: value };
    setSubtasks1(updatedSubtasks);
  };

  const handleSubtaskDescriptionChange = (value, index) => {
    const updatedSubtasks = [...subtasks1];
    updatedSubtasks[index] = { ...updatedSubtasks[index], description: value };
    setSubtasks1(updatedSubtasks);
  };

  const menuItems = (
    <Menu>
      {isTaskModalSet !== "" && (
        <>
          <Menu.Item key="duplicate" onClick={addDuplicateTodoTask}>
            <img
              src={duplicate}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Duplicate
          </Menu.Item>
          <Menu.Item key="delete" onClick={handleDelete}>
            <img
              src={deleteIcon}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Delete
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const mobileMenuItems = (
    <Menu>
      <Menu.Item key="duplicate" onClick={handleTaskPopover}>
        <img
          src={subtask}
          alt=""
          style={{ marginRight: "8px", width: "16px" }}
        />
        Add Subtask
      </Menu.Item>

      {isTaskModalSet !== "" && (
        <>
          <Menu.Item key="duplicate" onClick={addDuplicateTodoTask}>
            <img
              src={duplicate}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Duplicate
          </Menu.Item>
          <Menu.Item key="taskView" onClick={handleTaskView}>
            <img
               src={taskView}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Task View
          </Menu.Item>
          <Menu.Item key="delete" onClick={handleDelete}>
            <img
              src={deleteIcon}
              alt=""
              style={{ marginRight: "8px", width: "16px" }}
            />
            Delete
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const mobileDrawer = () => {
    return (
      <>
      <Drawer
        title={
          <div
            className="todo-title-card"
            style={{ marginBottom: isExpanded ? "20px" : "0px" }}
          >
            {isExpanded ? (
              <img
                style={{ width: "30px" }}
                onClick={handleModalClose}
                src={backBtn}
                alt=""
              />
            ) : (
              <span className="create-task-title">
                {" "}
                {isTaskModalSet !== "" ? "Edit" : "Add"} Task
              </span>
            )}
            <span className="create-task-icons">
              <Dropdown
                overlay={mobileMenuItems}
                placement="bottomRight"
                trigger={["click"]}
              >
                <img src={menu} alt="Menu" />
              </Dropdown>
              <img
                onClick={() => setIsExpanded(!isExpanded)}
                src={expand}
                alt=""
              />
            </span>
          </div>
        }
        placement={isMobile ? "bottom" : "right"}
        closable={false}
        onClose={handleCancel}
        open={isTaskModalOpen}
        className="todo-ant-drawer"
        style={{
          height:
            isMobile && isExpanded
              ? "89vh"
              : isMobile && !isExpanded
              ? "310px"
              : !isMobile
              ? "100vh"
              : "",
        }}
      >
        {isExpanded && (
          <label className="create-task-title">
            {isTaskModalSet !== "" ? "Edit" : "Add"} Task
          </label>
        )}

        <Input
          className="task-title"
          placeholder="Title"
          value={formData.title}
          onChange={handleTitleChange}
          style={{ marginTop: "10px" }}
          maxLength={100}
          required
        />

        <Input.TextArea
          className="task-title"
          placeholder="Description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{ marginTop: "10px", border: "1px solid #ccc" }}
        />

        {isExpanded ? (
          <div>
            <br />
            <label className="subtask-label">Date</label>
            <br />

            {isTaskModalSet ? (
              <DatePicker
                value={
                  formData.dueDate && formData.dueDate.seconds
                    ? dayjs(
                        moment
                          .unix(formData.dueDate.seconds)
                          .format("YYYY/MM/DD")
                      )
                    : formData.dueDate
                    ? moment(formData.dueDate)
                    : null
                }
                onChange={handleDateChange}
              />
            ) : (
              <DatePicker
                value={
                  formData.dueDate
                    ? dayjs(
                        moment
                          .unix(formData.dueDate.seconds)
                          .format("YYYY/MM/DD")
                      )
                    : null
                }
                onChange={handleDateChange}
              />
            )}

            {isTaskModalSet ? (
              <TimePicker
                onChange={(time) => handleTimeChange(time)}
                value={
                  formData.time
                    ? moment(formData.time, "h:mm:ss A")
                    : selectedTime
                    ? moment(formData.time, "h:mm:ss A")
                    : null
                }
              />
            ) : (
              <TimePicker onChange={(time) => handleTimeChange(time)} />
            )}

            <br />
            <br />
            <label className="subtask-label">Priority</label>
            <br />
            <Select
              placeholder="Select priority"
              style={{ width: 200 }}
              onChange={handlePriorityChange}
              value={priority}
            >
              {priorityOptions.map((priority) => (
                <Option key={priority.id} value={priority.id}>
                  <FlagImage index={priority.id} select="true" />{" "}
                  {priority.name}
                </Option>
              ))}
            </Select>
          </div>
        ) : null}

        {isSubtaskOpen === true && (
          <span>
            <br />
            <label className="subtask-label">Subtask</label>

            {subtasks1.map((item, index) => (
              <div key={index}>
                <Input
                  className="task-title"
                  value={item.title}
                  style={{ marginTop: "10px" }}
                  onChange={(e) =>
                    handleSubtaskTitleChange(e.target.value, index)
                  }
                />
                <Input.TextArea
                  className="task-description task-title" // Add a class for description styling if needed
                  value={item.description}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  onChange={(e) =>
                    handleSubtaskDescriptionChange(e.target.value, index)
                  }
                />
                <Button
                  style={{ marginBottom: "15px", float: "right" }}
                  type="primary"
                  danger
                  onClick={() => handleRemoveSubtask(index)}
                >
                  Remove
                </Button>
                <Divider />
              </div>
            ))}

            <Input.TextArea
              className="task-title"
              autoSize={{ minRows: 1, maxRows: 2 }}
              placeholder="Subtask Title"
              value={subtaskTitle}
              onChange={(e) => setSubtaskTitle(e.target.value)}
              style={{ marginTop: "10px" }}
              required
            />

            <Input.TextArea
              className="task-title"
              autoSize={{ minRows: 1, maxRows: 2 }}
              placeholder="Subtask Description"
              value={subtaskDescription}
              onChange={(e) => setSubtaskDescription(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Button
              style={{ marginBottom: "15px", float: "right" }}
              type="primary"
              ghost
              onClick={handleAddSubtask}
            >
              Add Subtask
            </Button>
          </span>
        )}
        <div
          className="taskset-container"
          style={{ marginTop: isExpanded ? "15.5vh" : "25px" }}
        >
          <span className="taskset-icons">
            {!isExpanded ? (
              <>
                <img
                  src={timer}
                  onClick={() => setTaskCalendarView(true)}
                  className={clockClass}
                  alt=""
                />
                {selectedDatesValues ? (
                  <pre style={{ color: "#0077D7" }}>{selectedDatesValues}</pre>
                ) : (
                  ""
                )}
                <Modal
                  open={taskCalendarView}
                  onOk={handleDateTime}
                  onCancel={() => setTaskCalendarView(false)}
                  okText="Choose Time"
                  cancelText="Cancel"
                  className="todo-date-modal"
                >
                  <Calendar
                    fullscreen={false}
                    value={
                      formData.dueDate
                        ? dayjs(
                            moment
                              .unix(formData.dueDate.seconds)
                              .format("YYYY/MM/DD")
                          )
                        : null
                    }
                    onChange={(date) => handleDateChange(date)}
                  />
                </Modal>
                <Modal
                  className="todo-time-modal"
                  open={taskTimeView}
                  onOk={() => setTaskTimeView(false)}
                  onCancel={() => setTaskTimeView(false)}
                  okText="Save"
                  cancelText="Cancel"
                  footer={null}
                >
                  <TimePicker
                    onChange={(time) => handleTimeChange(time)}
                    inline
                  />
                </Modal>
                <Popover
                  placement="top"
                  trigger="click"
                  open={priorityClicked}
                  onOpenChange={handlePriorityClickChange}
                  content={priorityOptions?.map((priority, index) => (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePrioritySetChange(priority.id)}
                      className="priority-option"
                      key={priority.id}
                      value={priority.id}
                    >
                      <FlagImage index={index} /> {priority.name}
                    </li>
                  ))}
                >
                  {priority === 1 ? (
                    <img
                      src={redFlag}
                      className={flagClass}
                      onClick={() => setPriorityClicked(true)}
                      alt=""
                    />
                  ) : priority === 2 ? (
                    <img
                      src={yellowFlag}
                      className={flagClass}
                      onClick={() => setPriorityClicked(true)}
                      alt=""
                    />
                  ) : priority === 3 ? (
                    <img
                      src={greenFlag}
                      className={flagClass}
                      onClick={() => setPriorityClicked(true)}
                      alt=""
                    />
                  ) : priority === 4 ? (
                    <img
                      src={blackFlag}
                      className={flagClass}
                      onClick={() => setPriorityClicked(true)}
                      alt=""
                    />
                  ) : (
                    <img
                      src={flag}
                      className={flagClass}
                      onClick={() => setPriorityClicked(true)}
                      alt=""
                    />
                  )}
                </Popover>
              </>
            ) : null}
            <label className="pin-checkbox">
              <span
                className={`pin-icon ${pinValue ? "checked" : ""}`}
                onClick={handlePinChange}
              >
                <PushpinOutlined
                  onClick={handlePinChange}
                  style={{ color: pinValue ? "#0077d7" : "" }}
                />
              </span>
            </label>
          </span>
          <span>
            <img
              src={send}
              onClick={isEdit ? handleEditConfirm : addTodoTask}
              alt=""
            />
          </span>
        </div>
      </Drawer>
       <ViewTask
       drawerVisible={taskDrawerVisible}
       onClose={onCloseTaskDrawer}
       Data={isTaskModalSet}
     />
     </>
    );
  };

  const webDrawer = () => {
    return (
      <Drawer
        title={
          <div
            className="todo-title-card"
            style={{ marginBottom: isExpanded ? "20px" : "0px" }}
          >
            <span className="create-task-title">
              {" "}
              {isTaskModalSet !== "" ? "Edit" : "Add"} Task
            </span>

            <span className="create-task-icons">
              {isTaskModalSet ? (
                <Dropdown
                  overlay={menuItems}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <img src={menu} alt="Menu" />
                </Dropdown>
              ) : null}

              <img onClick={handleModalClose} src={cancelIcon} alt="" />
            </span>
          </div>
        }
        placement={isMobile ? "bottom" : "right"}
        closable={false}
        onClose={handleCancel}
        open={isTaskModalOpen}
        className="todo-ant-drawer"
        style={{
          height:
            isMobile && isExpanded
              ? "89vh"
              : isMobile && !isExpanded
              ? "310px"
              : !isMobile
              ? "100vh"
              : "",
        }}
      >
        <Input
          className="task-title"
          placeholder="Title"
          value={formData.title}
          onChange={handleTitleChange}
          style={{ marginTop: "10px" }}
          maxLength={100}
          required
        />

        <Input.TextArea
          className="task-title"
          placeholder="Description"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{ marginTop: "10px", border: "1px solid #ccc" }}
        />

        <div style={{ marginTop: "15px" }}>
          <label className="subtask-label">Date</label>
          <div style={{ display: "flex", justifyContent: "space-between", gap:"10px" }}>
             {isTaskModalSet ? (
              <DatePicker
                value={
                  formData.dueDate && formData.dueDate.seconds
                    ? dayjs(
                        moment
                          .unix(formData.dueDate.seconds)
                          .format("YYYY/MM/DD")
                      )
                    : formData.dueDate
                    ? moment(formData.dueDate)
                    : null
                }
                onChange={handleDateChange}
              />
            ) : (
              <DatePicker
                value={
                  formData.dueDate
                    ? dayjs(
                        moment
                          .unix(formData.dueDate.seconds)
                          .format("YYYY/MM/DD")
                      )
                    :  dayjs(currentDate, "YYYY/MM/DD")
                   
                }
                onChange={handleDateChange}
              />
            )}

            {isTaskModalSet ? (
              <TimePicker
                onChange={(time) => handleTimeChange(time)}
                value={
                  formData.time
                    ? moment(formData.time, "h:mm:ss A")
                    : selectedTime
                    ? moment(formData.time, "h:mm:ss A")
                    : null
                }
              />
            ) : (
              <TimePicker onChange={(time) => handleTimeChange(time)} />
            )}

          </div>
        </div>

        <div style={{ marginTop: "10px" }}>
          <label className="subtask-label">Priority</label>
          <br />
          <Select
            placeholder="Select priority"
            style={{ width: 200 }}
            onChange={handlePriorityChange}
            value={priority}
          >
            {priorityOptions?.map((priority) => (
              <Option key={priority.id} value={priority.id}>
                <FlagImage index={priority.id} select="true" /> {priority.name}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: "10px" }}>
          <label className="subtask-label">Pin</label>
          <br />
          <span className="taskset-icons">
            <label className="pin-checkbox">
              <span
                className={`pin-icon ${pinValue ? "checked" : ""}`}
                onClick={handlePinChange}
              >
                <PushpinOutlined
                  onClick={handlePinChange}
                  style={{ color: pinValue ? "#0077d7" : "" }}
                />
              </span>
            </label>
          </span>
        </div>

        <Divider />

        {isSubtaskOpen === false && (
          <Button
            onClick={handleTaskPopover}
            type="primary"
            ghost
            style={{ float: "right" }}
          >
            Add Sub Task
          </Button>
        )}

        {isSubtaskOpen === true && (
          <>
            <label className="subtask-label">Subtask</label>
            {subtasks1.map((item, index) => (
              <div key={index}>
                <Input
                  className="task-title"
                  value={item.title}
                  style={{ marginTop: "10px" }}
                  onChange={(e) =>
                    handleSubtaskTitleChange(e.target.value, index)
                  }
                />
                <Input.TextArea
                  className="task-description task-title" // Add a class for description styling if needed
                  value={item.description}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  onChange={(e) =>
                    handleSubtaskDescriptionChange(e.target.value, index)
                  }
                />
                <Button
                  style={{ marginBottom: "15px", float: "right" }}
                  type="primary"
                  danger
                  onClick={() => handleRemoveSubtask(index)}
                >
                  Remove
                </Button>
                <Divider />
              </div>
            ))}

            <Input
              className="task-title"
              placeholder="Subtask Title"
              value={subtaskTitle}
              onChange={(e) => setSubtaskTitle(e.target.value)}
              style={{ marginTop: "10px" }}
              required
            />

            <Input.TextArea
              className="task-title"
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder="Subtask Description"
              value={subtaskDescription}
              onChange={(e) => setSubtaskDescription(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Button
              style={{ marginBottom: "15px", float: "right" }}
              type="primary"
              ghost
              onClick={handleAddSubtask}
            >
              Add Subtask
            </Button>
          </>
        )}

        <div>
          <Button
            onClick={handleModalClose}
            default
            style={{ width: "100%", marginTop: "30px" }}
          >
            Cancel
          </Button>
          <br />
          <br />
          <Button
            type="primary"
            onClick={isEdit ? handleEditConfirm : addTodoTask}
            style={{ width: "100%" }}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>

        {isMobile ? (
          <div
            className="taskset-container"
            style={{ marginTop: isExpanded ? "15.5vh" : "53px" }}
          >
            <span className="taskset-icons">
              <label className="pin-checkbox">
                <span
                  className={`pin-icon ${pinValue ? "checked" : ""}`}
                  onClick={handlePinChange}
                >
                  <PushpinOutlined
                    onClick={handlePinChange}
                    style={{ color: pinValue ? "#0077d7" : "" }}
                  />
                </span>
              </label>
            </span>
            <span>
              <img
                src={send}
                onClick={isEdit ? handleEditConfirm : addTodoTask}
                alt=""
              />
            </span>
          </div>
        ) : null}
      </Drawer>
    );
  };

  return (
    <CreateTaskStyle>
      <div className="todo-create-task">
        {isMobile ? mobileDrawer() : webDrawer()}
      </div>
    </CreateTaskStyle>
  );
}

export default CreateTask;
