import React, { createContext, useContext, useState, useEffect } from "react";
import {
  Button,
  Modal,
  message,
  Calendar,
  Spin,
  Layout,
  FloatButton,
  Switch,
} from "antd";
import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EmptyState, HomeStyle } from "./styles";
import aiTaskIcons from "../../assets/task/aiAnswer.svg";
import "../../styles/global.css";

import SearchFor from "../../components/Search";
import TaskCard from "../../components/Task/TaskCard";
import CreateTask from "../../components/Task/CreateTask";
import Navbar from "../../components/Navbar";
import EmptyStateImg from "../../assets/taskEmpty.png";
import createBtn from "../../assets/home/create-task.svg";
import Sidebar from "../../components/Sidebar";
import { fetchTask } from "../../redux/actions/task.actions";
import { updateGlobalDate } from "../../redux/actions/task.actions";
import { PlusOutlined } from "@ant-design/icons";
import TaskAiDrawer from "../../components/Ai";

const { Content } = Layout;
const TaskDateContext = createContext();

export const Task = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const todoTask = useSelector((state) => state.tasks.tasks);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [calendarView, setCalendarView] = useState(false);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [filterPin, setFilterPin] = useState(false);
  const [filterPinned, setFilterPinned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [taskDate, setTaskDate] = useState("Today");
  const [globalDate, setGlobalDate] = useState();
  const [taskAiDrawerVisible, setTaskAiDrawerVisible] = useState(false);

  useEffect(() => {
    if (currentUser?.user_id) {
      const currentDate = moment().format("YYYY/MM/DD");
      setGlobalDate(currentDate);
      getTasks(currentDate);
      getGlobalDate(currentDate);
    }
  }, []);
  const getTasks = async (date) => {
    setIsLoading(true);
    await dispatch(fetchTask({ user_id: currentUser?.user_id, date: date }));
    setIsLoading(false);
  };
  const getGlobalDate = async (date) => {
    await dispatch(updateGlobalDate(date));
  };

  const handleCalendarViewCancel = () => {
    setCalendarView(false);
  };

  const handleConfirmButtonClick = () => {
    if (selectedDate) {
      const selectedDates = new Date(selectedDate);
      const currentDate = new Date();

      const day = selectedDates.getDate();
      const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
        selectedDates
      );
      const year = selectedDates.getFullYear();

      let formattedDate;

      if (
        selectedDates.getDate() === currentDate.getDate() &&
        selectedDates.getMonth() === currentDate.getMonth() &&
        selectedDates.getFullYear() === currentDate.getFullYear()
      ) {
        formattedDate = "Today";
      } else {
        formattedDate = `${day} ${month} ${year}`;
      }

      setTaskDate(formattedDate);
      setGlobalDate(selectedDate.format("YYYY/MM/DD"));
      getGlobalDate(selectedDate.format("YYYY/MM/DD"));
      getTasks(selectedDate.format("YYYY/MM/DD"));
      setCalendarView(false);
    } else {
      console.log("No date selected");
    }
  };

  const onPanelChange = (value) => {
    setSelectedDate(value);
    const selectedDate = new Date(value);
    const currentDate = new Date();

    const day = selectedDate.getDate();
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      selectedDate
    );
    const year = selectedDate.getFullYear();

    let formattedDate;

    if (
      selectedDate.getDate() === currentDate.getDate() &&
      selectedDate.getMonth() === currentDate.getMonth() &&
      selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      formattedDate = "Today";
    } else {
      formattedDate = `${day} ${month} ${year}`;
    }
    // setTaskDate(formattedDate);
    // getTasks(value.format("YYYY/MM/DD"))
  };

  const handleTaskModalOpen = (val) => {
    setIsTaskModalOpen(val);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filterByPin = (checked) => {
    setFilterPin(checked);
    setFilterPinned(true);
  };

  const filterTasks = (todoslist) => {
    const filteredAndSortedTasks = todoslist.filter((task) => {
      const titleMatch = task?.title
        ? task.title.toLowerCase().includes(searchValue.toLowerCase())
        : false;
      const pinMatch = filterPin ? task.pin === true : true;
      return titleMatch && pinMatch;
    });

    const sortedTasks = filteredAndSortedTasks
      ? filteredAndSortedTasks.slice().sort((a, b) => {
          if (
            a.createdOn &&
            b.createdOn &&
            typeof a.createdOn.toMillis === "function" &&
            typeof b.createdOn.toMillis === "function"
          ) {
            return b.createdOn.toMillis() - a.createdOn.toMillis();
          }
          return 0;
        })
      : [];

    const pendingTasks = sortedTasks.filter(
      (task) => task.status === "Pending"
    );
    const completedTasks = sortedTasks.filter(
      (task) => task.status === "Completed"
    );

    return { pendingTasks, completedTasks };
  };

  const showTaskAiDrawer = (key) => {
    setTaskAiDrawerVisible(true);
  };

  const onCloseTaskAiDrawer = () => {
    setTaskAiDrawerVisible(false);
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout className="site-layout">
          <Content>
            <Navbar handleTaskModalOpen={handleTaskModalOpen} />
            <HomeStyle>
              <div className="todo-home">
                <div>{contextHolder}</div>

                {/* <img
                  className="todo-task-btn"
                  onClick={() => handleTaskModalOpen(true)}
                  src={createBtn}
                  alt=""
                /> */}
                {isTaskModalOpen === true && (
                  <CreateTask
                    handleTaskModalOpen={handleTaskModalOpen}
                    isTaskModalSet=""
                    getTasks={getTasks}
                    taskDate={taskDate}
                    globalDates={globalDate}
                  />
                )}

                <div className="todo-tasks">
                  <div className="todo-task-list">
                    <SearchFor
                      placeholder="Search..."
                      onChange={handleSearch}
                    />
                    <div className="home-buttons">
                      <Button
                        type="primary"
                        className="date-btn"
                        onClick={
                          !isMobile ? () => setCalendarView(true) : undefined
                        }
                      >
                        {taskDate} {!isMobile ? <DownOutlined /> : null}
                      </Button>
                      {isMobile ? (
                        <Button
                          className="calendar-btn"
                          onClick={() => setCalendarView(true)}
                        >
                          Calendar <CalendarOutlined />
                        </Button>
                      ) : null}
                      <Switch
                        checked={filterPin}
                        onChange={filterByPin}
                        checkedChildren="Pinned"
                        unCheckedChildren="Pin"
                      />
                    </div>
                    <Modal
                      open={calendarView}
                      onOk={handleCalendarViewCancel}
                      onCancel={handleCalendarViewCancel}
                      className="todo-ant-modal"
                      okButtonProps={{ style: { display: "none" } }}
                      cancelButtonProps={{ style: { display: "none" } }}
                      // okText="Confirm"
                    >
                      <Calendar fullscreen={false} onChange={onPanelChange} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button onClick={handleCalendarViewCancel}>
                          Cancel
                        </Button>
                        <Button
                          onClick={handleConfirmButtonClick}
                          type="primary"
                        >
                          Confirm
                        </Button>
                      </div>
                    </Modal>
                    {isLoading ? (
                      <Spin
                        tip="Loading"
                        size="small"
                        style={{ marginTop: "180px" }}
                      >
                        <div className="content" />
                      </Spin>
                    ) : (
                      <>
                        <span className="TaskHeadLine">Tasks</span>
                        {todoTask.length > 0 &&
                        filterTasks(todoTask)?.pendingTasks.length > 0
                          ? filterTasks(todoTask)?.pendingTasks.map(
                              (todo, index) => (
                                <TaskCard
                                  todo={todo}
                                  index={index}
                                  taskStatus="Completed"
                                  taskDate={taskDate}
                                />
                              )
                            )
                          : null}

                        {todoTask.length > 0 &&
                        filterTasks(todoTask)?.pendingTasks.length === 0 &&
                        todoTask.length > 0 &&
                        filterTasks(todoTask)?.completedTasks.length > 0 ? (
                          <span
                            style={{
                              color: "#778399",
                              fontSize: "16px",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            Sorry No Pending Tasks
                          </span>
                        ) : null}

                        {todoTask.length > 0 &&
                        filterTasks(todoTask)?.completedTasks.length > 0 ? (
                          <>
                            {/* <Button
                            type="default"
                            className="date-btn statusCompleted-btn"
                            style={{ marginTop: "20px" }}
                          >
                            Completed
                          </Button> */}

                            <span className="statusCompleted">Completed</span>
                          </>
                        ) : null}

                        {todoTask.length > 0 &&
                        filterTasks(todoTask)?.completedTasks.length > 0
                          ? filterTasks(todoTask)?.completedTasks.map(
                              (todo, index) => (
                                <TaskCard
                                  key={index}
                                  todo={todo}
                                  index={index}
                                  taskStatus="Pending"
                                  taskDate={taskDate}
                                />
                              )
                            )
                          : null}

                        {todoTask.length != 0 &&
                        filterTasks(todoTask)?.pendingTasks.length === 0 &&
                        filterTasks(todoTask)?.completedTasks.length === 0 ? (
                          <EmptyState>
                            <div className="wrapper">
                              <img alt="empty state" src={EmptyStateImg} />
                              <h3>No Results</h3>
                              {/* <h3>Sorry! No Task Found...</h3> */}
                              <span
                                className="newTask"
                                onClick={() => setIsTaskModalOpen(true)}
                              >
                                Please Add New Task
                              </span>
                            </div>
                          </EmptyState>
                        ) : null}
                        {todoTask.length === 0 ? (
                          <EmptyState>
                            <div className="wrapper">
                              <img alt="empty state" src={EmptyStateImg} />
                              <h3>Sorry! No Task Found...</h3>
                              <span
                                className="newTask"
                                onClick={() => setIsTaskModalOpen(true)}
                              >
                                Please Add New Task
                              </span>
                            </div>
                          </EmptyState>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {isMobile ? (
                <FloatButton
                  icon={<PlusOutlined />}
                  style={{
                    right: 94,
                  }}
                  type="primary"
                  onClick={() => handleTaskModalOpen(true)}
                  className="floatAddTeamButton"
                />
              ) : (
                ""
              )}

              <FloatButton
                icon={<img src={aiTaskIcons} alt="" />}
                description="Aks AI"
                shape="square"
                style={{
                  width: "50px",
                  height: "60px",
                }}
                visibilityHeight={0}
                onClick={() => showTaskAiDrawer()}
              />
              <TaskAiDrawer
                drawerVisible={taskAiDrawerVisible}
                onClose={onCloseTaskAiDrawer}
              />
            </HomeStyle>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
